import { Component, OnInit } from '@angular/core';
import { CommentFacade } from '@app/comment/state/comment-facade.service';
import { MediaFacade } from '@app/media/state/media.facade';
import { Comment } from '@app/comment/models/comment.model';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import {ActivatedRoute} from '@angular/router';
import {filter, first, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  media$ = this.mediaFacade.media$;
  comments$ = this.commentFacade.comments$;
  commentsCount$ = this.commentFacade.commentsCount$;
  user$ = this.authFacade.loggedUser$;

  scrollTo$ = this.route.queryParams.pipe(
    map(params => params.comment ? parseInt(params.comment, 10) : undefined),
    switchMap(id => id !== undefined ? this.comments$.pipe(
      filter(comments => comments.find(
        comment => comment.id === id || comment.replies?.find(sub => sub.id === id) !== undefined
      ) !== undefined),
      map(() => id),
      first()
    ) : of(undefined)),
    shareReplay(1),
  );

  constructor(
    private authFacade: AuthFacade,
    private commentFacade: CommentFacade,
    private mediaFacade: MediaFacade,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {}

  addComment(comment: Comment) {
    this.commentFacade.addComment(comment);
  }

  likeComment(like: { comment: Comment; liked: boolean }) {
    if (like.liked) {
      this.commentFacade.likeComment(like.comment);
    } else {
      this.commentFacade.unlikeComment(like.comment);
    }
  }
}
