import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Page } from '@app/page/models/page';

@Component({
  selector: 'app-page-display',
  templateUrl: './page-display.component.html',
  styleUrls: ['./page-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageDisplayComponent {
  @Input() page: Page | null;

  constructor() {}
}
