import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromReport from '@app/report/state/reducers';
import { map } from 'rxjs/operators';
import { ReportActions, ReportApiActions } from '@app/report/state/actions';
import { Report } from '@app/report/models/report.model';

@Injectable()
export class ReportFacade {
  loading$ = this.store.pipe(select(fromReport.getLoading));
  loaded$ = this.store.pipe(select(fromReport.getLoaded));
  addedReport$ = this.store.pipe(select(fromReport.getAddedReport));
  reportReasons$ = this.store.pipe(
    select(fromReport.getReportReasons),
    map(reasons => reasons.items)
  );
  reportReasonsCount$ = this.store.pipe(
    select(fromReport.getReportReasons),
    map(reasons => reasons.count)
  );

  constructor(private store: Store<fromReport.State>) {}

  loadReportReasons() {
    this.store.dispatch(ReportActions.loadReportReasons());
  }

  addReport(report: Report) {
    this.store.dispatch(ReportActions.addReport({ report }));
  }
}
