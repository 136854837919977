import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromMedia from '@app/media/state/reducers';
import {MediaActions} from '@app/media/state/actions';
import {map} from 'rxjs/operators';
import {MediaSearchModel} from '@app/media/models';

@Injectable()
export class MediaFacade {
  loading$ = this.store.pipe(select(fromMedia.getLoading));
  loaded$ = this.store.pipe(select(fromMedia.getLoaded));
  search$ = this.store.pipe(select(fromMedia.getSearch));

  featuredMedias$ = this.store.pipe(
    select(fromMedia.getFeaturedMedias),
    map(medias => medias.items)
  );
  medias$ = this.store.pipe(
    select(fromMedia.getMedias),
    map(medias => medias.items)
  );
  mediasCount$ = this.store.pipe(
    select(fromMedia.getMedias),
    map(medias => medias.count)
  );
  myMedias$ = this.store.pipe(
    select(fromMedia.getMyMedias),
    map(medias => medias.items)
  );
  myMediasCount$ = this.store.pipe(
    select(fromMedia.getMyMedias),
    map(medias => medias.count)
  );
  suggestedMedias$ = this.store.pipe(
    select(fromMedia.getSuggestedMedias),
    map(medias => medias.items)
  );
  suggestedMediasCount$ = this.store.pipe(
    select(fromMedia.getSuggestedMedias),
    map(medias => medias.count)
  );
  topMedias$ = this.store.pipe(
    select(fromMedia.getTopMedias),
    map(medias => medias.items)
  );
  topMediasCount$ = this.store.pipe(
    select(fromMedia.getTopMedias),
    map(medias => medias.count)
  );
  continueMedias$ = this.store.pipe(
    select(fromMedia.getContinueMedias),
    map(medias => medias.items)
  );
  continueMediasCount$ = this.store.pipe(
    select(fromMedia.getContinueMedias),
    map(medias => medias.count)
  );
  recommendedMedias$ = this.store.pipe(
    select(fromMedia.getRecommendedMedias),
    map(medias => medias.items)
  );
  recommendedMediasCount$ = this.store.pipe(
    select(fromMedia.getRecommendedMedias),
    map(medias => medias.count)
  );
  searchedMedias$ = this.store.pipe(
    select(fromMedia.getSearchedMedias),
    map(medias => medias.items)
  );
  searchedMediasCount$ = this.store.pipe(
    select(fromMedia.getSearchedMedias),
    map(medias => medias.count)
  );
  associatedPlaylists$ = this.store.pipe(
    select(fromMedia.getAssociatedPlaylists),
    map(medias => medias.items)
  );
  associatedPlaylistsCount$ = this.store.pipe(
    select(fromMedia.getAssociatedPlaylists),
    map(medias => medias.count)
  );
  media$ = this.store.pipe(select(fromMedia.getMedia));
  mediaExtraData$ = this.store.pipe(select(fromMedia.getMediaExtraData));
  featuredMediaExtraData$ = this.store.pipe(
    select(fromMedia.getFeaturedMediaExtraData)
  );

  constructor(private store: Store<fromMedia.State>) {
  }

  loadFeaturedMedias() {
    this.store.dispatch(MediaActions.loadFeaturedMedias());
  }

  loadFeatureMediaExtraData(id: number) {
    this.store.dispatch(MediaActions.loadFeatureMediaExtraData({id}));
  }

  loadMedias() {
    this.store.dispatch(MediaActions.loadMedias());
  }

  loadMyMedias(search: MediaSearchModel) {
    this.store.dispatch(MediaActions.loadMyMedias({search}));
  }

  searchMedias(search: MediaSearchModel) {
    this.store.dispatch(MediaActions.searchMedias({search}));
  }

  loadSuggestedMedias() {
    this.store.dispatch(MediaActions.loadSuggestedMedias());
  }

  loadTopMedias() {
    this.store.dispatch(MediaActions.loadTopMedias());
  }

  loadContinueMedias() {
    this.store.dispatch(MediaActions.loadContinueMedias());
  }

  loadRecommendedMedias() {
    this.store.dispatch(MediaActions.loadRecommendedMedias());
  }

  loadMedia(id: number, extraData = true, reset = true, highlightId?: number) {
    this.store.dispatch(MediaActions.loadMedia({id, reset}));
    if (extraData) {
      this.store.dispatch(MediaActions.loadMediaExtraData({id, reset, highlightId}));
    }
  }

  loadAssociatedPlaylists(id: number) {
    this.store.dispatch(MediaActions.loadAssociatedPlaylists({id}));
  }

  likeMedia(id: number) {
    this.store.dispatch(MediaActions.likeMedia({id}));
  }

  unlikeMedia(id: number) {
    this.store.dispatch(MediaActions.unlikeMedia({id}));
  }

  saveMediaVideoPosition(id: number, videoPosition: number) {
    this.store.dispatch(MediaActions.saveMediaVideoPosition({id, videoPosition}));
  }
}
