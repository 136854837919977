import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from '@app/user/services/user.service';
import { Store } from '@ngrx/store';
import * as fromChannel from '../reducers/channel.reducer';
import { ChannelActions, ChannelApiActions } from '../actions';
import { AuthApiActions } from '../../../authentication/state/actions';

@Injectable()
export class ChannelEffects {
  loadFollowedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelActions.loadFollowedUser),
      switchMap(() => {
        return this.service.getUserFollows().pipe(
          map(followedUsers =>
            ChannelApiActions.loadFollowedUserSuccess({ followedUsers })
          ),
          catchError(error => {
            return of(ChannelApiActions.loadFollowedUserFailure({ error }));
          })
        );
      })
    )
  );

  reloadFollowedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChannelApiActions.followSuccess,
        ChannelApiActions.unfollowSuccess,
        AuthApiActions.loadLoggedUserSuccess
      ),
      map(() => ChannelActions.loadFollowedUser())
    )
  );

  follow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelActions.follow),
      switchMap(({ userId }) => {
        return this.service.followUser(userId).pipe(
          map(follow => ChannelApiActions.followSuccess({ follow })),
          catchError(error => {
            return of(ChannelApiActions.followFailure({ error }));
          })
        );
      })
    )
  );

  unfollow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelActions.unfollow),
      switchMap(({ follow }) => {
        return this.service.unfollowUser(follow).pipe(
          map(() => ChannelApiActions.unfollowSuccess({ unfollow: follow })),
          catchError(error => {
            return of(ChannelApiActions.unfollowFailure({ error }));
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private store: Store<fromChannel.State>,
    private service: UserService
  ) {}
}
