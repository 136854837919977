<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" novalidate>
  <div formGroupName="plainPassword">
    <div class="custom-field">
      <app-text-input
        formControlName="first"
        label="{{ 'PASSWORD' | translate }}"
        helpText="{{ 'HELP_PASSWORD_FIELD' | translate }}"
        type="password"
        [submitted]="submitted"
      ></app-text-input>
    </div>
    <div class="custom-field">
      <app-text-input
        formControlName="second"
        label="{{ 'PASSWORD_CONFIRMATION' | translate }}"
        type="password"
        [showError]="showErrorPasswordEqual"
        errorText="{{ 'FIELD_PASSWORD_MUST_BE_EQUAL' | translate }}"
      ></app-text-input>
    </div>
  </div>

  <div class="submit-container">
    <button
      type="submit"
      class="custom-submit"
      [disabled]="processing"
      translate
    >
      UPDATE_MY_PASSWORD
    </button>
  </div>
</form>
