<div class="media-list" #mediaList>
  <app-media-tile
    *ngFor="let media of medias"
    [media]="media"
    [horizontal]="true"
    [description]="description"
    class="media small-picture format-{{ size }}"
    [class.card-hover]="cardLink"
    [highlight]="highlight?.id === media.id"
    [playlist]="playlist"
    [routerLink]="cardLink ? (media | mediaRoute) : undefined"
    [queryParams]="cardLink ? (media | mediaRouteParams: playlist) : undefined"
    @Appear
  ></app-media-tile>
</div>
