import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {Comment} from '@app/comment/models/comment.model';
import {User} from '../../../user/models/user.model';
import {Media} from '@app/media/models';
import {LikedComment} from '@app/comment/models/comment-like.model';
import {BehaviorSubject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {delay, filter} from 'rxjs/operators';
import {CommentComponent} from '../comment/comment.component';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  animations: [
    trigger('highlight', [
      transition('1 => *', animate(0)),
      transition('* => 1', animate('500ms 500ms', keyframes([
        style({backgroundColor: 'initial'}),
        style({backgroundColor: '#adfffc'}),
        style({backgroundColor: 'initial'}),
      ])))
    ])
  ]
})
export class CommentListComponent implements OnInit, AfterViewInit {
  scrollTo$ = new BehaviorSubject<number | undefined>(undefined);

  scroll$ = this.scrollTo$.pipe(
    filter((id): id is number => id !== undefined),
    delay(300)
  );

  @ViewChildren(CommentComponent)
  commentElements: QueryList<CommentComponent>;

  @Input()
  user: User;

  @Input()
  media: Media;

  @Input()
  comments: Comment[];

  @Input()
  canAnswer = true;

  @Output()
  addComment = new EventEmitter<Comment>();

  @Output()
  like = new EventEmitter<LikedComment>();

  constructor(private change: ChangeDetectorRef) {
  }

  @Input()
  set scrollTo(scrollTo: number | undefined) {
    this.scrollTo$.next(scrollTo);
  }

  ngOnInit() {
  }

  trackById(index: number, item: Comment) {
    return item.id;
  }

  ngAfterViewInit(): void {
    this.scroll$.pipe(
      untilDestroyed(this)
    ).subscribe(id => {
      this.change.detectChanges();
      const commentComponent = this.commentElements.find(component => component.comment?.id === id);

      if (!commentComponent) {
        return;
      }

      const element = commentComponent.elementRef.nativeElement as HTMLElement;
      element.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
    });
  }
}
