import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromUserChannel from '@app/channel-media/state/reducers/user-channel.reducer';

export interface State extends fromRoot.State {
  userChannel: fromUserChannel.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    userChannel: fromUserChannel.reducer
  })(state, action);
}

export const selectUserChannelState = createFeatureSelector<State>(
  'userChannel'
);

export const getUserChannelState = createSelector(
  selectUserChannelState,
  state => state.userChannel
);

export const getUserChannel = createSelector(
  getUserChannelState,
  fromUserChannel.getUserChannel
);

export const getUserChannelMedias = createSelector(
  getUserChannelState,
  fromUserChannel.getUserChannelMedias
);

export const getUserChannelMediasSearch = createSelector(
  getUserChannelState,
  fromUserChannel.getUserChannelMediasSearch
);

export const getLoading = createSelector(
  getUserChannelState,
  fromUserChannel.getLoading
);
export const getLoaded = createSelector(
  getUserChannelState,
  fromUserChannel.getLoaded
);
