import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RedirectService } from './redirect.service';

// if service worker needed
// import { ServiceWorkerModule } from '@angular/service-worker';
import { Router, RouterModule, Scroll } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { TokenInterceptor } from '@app/authentication/services';
import { CoreModule } from '@app/core';
import { ShellModule } from '@app/shell/shell.module';
import { AuthModule } from './authentication/auth.module';
import { PageModule } from '@app/page/page.module';
import { UserModule } from '@app/user/user.module';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomRouteSerializer } from '@app/core/utils/custom-route-serializer';
import { reducers } from '@app/core/state/reducers';
import { environment } from '@env/environment';
import { extModules } from '@app/build-specifics';
import { registerLocaleData, ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MediaModule } from '@app/media/media.module';
import { CommentModule } from '@app/comment/comment.module';
import { FallbackModule } from '@app/fallback/fallback.module';
import localeFr from '@angular/common/locales/fr';
import { UploadModule } from '@app/upload/upload.module';
import { ToasterModule } from 'angular2-toaster';
import { CategoryMediaModule } from '@app/category-media/category-media.module';
import { LibraryModule } from '@app/library/library.module';
import { UserContentModule } from '@app/user-content/user-content.module';
import { UserChannelMediaModule } from '@app/channel-media/user-channel-media.module';

registerLocaleData(localeFr);

let MODULES: any[] = [
  BrowserModule,
  BrowserAnimationsModule,
  EffectsModule.forRoot(),
  ShellModule,
  CoreModule,
  PageModule,
  CategoryMediaModule,
  LibraryModule,
  // SharedModule,
  AuthModule,
  UserModule,
  MediaModule,
  UploadModule,
  UserContentModule,
  CommentModule,
  FallbackModule,
  UserChannelMediaModule,
  ToasterModule.forRoot(),
  StoreModule.forRoot(reducers, {
    runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true,
      strictStateSerializability: true
      // Disable as we can pass File to action wich is not serializable
      /* strictActionSerializability: true */
    }
  }),
  StoreRouterConnectingModule.forRoot({
    routerState: RouterState.Minimal,
    serializer: CustomRouteSerializer
  }),

  // if service worker needed
  /*   ServiceWorkerModule.register('/ngsw-worker.js', {
    enabled: environment.production
  }),
 */ RouterModule.forRoot(
    APP_ROUTES,
    {
      useHash: true,
      relativeLinkResolution: 'legacy'
    }
  )
];

if (!environment.production) {
  MODULES = [...MODULES, extModules];
}

@NgModule({
  imports: MODULES,
  exports: [RouterModule],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    CookieService,
    RedirectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        // todo : find another solution better than this hotix (ex : using routes datas to prevent the scroll top)
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
