<app-profile-picture [user]="user"></app-profile-picture>
<form (ngSubmit)="submit()" [formGroup]="form">
  <input
    name="content"
    formControlName="content"
    type="text"
    placeholder="{{
      parent ? 'Répondre au commentaire' : 'Ajouter un commentaire'
    }}"
  />
  <!--  <app-icon src="assets/images/share.svg" (click)="submit()"></app-icon>-->
  <app-button-outlined text="Envoyer" (click)="submit()" [disabled]="!form.valid"></app-button-outlined>
</form>
