import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromUserChannel from '@app/channel-media/state/reducers';
import { UserChannelActions } from '@app/channel-media/state/actions';
import { MediaSearchModel } from '@app/media/models';
import { map } from 'rxjs/operators';

@Injectable()
export class UserChannelFacade {
  loading$ = this.store.pipe(select(fromUserChannel.getLoading));
  loaded$ = this.store.pipe(select(fromUserChannel.getLoaded));
  getUserChannel$ = this.store.pipe(select(fromUserChannel.getUserChannel));
  getUserChannelMedias$ = this.store.pipe(
    select(fromUserChannel.getUserChannelMedias),
    map(collection => collection.items)
  );
  getUserChannelMediasSearch$ = this.store.pipe(
    select(fromUserChannel.getUserChannelMediasSearch)
  );

  constructor(private store: Store<fromUserChannel.State>) {
    // TODO infinite scroll
  }

  loadUserChannel(id: number, reset = true) {
    this.store.dispatch(UserChannelActions.loadUserChannel({ id, reset }));
  }

  changeUserChannelMediaSearch(search: MediaSearchModel) {
    this.store.dispatch(
      UserChannelActions.changeUserChannelMediaSearch({ search })
    );
  }
}
