import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryMediaRoutingModule } from '@app/category-media/category-media-routing.module';
import { CategoryComponent } from '@app/category-media/containers/category/category.component';
import { CategoryModule } from '@app/category/category.module';
import { MediaModule } from '@app/media/media.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [CategoryComponent],
  imports: [
    CommonModule,
    CategoryModule,
    MediaModule,
    CategoryMediaRoutingModule,
    InfiniteScrollModule
  ]
})
export class CategoryMediaModule {}
