import { Component } from '@angular/core';
import { scan, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { Collection, defaultCollection } from '@app/shared/models/collection';
import { Media } from '@app/media/models';
import { MediaService } from '@app/media/services';
import { MediaSearchService } from '../../../media/services/media.search.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent {
  loading = false;
  hasNextPage = true;
  medias$ = this.mediaSearchService.search$.pipe(
    untilDestroyed(this),
    // loading
    tap(() => (this.loading = true)),
    // load a page of medias
    switchMap(search => this.mediaService.getResumeMedias(search)),
    // default value
    startWith(defaultCollection<Media>()),
    // reduce medias over time (accumulate them)
    scan(
      (previousMedia, newMedias) =>
        ({
          items:
            this.mediaSearchService.search$.getValue().page === 1
              ? newMedias.items
              : [...previousMedia.items, ...newMedias.items],
          count: newMedias.count
        } as Collection<Media>)
    ),
    // keep if other pages
    tap(collection => (this.hasNextPage = !!collection.items.length)),
    // end loading
    tap(() => (this.loading = false)),
    // Share same observable replaying last result
    shareReplay(1)
  );

  constructor(
    private mediaService: MediaService,
    private mediaSearchService: MediaSearchService
  ) {}
}
