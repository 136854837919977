<div
  class="media-playlists"
  *ngIf="media"
>
  <ng-container *ngIf="isUniquePlaylist">
    <h3>Parcours entier :</h3>
    <h4 class="separate">{{uniquePlaylist?.playlist.title}}</h4>
  </ng-container>

  <ng-container *ngIf="!isUniquePlaylist">
    <h3 class="separate">Cette vidéo fait partie de {{pPlaylists.length}} parcours</h3>
  </ng-container>

  <div
    class="media-playlist"
    *ngFor="let playlist of pPlaylists; trackBy: trackById"
  >
    <div
      class="header flex-row align-items-center"
      [routerLink]="media | mediaRoute"
      [queryParams]="{playlist: playlist.playlist.id}"

      *ngIf="!isUniquePlaylist"
    >
      <div class="playlist-preview">
        <div class="wrapper">
          <div class="media-view">
            <app-media-view [media]="playlist.playlist"></app-media-view>
          </div>
        </div>
      </div>

      <div class="title flex-1">
        <span>{{playlist.playlist.title}}</span>
        <app-media-creator class="small-picture" [media]="playlist.playlist"></app-media-creator>
      </div>
      <div class="action flex-row align-items-center justify-content-center">
        <app-icon src="assets/images/slide-right.svg" class="light-green-fill"></app-icon>
      </div>
    </div>

    <div class="medias-container" #mediasContainer [hidden]="!isUniquePlaylist">
      <app-media-vertical-list
        class="media-vertical-list padded no-vertical-padding"
        [medias]="playlist.medias"
        [highlight]="media"
        [playlist]="playlist.playlist"
        size="small"
        [description]="false"
        [cardLink]="true"
        (highlightComponent)="scrollToMedia($event)"
      ></app-media-vertical-list>

      <app-media-slider
        class="media-list"
        [highlight]="media"
        [playlist]="playlist.playlist"
        [medias]="playlist.medias"
        [cardLink]="true"
      ></app-media-slider>
    </div>
  </div>
</div>
