import { createReducer, on } from '@ngrx/store';

import { ProfileActions } from '../actions';

export interface State {
  sendProfileSuccess: boolean;
  sendProfileError: boolean;
  sendProfilePending: boolean;
}

export const INITIAL_STATE: State = {
  sendProfileSuccess: false,
  sendProfileError: false,
  sendProfilePending: false
};

export const reducer = createReducer(INITIAL_STATE);

export const getSendProfileSuccess = (state: State) => state.sendProfileSuccess;
export const getSendProfileError = (state: State) => state.sendProfileError;
export const getSendProfilePending = (state: State) => state.sendProfilePending;
