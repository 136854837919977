import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-widget',
  templateUrl: './upload-widget.component.html',
  styleUrls: ['./upload-widget.component.scss']
})
export class UploadWidgetComponent implements OnInit {
  @Input() alternate = false;

  constructor() {}

  ngOnInit() {}
}
