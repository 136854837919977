import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromTag from '@app/tag/state/reducers';
import { TagActions } from '@app/tag/state/actions';

@Injectable()
export class TagFacade {
  tags$ = this.store.pipe(select(fromTag.getTags));
  loading$ = this.store.pipe(select(fromTag.getLoading));
  loaded$ = this.store.pipe(select(fromTag.getLoaded));

  constructor(private store: Store<fromTag.State>) {}

  loadTags(name: string) {
    this.store.dispatch(TagActions.loadTags({ name }));
  }
}
