import { createAction, props } from '@ngrx/store';
import { MediaSearchModel } from '@app/media/models';

const TAG = '[UserChannel/View]';

export const changeUserChannelMediaSearch = createAction(
  `${TAG} change user channel search`,
  props<{ search: MediaSearchModel }>()
);
export const loadUserChannel = createAction(
  `${TAG} Load user channel`,
  props<{ id: number; reset: boolean }>()
);
export const loadUserChannelMedias = createAction(
  `${TAG} Load user channel medias`,
  props<{ userId: number }>()
);
