import { NgModule } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPowerOff,
  faPencilAlt,
  faTrashAlt,
  faAngleLeft,
  faTimes,
  faHome,
  faSlidersH
} from '@fortawesome/free-solid-svg-icons';
import { IconComponent } from './icon/icon.component';
import { CommonModule } from '@angular/common';

@NgModule({
  exports: [FontAwesomeModule, IconComponent],
  imports: [CommonModule],
  declarations: [IconComponent]
})
export class IconsModule {
  constructor() {
    library.add(
      faPowerOff,
      faPencilAlt,
      faTrashAlt,
      faAngleLeft,
      faTimes,
      faHome,
      faSlidersH
    );
  }
}
