<div class="title" [class.centered]="centered">
  <div class="icon-slot">
    <ng-content class="test" select="app-icon"></ng-content>
  </div>
  <div class="main-slot">
    <ng-content></ng-content>
  </div>
</div>
<div
  class="close"
  [class.centered]="centered"
  (click)="modalService.close()"
  *ngIf="modalService.closableByDefault"
>
  <app-icon class="close-button" src="/assets/images/close.svg"></app-icon>
</div>

<!-- *ngIf="!centered" -->
