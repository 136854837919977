import { createReducer, on } from '@ngrx/store';

import { PageActions } from '@app/page/state/actions';
import { Page } from '@app/page/models/page';

export interface State {
  page: Page | null;
}

export const INITIAL_STATE: State = {
  page: null
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(PageActions.loadPageSuccess, (state, { page }) => ({
    ...state,
    page: {
      ...page,
      image: `assets/images/pages/${page.devKey}.jpg`
    }
  })),

  on(PageActions.loadPageFailure, state => ({
    ...state,
    page: null
  }))
);

export const getPage = (state: State) => state.page;
