export const themeColors = [
  'light-violet',
  'violet',
  'pink',
  'red',
  'brown',
  'yellow',
  'orange',
  'light-orange',
  'light-green',
  'dark-green',
  'blue',
  'light-blue'
];

export function getColorForNumber(num: number, colors = themeColors) {
  return colors.length ? colors[num % colors.length] : 'light-green';
}
