import { Injectable } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';

import { HttpService } from '@app/core/services/http.service';
import {
  Affiliation,
  Affiliations,
  Profession,
  Professions
} from '@app/authentication/models/auth';
import { User } from '../../user/models/user.model';
import { ApiCollection } from '@app/shared/models/api-collection';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirstConnectionService {
  saveModification$ = new Subject<boolean>();

  public constructor(private httpService: HttpService) {}

  public loadProfessions(): Observable<Professions> {
    return this.httpService
      .get<ApiCollection<Profession>>('/professions')
      .pipe(map(response => response['hydra:member']));
  }

  public loadAffiliations(): Observable<Affiliations> {
    return this.httpService
      .get<ApiCollection<Affiliation>>('/affiliations')
      .pipe(map(response => response['hydra:member']));
  }

  public firstUpdateUserInfos(
    user: Partial<User>,
    fromProfile?: boolean
  ): Observable<Partial<User>> {
    if (fromProfile) {
      return this.httpService.put<Partial<User>>(`/users/${user.id}`, {
        ...user,
        updatedFromProfilPage: true
      });
    }
    return this.httpService.put<Partial<User>>(`/users/${user.id}`, user);
  }

  public login(): Observable<any> {
    return of(true);
  }

  public cancelModifications() {
    this.saveModification$.next(false);
  }

  public applyModifications() {
    this.saveModification$.next(true);
  }
}
