import { createReducer, on } from '@ngrx/store';
import { Collection, defaultCollection } from '@app/shared/models/collection';
import { Notification } from '@app/notification/models';
import {
  NotificationActions,
  NotificationApiActions
} from '@app/notification/state/actions';

export interface State {
  notifications: Collection<Notification>;
  loading: boolean;
  loaded: boolean;
}

export const INITIAL_STATE: State = {
  notifications: defaultCollection<Notification>(),
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(NotificationActions.loadNotifications, state => ({
    ...state,
    notifications: defaultCollection<Notification>(),
    loading: true,
    loaded: false
  })),
  on(NotificationActions.updateNotification, state => ({
    ...state,
    loading: true,
    loaded: false
  })),
  // Api actions
  on(NotificationApiActions.loadNotificationsSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    loading: false,
    loaded: true
  })),
  on(NotificationApiActions.loadNotificationsFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(NotificationApiActions.updateNotificationSuccess, (state, { notification }) => ({
    ...state,
    notifications: {
      count: state.notifications.count,
      items: state.notifications.items.map(item => item.id === notification.id ? notification : item)
    },
    loading: false,
    loaded: true
  })),
  on(NotificationApiActions.updateNotificationFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  }))
);

export const getNotifications = (state: State) => state.notifications;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
