<div class="welcome-texts" *ngIf="user">
  <h2>
    Bonjour {{ user.firstname }} {{ user.lastname }},
    <br />
  </h2>
  <p>
    Nous avons encore besoin de quelques renseignements avant que vous ne vous
    lanciez :
  </p>
</div>
