<div class="notifications-container" (click)="visible = true; $event.stopPropagation()">
  <div
    class="notifications-button flex-column align-items-center justify-content-center"
  >
    <ng-container  *ngIf="count$ | async as count">
      <div class="notifications-count flex-row align-items-center justify-content-center" *ngIf="count > 0">
        <span *ngIf="count < 100">{{count}}</span>
        <span *ngIf="count >= 100">99+</span>
      </div>
    </ng-container>

    <app-icon src="assets/images/bell.svg" class="darkest-green-fill"></app-icon>
  </div>

  <div class="notifications-widget" *ngIf="visible">
    <div class="widget-header flex-row">
      <div class="widget-title flex-1">Notifications</div>
    </div>

    <div class="widget-content" *ngIf="(notifications$ | async) as notifications">
      <app-notification-list
        [notifications]="notifications"
        (notificationChange)="update($event)"
        (redirect)="visible = false"
      ></app-notification-list>
    </div>
  </div>
</div>
