import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromProfile from './profile.reducer';

export interface State extends fromRoot.State {
  profile: fromProfile.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    profile: fromProfile.reducer
  })(state, action);
}

export const selectProfileState = createFeatureSelector<State>('profile');

export const getProfileState = createSelector(
  selectProfileState,
  state => state.profile
);

export const getSendProfileSuccess = createSelector(
  getProfileState,
  fromProfile.getSendProfileSuccess
);

export const getSendProfileError = createSelector(
  getProfileState,
  fromProfile.getSendProfileError
);

export const getSendProfilePending = createSelector(
  getProfileState,
  fromProfile.getSendProfilePending
);
