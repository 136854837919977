import { createAction, props } from '@ngrx/store';
import { Report } from '@app/report/models/report.model';

const TAG = '[Report/View]';

export const loadReportReasons = createAction(`${TAG} Load report reasons`);

export const addReport = createAction(
  `${TAG} Add report`,
  props<{ report: Report }>()
);
