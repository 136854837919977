import {Pipe, PipeTransform} from '@angular/core';
import {Media} from '../models';
import {HighlightedMedia} from '../models/highlighted-media.model';

@Pipe({
  name: 'mediaRouteParams'
})
export class MediaRouteParamsPipe implements PipeTransform {
  transform(media: Media | HighlightedMedia | number, playlist?: Media): any {
    const targetMedia = this.getTargetMedia(media);
    playlist = playlist ?? (targetMedia?.playlistItems?.length ? targetMedia : undefined);

    return {
      ...(playlist ? {playlist: playlist.id} : {}),
      ...(typeof media === 'object' && (media as HighlightedMedia).media ? {highlight: media.id} : {}),
    };
  }

  getTargetMedia(media: Media | HighlightedMedia | number): Media | undefined {
    return typeof media === 'object' ? ((media as HighlightedMedia).media ?? media) : undefined;
  }
}
