import { createReducer, on } from '@ngrx/store';
import { Collection, defaultCollection } from '@app/shared/models/collection';
import { CommentActions, CommentApiActions } from '@app/comment/state/actions';
import { Comment } from '@app/comment/models/comment.model';
import { MediaApiActions } from '@app/media/state/actions';
import { patchModelRecursively } from '@app/shared/utils/collection-functions';

export interface State {
  comments: Collection<Comment>;
  addedComment?: Comment;
  loading: boolean;
  loaded: boolean;
}

export const INITIAL_STATE: State = {
  comments: defaultCollection<Comment>(),
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(CommentActions.loadComments, state => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(CommentActions.resetComments, state => ({
    ...state,
    comments: defaultCollection<Comment>()
  })),
  on(CommentActions.addComment, state => ({
    ...state,
    addedComment: undefined,
    loading: true,
    loaded: false
  })),
  on(CommentActions.likeComment, CommentActions.unlikeComment, state => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(CommentApiActions.loadCommentsSuccess, (state, { comments }) => ({
    ...state,
    comments,
    loading: false,
    loaded: true
  })),
  on(CommentApiActions.addCommentSuccess, (state, { comment }) => ({
    ...state,
    addedComment: comment,
    loading: false,
    loaded: true
  })),
  on(CommentApiActions.likeCommentSuccess, (state, patch) => ({
    ...state,
    comments: {
      count: state.comments.count,
      items: state.comments.items.map(cmt =>
        patchModelRecursively(cmt, { ...patch, liked: true }, ['replies'])
      )
    },
    loading: false,
    loaded: true
  })),
  on(CommentApiActions.unlikeCommentSuccess, (state, patch) => ({
    ...state,
    comments: {
      count: state.comments.count,
      items: state.comments.items.map(cmt =>
        patchModelRecursively(cmt, { ...patch, liked: false }, ['replies'])
      )
    },
    loading: false,
    loaded: true
  })),
  on(CommentApiActions.loadCommentsFailure, state => ({
    ...state,
    comments: defaultCollection<Comment>(),
    loading: false,
    loaded: false
  })),
  on(CommentApiActions.addCommentFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(
    CommentApiActions.likeCommentFailure,
    CommentApiActions.unlikeCommentFailure,
    state => ({
      ...state,
      loading: false,
      loaded: false
    })
  )
);

export const getComments = (state: State) => state.comments;
export const getAddedComment = (state: State) => state.addedComment;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
