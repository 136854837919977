import { Component, Input, ViewChild } from '@angular/core';
import { Media } from '@app/media/models';
import { ModalComponent } from '@app/shared/modal/modal/modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-media-share-button',
  templateUrl: './media-share-button.component.html',
  styleUrls: ['./media-share-button.component.scss']
})
export class MediaShareButtonComponent {
  mailSubject = 'Voici une vidéo intéressante sur mycampus.Tube';
  mailBody =
    "Bonjour,\n\nVoici une vidéo intéressante que j'ai trouvé sur mycampus.Tube : ";

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @Input()
  media: Media;

  constructor(private router: Router) {}

  get mailTo() {
    return `mailto:?subject=${encodeURI(this.mailSubject)}&body=${encodeURI(
      this.mailBody + this.getMediaUrl() + '\n\n'
    )}`;
  }

  getMediaUrl() {
    return (
      (window as any).location.origin +
      '/#' +
      this.router.serializeUrl(
        this.router.createUrlTree(['/medias', this.media.id])
      )
    );
  }
}
