import {NgModule} from '@angular/core';

import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {SharedModule} from '@app/shared/shared.module';
import {PageComponent} from '@app/page/containers';
import {PageDisplayComponent} from './components/page-display/page-display.component';
import {PageFacade} from './state/page.facade';
import {PageEffects} from '@app/page/state/effects/page.effects';
import {ContactEffects} from '@app/page/state/effects/contact.effects';
import {customPageKey, reducers} from '@app/page/state/reducers';
import {PageRoutingModule} from '@app/page/page-routing.module';
import {PageContentComponent} from './containers/page-content/page-content.component';
import {PageHeaderDisplayComponent} from './components/page-header-display/page-header-display.component';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  imports: [
    PageRoutingModule,
    StoreModule.forFeature(customPageKey, reducers),
    EffectsModule.forFeature([ContactEffects, PageEffects]),
    SharedModule,
    NgSelectModule
  ],
  declarations: [
    PageComponent,
    PageContentComponent,
    PageDisplayComponent,
    PageHeaderDisplayComponent
  ],
  exports: [PageComponent],
  providers: [PageFacade]
})
export class PageModule {
}
