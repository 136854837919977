import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges
} from '@angular/core';

import { Router } from '@angular/router';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import { Observable } from 'rxjs';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'app-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  loggedUser$: Observable<User | null>;

  constructor(private facade: AuthFacade) {}

  ngOnInit() {
    this.loggedUser$ = this.facade.loggedUser$;
  }

  isLogged(user: User) {
    return !!user;
  }

  hasPreHomeValue(user: User) {
    return user.preHomeData;
  }
}
