<div class="media-list type-{{ displayType }}">
  <button
    (click)="scrollLeft()"
    [hidden]="(canScrollLeft$ | async) === false"
    class="btn btn-scroll left"
    [style.width.px]="buttonWidth"
    [style.left.px]="buttonMargin"
  >
    <img src="/assets/images/slide-left.svg" />
  </button>

  <div
    class="wrapper"
    #wrapper
    [style.padding-left.px]="margin"
    [style.padding-right.px]="margin"
  >
    <div class="medias" [ngSwitch]="displayType">
      <ng-container *ngSwitchCase="'classic'">
        <app-media-tile
          *ngFor="let media of medias"
          [media]="media"
          [highlight]="highlight?.id === media.id"
          class="media small-picture"
          [class.card-hover]="cardLink"
          [playlist]="playlist"
          [routerLink]="cardLink ? (media | mediaRoute) : undefined"
          [queryParams]="cardLink ? (media | mediaRouteParams: playlist) : undefined"
          [class.highlight]="highlight?.id === media.id"
          [style.margin-left.px]="itemsMargin"
          [style.margin-right.px]="itemsMargin"
        ></app-media-tile>
      </ng-container>

      <ng-container *ngSwitchCase="'top'">
        <app-media-top-tile
          *ngFor="let media of medias; let index = index"
          [media]="media"
          [position]="index + 1"
          class="media small-picture"
          [style.margin-left.px]="itemsMargin"
          [style.margin-right.px]="itemsMargin"
        ></app-media-top-tile>
      </ng-container>
    </div>
  </div>

  <button
    (click)="scrollRight()"
    [hidden]="(canScrollRight$ | async) === false"
    class="btn btn-scroll right"
    [style.width.px]="buttonWidth"
    [style.right.px]="buttonMargin"
  >
    <img src="/assets/images/slide-right.svg" />
  </button>
</div>
