import { Component, Input, ViewChild } from '@angular/core';
import { ReportFacade } from '@app/report/state/report-facade.service';
import { ReportReason } from '@app/report/models/report-reason.model';
import { Media } from '@app/media/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { ModalComponent } from '@app/shared/modal/modal/modal.component';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss']
})
export class ReportButtonComponent {
  reasons$: Observable<ReportReason[]> = this.reportFacade.reportReasons$;
  selectedReason?: ReportReason;

  @Input()
  media: Media;

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  constructor(
    private reportFacade: ReportFacade,
    private toast: ToasterService
  ) {}

  openModal() {
    this.reportFacade.loadReportReasons();
    this.modal.open();
  }

  sendReport() {
    if (!this.selectedReason) {
      this.toast.pop(
        'error',
        'Veuillez sélectionner les raisons de votre signalement !'
      );
      return;
    }

    this.reportFacade.addReport({
      reason: this.selectedReason,
      media: this.media
    });

    delete this.selectedReason;

    this.reportFacade.addedReport$
      .pipe(untilDestroyed(this), first())
      .subscribe(() => {
        this.toast.pop('success', 'Votre signalement a bien été envoyé !');
        this.modal.close();
      });
  }
}
