import { props, createAction } from '@ngrx/store';

export const uploadRequest = createAction(
  '[File upload] request',
  props<{ file: File }>()
);

export const uploadFailure = createAction(
  '[File upload] failure',
  props<{ error: string }>()
);

export const uploadComplete = createAction('[File upload] complete');
