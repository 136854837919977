import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromUpload from '@app/upload/state/reducers/upload.reducer';

export interface State extends fromRoot.State {
  upload: fromUpload.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    upload: fromUpload.reducer
  })(state, action);
}

export const selectUploadState = createFeatureSelector<State>('upload');

export const getUploadState = createSelector(
  selectUploadState,
  customTag => customTag.upload
);

export const getUploads = createSelector(getUploadState, fromUpload.getUploads);
