import { createReducer, on } from '@ngrx/store';

import { ContactActions } from '@app/page/state/actions';

export interface State {
  messageSentSuccess: boolean;
  messageSentError: boolean;
  messageSentLoading: boolean;
}

export const INITIAL_STATE: State = {
  messageSentSuccess: false,
  messageSentError: false,
  messageSentLoading: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(ContactActions.sendMessage, (state, {}) => ({
    ...INITIAL_STATE,
    messageSentLoading: true
  })),
  on(ContactActions.sendMessageSuccess, (state, {}) => ({
    ...INITIAL_STATE,
    messageSentSuccess: true
  })),
  on(ContactActions.sendMessageFailure, (state, { error }) => ({
    ...INITIAL_STATE,
    messageSentError: true
  })),
  on(ContactActions.sendMessageReset, (state, {}) => ({
    ...INITIAL_STATE
  }))
);

export const getMessageSentSuccess = (state: State) => state.messageSentSuccess;
export const getMessageSentError = (state: State) => state.messageSentError;
export const getMessageSentLoading = (state: State) => state.messageSentLoading;
