import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaderResponse,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { HttpService } from '@app/core/services/http.service';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import { User } from '../models/user.model';
import { UserFollow } from '@app/user/models/user-follow.model';
import { Iri } from '@app/shared/models/api-entity';
import { ApiCollection } from '@app/shared/models/api-collection';
import { IriToUrl } from '@app/shared/utils/iri-functions';
import { Collection, collectionFromApi } from '../../shared/models/collection';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public constructor(
    private httpClient: HttpClient,
    private http: HttpService,
    private auth: AuthFacade
  ) {}

  public addImage(file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', file);

    const options = {
      reportProgress: true
    };

    const req = new HttpRequest(
      'POST',
      `${environment.apiHost}${environment.apiBasePath}/users/me/upload_avatar`,
      formData,
      options
    );

    return this.httpClient
      .request(req)
      .pipe(filter(event => event instanceof HttpHeaderResponse));
  }

  public getUser(id: number): Observable<User> {
    return this.http.get<User>(`/users/${id}`);
  }

  public getUserFollows(): Observable<Collection<UserFollow>> {
    return this.auth.loggedUser$.pipe(
      first(),
      switchMap(connected => {
        if (!connected) {
          throw new Error(
            'Should be authenticated to subscribe to other user channel'
          );
        }

        return this.http
          .get<ApiCollection<UserFollow>>(`/user_follows`, {
            'follower.id': connected.id.toString()
          })
          .pipe(map(collection => collectionFromApi(collection)));
      })
    );
  }

  public getUserFollowFor(userId: number): Observable<UserFollow | undefined> {
    return this.auth.loggedUser$.pipe(
      first(),
      switchMap(connected => {
        if (!connected) {
          throw new Error(
            'Should be authenticated to subscribe to other user channel'
          );
        }

        return this.http
          .get<ApiCollection<UserFollow>>(`/user_follows`, {
            'follower.id': connected.id.toString(),
            'followed.id': userId.toString()
          })
          .pipe(
            map(collection =>
              collection['hydra:member'].length
                ? collection['hydra:member'][0]
                : undefined
            )
          );
      })
    );
  }

  public followUser(userId: number): Observable<UserFollow> {
    return this.auth.loggedUser$.pipe(
      first(),
      switchMap(connected => {
        if (!connected) {
          throw new Error(
            'Should be authenticated to subscribe to other user channel'
          );
        }

        return this.http.post<UserFollow>(`/user_follows`, {
          follower: connected.id.toString(),
          followed: userId.toString(),
          followedAt: new Date().toISOString()
        } as UserFollow);
      })
    );
  }

  public unfollowUser(userFollow: UserFollow): Observable<void> {
    return this.http.delete(IriToUrl(userFollow['@id'] as Iri));
  }

  public generateApiVideoUploadToken(
    id: number
  ): Observable<{ lastApiVideoUploadToken: string }> {
    return this.http.get(`/users/${id}/generate_api_video_upload_token`);
  }

  public generateApiVideoUploadTokenForConnected(): Observable<{
    lastApiVideoUploadToken: string;
  }> {
    return this.auth.loggedUser$.pipe(
      filter(user => user !== null),
      first(),
      switchMap(user => this.generateApiVideoUploadToken((user as User).id))
    );
  }
}
