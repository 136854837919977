import { Component, OnInit, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import { ProfileFacade } from '@app/user/state/profile.facade';
import { FormControl, Validators } from '@angular/forms';
import { validateEmail } from '@app/shared/validators';
@Component({
  selector: 'app-profile',
  templateUrl: 'profile.component.html',
  styleUrls: ['profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  loggedUser$: Observable<User | null>;
  sendProfileSuccess$: Observable<boolean>;
  sendProfileError$: Observable<boolean>;
  sendProfilePending$: Observable<boolean>;

  email = new FormControl('', [Validators.required, validateEmail]);

  submitted = false;
  constructor(private authFacade: AuthFacade, private facade: ProfileFacade) {}

  ngOnInit() {
    this.loggedUser$ = this.authFacade.loggedUser$;
    this.sendProfileSuccess$ = this.facade.sendProfileSuccess$;
    this.sendProfileError$ = this.facade.sendProfileError$;
    this.sendProfilePending$ = this.facade.sendProfilePending$;
  }

  onUploadFile(file: File) {
    this.facade.uploadRequest(file);
  }

  ngOnDestroy() {
    this.authFacade.reloadUser();
  }
}
