<div class="comments-count">
  <div class="spacer"></div>
  <img src="assets/images/chat.svg" />
  <b>{{ commentsCount$ | async }}</b>
  <span>commentaires</span>
</div>

<ng-container
  *ngIf="{
    user: user$ | async,
    media: media$ | async,
    comment: comments$ | async
  } as data"
>
  <app-comment-form
    [user]="data.user"
    [media]="data.media"
    (comment)="addComment($event)"
  ></app-comment-form>

  <app-comment-list
    *ngIf="comments$"
    [user]="data.user"
    [media]="data.media"
    [comments]="data.comment"
    [scrollTo]="scrollTo$ | async"
    (addComment)="addComment($event)"
    (like)="likeComment($event)"
  ></app-comment-list>
</ng-container>
