import { createAction, props } from '@ngrx/store';
import { Collection } from '@app/shared/models/collection';
import { Category } from '@app/category/models';

const TAG = '[Category/Api]';

export const loadCategoriesSuccess = createAction(
  `${TAG} Load categories SUCCESS`,
  props<{ categories: Collection<Category> }>()
);

export const loadCategoriesFailure = createAction(
  `${TAG} Load categories FAILURE`,
  props<{ error: any }>()
);

export const loadCategorySuccess = createAction(
  `${TAG} Load category SUCCESS`,
  props<{ category: Category }>()
);

export const loadCategoryFailure = createAction(
  `${TAG} Load category FAILURE`,
  props<{ error: any }>()
);
