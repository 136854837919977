<nav *ngIf="loggedUser" class="navbar navbar-expand bg-white main-navbar">
  <!-- MyCampus Logo -->
  <div class="navbar-side">
    <a class="navbar-menu" (click)="onToggleMenu()">
      <img class="menu-hamburger" src="/assets/images/burger.svg" alt="Menu" />
    </a>
    <a routerLink="/" class="navbar-campus">
      <img
        class="logomycampustube"
        src="/assets/images/logo.png?270224"
        alt="Logo MyCampus Tube"
      />
      <img
        class="logomycampustube-small"
        src="/assets/images/logo-small.png?270224"
        alt="Logo MyCampus Tube"
      />
    </a>
  </div>

  <app-medias-search-input class="navbar-search"></app-medias-search-input>

  <app-button-outlined
    text="Gérer mes contenus"
    icon="/assets/images/cog.svg"
    [routerLink]="['/my-content']"
    routerLinkActive="active"
    shrinkable="true"
  ></app-button-outlined>

  <app-button-outlined
    class="share"
    text="Partager mes connaissances !"
    icon="/assets/images/add.svg"
    [routerLink]="['/medias/upload']"
    shrinkable="true"
  ></app-button-outlined>

  <app-notifications-widget></app-notifications-widget>

  <!-- Avatar -->
  <div class="navbar-profile" (clickOutside)="closeWidget()">
    <span
      class="profile-wrapper"
      (click)="opened && onToggleMenu()"
      (mouseenter)="hover = true"
      (mouseleave)="hover = false"
    >
      <app-profile-picture
        [user]="loggedUser"
        [hover]="hover"
        [route]="false"
        (click)="pictureClick()"
      ></app-profile-picture>
    </span>

    <app-profile-widget
      *ngIf="showWidget"
      [user]="loggedUser"
      (logout)="logout.emit(); closeWidget()"
      (routing)="closeWidget()"
    ></app-profile-widget>
  </div>
</nav>
