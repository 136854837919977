import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Media, PlaylistItem} from '../../models';
import {MediaService} from '../../services';
import {changeItemPosition, removePlaylistItem} from '../../../shared/utils/playlist-functions';
import {AuthFacade} from '../../../authentication/state/auth.facade';

@Component({
  selector: 'app-playlist-medias-form-list',
  templateUrl: './playlist-medias-form-list.component.html',
  styleUrls: ['./playlist-medias-form-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaylistMediasFormListComponent {
  connected$ = this.auth.loggedUser$;

  @Input()
  searching = false;

  @Input()
  playlist: Media;

  @Output()
  playlistChange = new EventEmitter<Media>();

  @Input()
  medias: Media[] = [];

  constructor(
    private auth: AuthFacade,
    private mediaService: MediaService,
    private change: ChangeDetectorRef
  ) {
  }

  getItemForMedia(media: Media) {
    if (!this.playlist) {
      return undefined;
    }

    return this.playlist.playlistItems.find(
      item => media.id === item.mediaItem.id
    );
  }

  addMedia(media: Media) {
    this.playlistChange.emit({
      ...this.playlist,
      playlistItems: [
        ...this.playlist.playlistItems,
        {
          position: this.playlist.playlistItems.length + 1,
          mediaItem: media,
          mediaItemId: media.id
        }
      ],
      playlistItemsCount: this.playlist.playlistItems.length
    });
    this.change.markForCheck();
  }

  removePlaylistItem(item: PlaylistItem) {
    this.playlistChange.emit(removePlaylistItem(this.playlist, item));
    this.change.markForCheck();
  }

  changeItemPosition(item: PlaylistItem, position: number) {
    const newPlaylist = changeItemPosition(this.playlist, item, position);

    if (!newPlaylist) {
      return;
    }

    this.playlistChange.emit(newPlaylist);
    this.change.markForCheck();
  }

  trackByMediaId(index: number, media: Media) {
    return media.id;
  }
}
