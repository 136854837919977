<form [formGroup]="form">
  <div class="form-section">
    <span class="form-title">Trier par :</span>

    <select formControlName="order">
      <option *ngFor="let order of pConfig.availableOrders" [value]="order">
        {{ gerOrderText(order) }}
      </option>
    </select>
  </div>

  <div class="form-section">
    <span class="form-title">Type de médias :</span>

    <label>
      <span>Vidéos</span>
      <input type="checkbox" formControlName="videoType" />
    </label>

    <label>
      <span>Pdf</span>
      <input type="checkbox" formControlName="pdfType" />
    </label>

    <label>
      <span>Parcours</span>
      <input type="checkbox" formControlName="playlistType" />
    </label>
  </div>
</form>
