import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-profile-confirmation',
  templateUrl: 'profile-confirmation.component.html',
  styleUrls: ['profile-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileConfirmationComponent {
  constructor(public bsModalRef: BsModalRef) {}

  continue() {
    this.bsModalRef.hide();
  }
}
