import { createAction, props } from '@ngrx/store';

import { Tag } from '@app/tag/models/tag.model';
import { Collection } from '@app/shared/models/collection';

export const loadTagsSuccess = createAction(
  '[Tags] Load tags success',
  props<{ tags: Collection<Tag> }>()
);
export const loadTagsFailure = createAction(
  '[Tags] Load tags failure',
  props<{ error: any }>()
);
