import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services';
import {
  Upload,
  UploadedResponse,
  UploadType
} from '@app/upload/models/upload.model';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { environment } from '@env/environment';
import { map, switchMap, tap } from 'rxjs/operators';
import { UploadProgress } from '@app/upload/models/upload-progress.model';
import { MediaPdf } from '@app/upload/models/media-pdf.model';
import { ApiVideo } from '@app/upload/models/api-video.model';
import { MediaImage } from '@app/upload/models/media-image.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private chunkSize = 100000000; // 100Mb

  public constructor(
    private httpService: HttpService,
    private http: HttpClient
  ) {}

  public uploadFile(upload: Upload) {
    // Uploading video in chunks
    if (upload.type === UploadType.API_VIDEO) {
      return this.uploadVideoWithChunk(upload);
    }

    const formData = new FormData();
    formData.append('file', upload.file);

    const req = new HttpRequest('POST', this.getUrl(upload), formData, {
      reportProgress: true,
      params: upload.params
        ? new HttpParams({ fromObject: upload.params })
        : new HttpParams()
    });

    let updatedUpload: Upload = {
      ...upload
    };

    return this.http.request<UploadedResponse>(req).pipe(
      map((event: HttpEvent<UploadedResponse>) => {
        if (event === null) {
          return updatedUpload;
        }

        updatedUpload = this.handleResponseSent(updatedUpload, event);
        updatedUpload = this.handleResponseProgress(updatedUpload, event);
        updatedUpload = this.handleResponse(updatedUpload, event);

        return updatedUpload;
      })
    );
  }

  prepareChunks(upload: Upload) {
    const chunksQuant = Math.ceil(upload.file.size / this.chunkSize);
    const chunks = [];
    const chunkId = 0;
    let start: number;
    let end: number;

    for (let i = 0; i < chunksQuant; i++) {
      start = i * this.chunkSize;
      end = Math.min(start + this.chunkSize, upload.file.size);

      chunks.push({
        data: upload.file.slice(start, end),
        start,
        end
      });
    }

    return {
      ...upload,
      chunks,
      chunkId,
      chunkUploaded: false
    };
  }

  uploadVideoWithChunk(upload: Upload): Observable<Upload> {
    return this.uploadChunks(this.prepareChunks(upload));
  }

  private uploadChunk(upload: Upload): Observable<Upload> {
    if (upload.chunks === undefined || upload.chunkId === undefined) {
      return of(upload);
    }

    upload.chunkUploaded = false;
    const formData = new FormData();

    if (upload.apiVideoId !== undefined) {
      formData.append('videoId', upload.apiVideoId);
    }

    const chunk = upload.chunks[upload.chunkId];
    formData.append('file', chunk.data);

    const req = new HttpRequest('POST', this.getUrl(upload), formData, {
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Range': `bytes ${chunk.start}-${chunk.end - 1}/${
          upload.file.size
        }`
      }),
      params: upload.params
        ? new HttpParams({ fromObject: upload.params })
        : new HttpParams()
    });

    return this.http.request<UploadedResponse>(req).pipe(
      map((event: HttpEvent<UploadedResponse>) => {
        if (event === null) {
          return upload;
        }

        if (upload.chunkId === 0) {
          upload = this.handleResponseSent(upload, event);
        }

        upload = this.handleResponseProgress(upload, event);

        if (this.isLastChunk(upload)) {
          upload = this.handleResponse(upload, event);
        }

        upload = this.handleChunkResponse(upload, event);

        return upload;
      })
    );
  }

  private uploadChunks(upload: Upload): Observable<Upload> {
    return this.uploadChunk(upload).pipe(
      switchMap(newUpload =>
        newUpload.chunkUploaded && this.hasMoreChunk(newUpload)
          ? this.uploadChunks(newUpload)
          : of(newUpload)
      )
    );
  }

  private isLastChunk(upload: Upload) {
    return (
      upload.chunkId !== undefined &&
      upload.chunks !== undefined &&
      upload.chunkId === upload.chunks.length - 1
    );
  }

  private hasMoreChunk(upload: Upload) {
    return (
      upload.chunkId !== undefined &&
      upload.chunks !== undefined &&
      upload.chunkId <= upload.chunks.length - 1
    );
  }

  private handleResponseSent(
    upload: Upload,
    event: HttpEvent<UploadedResponse>
  ) {
    return event.type !== HttpEventType.Sent
      ? upload
      : {
          ...upload,
          progress: {
            startedAt: new Date(),
            progress: 0
          }
        };
  }

  private handleResponseProgress(
    upload: Upload,
    event: HttpEvent<UploadedResponse>
  ) {
    return event.type !== HttpEventType.UploadProgress
      ? upload
      : {
          ...upload,
          progress: {
            ...(upload.progress as UploadProgress),
            progress: Math.round(
              ((// Currently uploaded size
              (event as HttpProgressEvent).loaded +
                // Plus already uploaded size
                (upload.chunkId ?? 0) * this.chunkSize) /
                // Divide per total size in %
                upload.file.size) *
                100
            )
          }
        };
  }

  private handleResponse(upload: Upload, event: HttpEvent<UploadedResponse>) {
    return event.type !== HttpEventType.Response
      ? upload
      : {
          ...upload,
          progress: {
            ...(upload.progress as UploadProgress),
            progress: 100
          },
          response: this.getResponse(upload.type, event)
        };
  }

  private handleChunkResponse(
    upload: Upload,
    event: HttpEvent<UploadedResponse>
  ) {
    return event.type !== HttpEventType.Response || !upload.chunks
      ? upload
      : {
          ...upload,
          apiVideoId: (event.body as ApiVideo).videoId ?? '',
          chunkUploaded: true,
          chunkId: (upload.chunkId ?? 0) + 1
        };
  }

  private getUrl(upload: Upload) {
    switch (upload.type) {
      case UploadType.MEDIA_IMAGE:
        return this.httpService.getApiUrl('/media_image_files');
      case UploadType.MEDIA_PDF:
        return this.httpService.getApiUrl('/media_pdf_files');
      case UploadType.API_VIDEO:
        return `${environment.apiVideoHost}/upload`;
    }
  }

  private getResponse(type: UploadType, data: HttpResponse<UploadedResponse>) {
    switch (type) {
      case UploadType.MEDIA_IMAGE:
        return data.body as MediaImage;
      case UploadType.MEDIA_PDF:
        return data.body as MediaPdf;
      case UploadType.API_VIDEO:
        return data.body as ApiVideo;
    }
  }
}
