import {Media, MediaType, PlaylistItem} from '../../media/models';

export function removePlaylistItem(playlist: Media, item: PlaylistItem): Media {
  if (!playlist || playlist.type !== MediaType.PLAYLIST) {
    return playlist;
  }

  // remove and reposition items
  return {
    ...playlist,
    playlistItems: playlist.playlistItems
      .filter(it => it.position !== item.position)
      .map(it => ({
        ...it,
        position: it.position > item.position ? it.position - 1 : it.position
      }))
  };
}

export function changeItemPosition(playlist: Media, item: PlaylistItem, position: number): Media | false {
  if (
    !playlist ||
    playlist.type !== MediaType.PLAYLIST ||
    item.position === position ||
    position < 0 ||
    position > playlist.playlistItemsCount
  ) {
    return false;
  }

  const itemIndex = playlist.playlistItems.findIndex(
    oItem => oItem.position === item.position
  );

  const otherIndex = playlist.playlistItems.findIndex(
    oItem => oItem.position === position
  );

  if (otherIndex === -1) {
    return false;
  }

  return {
    ...playlist,
    playlistItems: [
      ...playlist.playlistItems.map((it, index) => {
        if (index === otherIndex) {
          return {
            ...it,
            position: item.position
          };
        } else if (index === itemIndex) {
          return {
            ...it,
            position
          };
        } else {
          return it;
        }
      }).sort((i1, i2) => i1.position - i2.position)
    ]
  };
}
