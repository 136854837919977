import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MediasComponent } from '@app/media/containers/medias/medias.component';
import { AuthGuard } from '@app/authentication/services';
import { MediaComponent } from '@app/media/containers/media/media.component';
import { MediasSearchComponent } from '@app/media/containers/medias-search/medias-search.component';
import { UploadComponent } from '@app/media/containers/upload/upload.component';

const MEDIA_ROUTES: Routes = [
  { path: 'medias', component: MediasComponent, canActivate: [AuthGuard] },
  {
    path: 'medias/search',
    component: MediasSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'medias/upload',
    component: UploadComponent,
    canActivate: [AuthGuard]
  },
  { path: 'medias/:id', component: MediaComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(MEDIA_ROUTES)],
  exports: [RouterModule]
})
export class MediaRoutingModule {}
