import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Notification} from '../../models';
import {User} from '../../../user/models/user.model';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Comment} from '../../../comment/models/comment.model';
import {Media, MediaLike, MediaType} from '../../../media/models';
import {CommentLike} from '../../../comment/models/comment-like.model';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemComponent {

  @Input()
  notification?: Notification | null;

  @Output()
  notificationChange = new EventEmitter<Notification>();

  @Output()
  redirect = new EventEmitter<void>();

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
  }

  get extraData() {
    return this.notification?.extraData;
  }

  get message() {
    return this.notification?.extraData.message ? this.sanitizer.bypassSecurityTrustHtml(this.notification?.extraData.message) : '';
  }

  get user() {
    return this.extraData?.messageFromUser;
  }

  markAsRead() {
    if (!this.notification) {
      return;
    }

    this.notificationChange.emit({
      ...this.notification,
      viewedAt: (new Date()).toISOString()
    });
  }

  redirectTo() {
    if (!this.notification?.extraData) {
      return;
    }

    let path = '';
    let media: Media;
    let user: User;
    let comment: Comment;
    let queryParams = {};

    switch (this.notification.extraData.relatedObjectClass) {
      case 'Comment':
        comment = this.notification.extraData.relatedObject as Comment;
        media = comment.media as Media;
        path = `/medias/${media.id}`;
        queryParams = {comment: comment.id};
        break;
      case 'Media':
        media = this.notification.extraData.relatedObject as Media;
        if (media.type === MediaType.PLAYLIST) {
          user = this.notification.extraData.messageFromUser as User;
          path = `/channel/${user.id}`;
        } else {
          path = `/medias/${media.id}`;
        }
        break;
      case 'UserFollow':
        user = this.notification.extraData.messageFromUser as User;
        path = `/channel/${user.id}`;
        break;
      case 'CommentLike':
        const commentLike = this.notification.extraData.relatedObject as CommentLike;
        comment = commentLike.comment as Comment;
        media = comment.media as Media;
        path = `/medias/${media.id}`;
        queryParams = {comment: comment.id};
        break;
      case 'PlaylistItem':
        user = this.notification.extraData.messageFromUser as User;
        path = `/channel/${user.id}`;
        break;
      case 'MediaUserLike':
        const mediaLike = this.notification.extraData.relatedObject as MediaLike;
        media = mediaLike.media as Media;
        path = `/medias/${media.id}`;
        break;
    }

    this.markAsRead();
    this.redirect.emit();
    this.router.navigate([path], {queryParams});
  }
}
