import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserChannelFacade } from '@app/channel-media/state/user-channel-facade.service';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getColorForNumber } from '@app/shared/models/colors';
import { MediaResearchFormValue } from '@app/media/components/media-research-form/media-research-form.component';
import { MediaSearchService } from '@app/media/services/media.search.service';
import { AuthFacade } from '../../../authentication/state/auth.facade';
import { combineLatest } from 'rxjs';
import {
  defaultMediaSearchModel,
  MediaSearchOrder
} from '../../../media/models';

@UntilDestroy()
@Component({
  selector: 'app-user-channel',
  templateUrl: './user-channel.component.html',
  styleUrls: ['./user-channel.component.scss']
})
export class UserChannelComponent implements OnInit {
  medias$ = this.facade.getUserChannelMedias$;
  connected$ = this.auth.loggedUser$;
  searchForm$ = this.mediaSearchService.searchForm$;
  searchConfig$ = this.mediaSearchService.config$;
  userChannel$ = this.facade.getUserChannel$;

  channelId$ = this.route.params.pipe(
    map(params => (params.id ? parseInt(params.id, 10) : undefined)),
    distinctUntilChanged()
  );

  color$ = this.userChannel$.pipe(
    map(user => (user ? getColorForNumber(user.id) : 'green'))
  );

  constructor(
    private route: ActivatedRoute,
    private auth: AuthFacade,
    private mediaSearchService: MediaSearchService,
    private facade: UserChannelFacade
  ) {}

  ngOnInit(): void {
    this.mediaSearchService.changeConfig({
      availableOrders: [MediaSearchOrder.CREATED_AT, MediaSearchOrder.TITLE]
    });

    this.channelId$
      .pipe(untilDestroyed(this))
      .subscribe(id => (id ? this.loadChannel(id, true) : undefined));

    this.mediaSearchService.search$
      .pipe(untilDestroyed(this))
      .subscribe(search => {
        this.facade.changeUserChannelMediaSearch(search);
      });
  }

  loadChannel(channelId: number, reset = false): void {
    this.facade.loadUserChannel(channelId, reset);
  }

  search(value: MediaResearchFormValue) {
    this.mediaSearchService.search(value, [], this.route);
  }

  loadMore() {
    combineLatest([
      this.facade.getUserChannelMediasSearch$,
      this.facade.loading$
    ])
      .pipe(
        first(),
        filter(([search, loading]) => !loading && !!search && search.hasMore)
      )
      .subscribe(([search]) => {
        search = search ? search : defaultMediaSearchModel();
        this.facade.changeUserChannelMediaSearch({
          ...search,
          page: search.page + 1
        });
      });
  }
}
