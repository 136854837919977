import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinksBarComponent } from '@app/links-bar/containers';
import { ExternalLinkFacade } from '@app/links-bar/state/external-link.facade';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@app/links-bar/state/reducers';
import { ExternalLinkEffects } from '@app/links-bar/state/effects';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LinksBarComponent],
  exports: [LinksBarComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ExternalLinkEffects]),
    StoreModule.forFeature('externalLinks', reducers),
    RouterModule
  ],
  providers: [ExternalLinkFacade]
})
export class LinksBarModule {}
