import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { User } from '../../../user/models/user.model';
import { Menu } from '@app/shell/shared/menu.model';

@Component({
  selector: 'app-navbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() loggedUser: User;
  @Input() opened: boolean;
  @Input() pageTitle = '';
  @Input() previousLink: string[] | null;
  @Input() menu: Menu[];
  @Output() logout = new EventEmitter<string>();
  @Output() toggleMenu = new EventEmitter();

  hover = false;
  showWidget = false;

  onLogout(event: string) {
    this.logout.emit(event);
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  pictureClick() {
    this.showWidget = !this.showWidget;
  }

  closeWidget() {
    this.showWidget = false;
  }
}
