import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {defaultMedia, Media, MediaType} from '@app/media/models';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@app/user/services/user.service';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import {
  combineLatest,
  interval,
  Observable,
  of,
  Subscription,
  throwError
} from 'rxjs';
import { MediaService } from '@app/media/services';
import { Upload, UploadType } from '@app/upload/models/upload.model';
import { MediaPdf } from '@app/upload/models/media-pdf.model';
import {
  filter,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  takeWhile,
  withLatestFrom
} from 'rxjs/operators';
import { UploadProgress } from '@app/upload/models/upload-progress.model';
import { ApiVideo } from '@app/upload/models/api-video.model';
import { ModalComponent } from '@app/shared/modal/modal/modal.component';
import { UploadFacade } from '@app/upload/state/upload-facade.service';
import { MediaImage } from '@app/upload/models/media-image.model';
import { UploadDetailComponent } from '@app/media/components/upload-detail/upload-detail.component';
import { ReloadService } from '@app/authentication/services/reload.service';
import { ErrorService } from '@app/core/services/error.service';

enum UploadStep {
  MEDIA_TYPE,
  MEDIA_FILE,
  MEDIA_INFO
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ top: '+100%', opacity: 0 }),
        animate(
          '1s cubic-bezier(0.495, -0.395, 0.495, 1.395)',
          style({ top: 0, opacity: 1 })
        )
      ]),
      transition(':leave', [
        style({ top: 0, opacity: 1 }),
        animate(
          '1s cubic-bezier(0.495, -0.395, 0.495, 1.395)',
          style({ top: '-100%', opacity: 0 })
        )
      ])
    ])
  ]
})
export class UploadComponent implements OnInit, OnDestroy {
  MediaType = MediaType;
  UploadStep = UploadStep;
  inputFile?: File;
  mediaType?: MediaType;
  media?: Partial<Media>;
  uploadedMedia?: Media;
  thumbnail?: File;
  thumbnailVideo$?: Observable<string | null>;
  uploadMediaResource$?: Observable<Upload | null>;
  uploadMediaImage$?: Observable<Upload | undefined | null>;
  videoDuration?: number;
  refreshVideoPicture = true;
  submiting = false;
  mediaSubscription: Subscription;

  @ViewChild('modalPublish', { static: true })
  modalPublish: ModalComponent;

  @ViewChild(UploadDetailComponent)
  uploadDetailComponent: UploadDetailComponent;

  private pFile?: File;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private auth: AuthFacade,
    private mediaService: MediaService,
    private errorService: ErrorService,
    private reloadService: ReloadService,
    private uploadFacade: UploadFacade,
    private changes: ChangeDetectorRef
  ) {}

  get file() {
    return this.pFile;
  }

  set file(file: File | undefined) {
    this.pFile = file;
    this.media = {
      ...defaultMedia(),
      type: this.mediaType
    };

    if (this.mediaType === MediaType.VIDEO) {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        this.videoDuration = video.duration;
      };

      video.src = URL.createObjectURL(file);
    }

    this.changes.detectChanges();
    this.upload();
  }

  @HostBinding('class.fullscreen')
  get isFullScreen() {
    return this.currentStep < UploadStep.MEDIA_INFO;
  }

  get currentStep(): UploadStep {
    switch (true) {
      case this.mediaType === undefined:
        return UploadStep.MEDIA_TYPE;
      case this.file === undefined:
        return UploadStep.MEDIA_FILE;
      default:
        return UploadStep.MEDIA_INFO;
    }
  }

  get canSubmit() {
    return (
      this.uploadDetailComponent &&
      this.uploadDetailComponent.form.valid &&
      !this.submiting
    );
  }

  ngOnInit(): void {
    delete this.inputFile;
    delete this.mediaType;
    delete this.media;
    delete this.thumbnail;
    delete this.uploadMediaResource$;
    delete this.uploadMediaImage$;
    delete this.thumbnailVideo$;
    delete this.uploadedMedia;
    this.refreshVideoPicture = true;
    this.submiting = false;

    // this.debugModal();
    // this.debug(MediaType.PDF);
  }

  debug(type = MediaType.PDF, delay = 1000) {
    this.pFile = {
      size: 1502184,
      name: type === MediaType.PDF ? 'test.pdf' : 'test.mp4',
      lastModified: 0,
      type: type === MediaType.PDF ? 'application/pdf' : 'video/mp4'
    } as File;

    this.mediaType = type;

    this.media = {
      ...defaultMedia(),
      type: this.mediaType,
    };

    this.videoDuration = 2105;

    let upload: Upload = {
      id: 0,
      file: this.pFile,
      type:
        type === MediaType.PDF ? UploadType.MEDIA_PDF : UploadType.API_VIDEO,
      progress: { progress: 0, startedAt: new Date() },
      params: {}
    };

    this.uploadMediaResource$ = interval(delay).pipe(
      map(() => {
        if (upload.progress && upload.progress.progress >= 100) {
          upload = {
            ...upload,
            response:
              type === MediaType.PDF
                ? ({
                    id: 1,
                    createdAt: '',
                    updatedAt: '',
                    fileName: this.pFile?.name ?? ''
                  } as MediaPdf)
                : ({
                    videoId: 'a478ez4a89e4z8ae4z89a'
                  } as ApiVideo)
          };
        } else {
          upload = {
            ...upload,
            progress: {
              ...(upload.progress as UploadProgress),
              progress: upload.progress ? upload.progress.progress + 25 : 0
            }
          };
        }

        return upload;
      }),
      startWith(null),
      share()
    );
  }

  debugModal() {
    this.uploadedMedia = {
      ...this.media,
      id: 1
    } as Media;

    setTimeout(() => this.modalPublish.open(), 1000);
  }

  upload() {
    if (this.file === undefined || this.mediaType === undefined) {
      return;
    }

    this.uploadMediaResource$ = this.mediaService
      .uploadMedia(this.file, this.mediaType)
      .pipe(shareReplay(1));

    this.thumbnailVideo$ = this.uploadMediaResource$.pipe(
      map(upload => {
        if (
          upload &&
          upload.type === UploadType.API_VIDEO &&
          upload.response !== undefined
        ) {
          return (upload.response as ApiVideo).assets.thumbnail;
        } else {
          return null;
        }
      }),
      switchMap(image =>
        interval(2000).pipe(
          map(() =>
            image !== null ? `${image}?t=${new Date().getTime()}` : null
          )
        )
      ),
      takeWhile(() => this.refreshVideoPicture)
    );

    this.changes.detectChanges();
  }

  submit() {
    if (!this.canSubmit) {
      this.uploadDetailComponent.form.markAllAsTouched();
      this.errorService.pop('Veuillez suivre les indications du formulaire');
      this.changes.detectChanges();
      return;
    }

    if (this.uploadMediaResource$ === undefined) {
      this.errorService.pop("Veuillez attendre la fin de l'envoi du fichier");
      return;
    }

    this.submiting = true;

    // Upload the thumbnail
    if (this.uploadMediaImage$ === undefined) {
      this.uploadMediaImage$ = !!this.thumbnail
        ? this.uploadFacade
            .uploadFile(this.thumbnail, UploadType.MEDIA_IMAGE)
            .pipe(shareReplay(1))
        : of(null);
    }

    // Track both uploads
    this.mediaSubscription = combineLatest([
      this.uploadMediaResource$,
      this.uploadMediaImage$
    ])
      .pipe(
        // Finishing image upload first
        filter(
          ([uploadMedia, uploadImage]) =>
            uploadImage === null ||
            (uploadImage !== undefined && uploadImage.response !== undefined)
        ),
        // Take connected user for creator info
        withLatestFrom(this.auth.loggedUser$),
        switchMap(([[uploadMedia, uploadImage], connected]) => {
          if (connected === null || this.media === undefined) {
            return throwError(
              'Vous devez être connecter pour faire cette action'
            );
          }

          if (!uploadMedia || uploadMedia.response === undefined) {
            return throwError("Veuillez attendre la fin de l'envoi du fichier");
          }

          // Add media id
          if (uploadMedia.type === UploadType.MEDIA_PDF) {
            this.media.pdfFile = (uploadMedia.response as MediaPdf)['@id'];
          } else {
            this.media.apiVideoId = (uploadMedia.response as ApiVideo).videoId;
          }

          // Custom thumbnail
          if (uploadImage !== null) {
            this.media.imageFile = ((uploadImage as Upload)
              .response as MediaImage)['@id'];
          }

          // Add creator
          this.media.creator = connected;

          // Create media
          this.refreshVideoPicture = true;

          return this.mediaService
            .uploadMediaTags(this.media as Media)
            .pipe(switchMap(media => this.mediaService.createMedia(media)));
        })
      )
      .subscribe(
        media => {
          this.uploadedMedia = media;
          this.modalPublish.open();
          this.changes.detectChanges();
        },
        error => {
          this.errorService.pop(error);
          this.refreshVideoPicture = true;
          this.submiting = false;
          this.changes.detectChanges();
        }
      );
  }

  restart(event: Event) {
    event.preventDefault();
    this.reloadService.navigateWithReload(['/medias/upload']);
  }

  ngOnDestroy(): void {
    if (this.mediaSubscription && !this.mediaSubscription.closed) {
      this.mediaSubscription.unsubscribe();
    }
  }
}
