<div class="media-view">
  <div class="wrapper">
    <!-- Video (playing) -->
    <app-media-view
      *ngIf="featuredMedia.media.type === MediaType.VIDEO"
      [media]="featuredMedia"
      [extraData]="featuredMediaExtraData"
      (needExtraData)="needExtraData.emit()"
      [autoplay]="true"
    ></app-media-view>

    <!-- PDF/Playlist (redirect) -->
    <app-media-view
      *ngIf="featuredMedia.media.type !== MediaType.VIDEO"
      [media]="featuredMedia"
      [extraData]="featuredMediaExtraData"
      [routerLink]="featuredMedia.media | mediaRoute"
      [queryParams]="featuredMedia.media | mediaRouteParams"
    ></app-media-view>
  </div>
</div>
<div class="media-info">
  <div class="wrapper">
    <app-media-badge *ngIf="featuredMedia.media.type === MediaType.PLAYLIST">Playlist à la une</app-media-badge>
    <app-media-badge *ngIf="featuredMedia.media.type === MediaType.VIDEO">Vidéo à la une</app-media-badge>
    <app-media-badge *ngIf="featuredMedia.media.type === MediaType.PDF">PDF à la une</app-media-badge>

    <div
      class="media-title router-link"
      [routerLink]="featuredMedia.media | mediaRoute"
      [title]="featuredMedia.media.title"
    >
      {{ featuredMedia.media.title }}
    </div>

    <div class="media-description">
      <div class="media-description-text">
        <div
          class="media-description-ellipsis"
          *nestedEllipsis="true"
          [innerHTML]="featuredMedia.media.description"
        ></div>
      </div>
      <div
        class="media-description-sizer"
        [innerHTML]="featuredMedia.media.description"
      ></div>
    </div>

    <div class="media-footer">
      <div class="media-author">
        <app-media-creator
          [media]="featuredMedia.media"
          theme="light"
        ></app-media-creator>
      </div>

      <div class="media-controls">
        <button
          class="play router-link"
          [routerLink]="featuredMedia.media | mediaRoute"
          [queryParams]="featuredMedia.media | mediaRouteParams"
        >
          Lecture
        </button>
      </div>
    </div>
  </div>
</div>
