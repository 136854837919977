import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Media, MediaExtraData, MediaType } from '@app/media/models';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'app-last-media-card',
  templateUrl: './last-media-card.component.html',
  styleUrls: ['./last-media-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastMediaCardComponent implements OnInit {
  MediaType = MediaType;

  @Input()
  media: Media;

  @Input()
  user: User;

  @Input()
  mediaExtraData: MediaExtraData;

  @Output()
  needExtraData = new EventEmitter<void>();

  @Output()
  likeMedia = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}
}
