import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {defaultMedia, Media, MediaType, PlaylistItem} from '@app/media/models';
import {MediaUpdateComponent} from '@app/media/components/media-update/media-update.component';
import {MediaRemoveComponent} from '@app/media/components/media-remove/media-remove.component';
import {PlaylistItemsComponent} from '../../../media/components/playlist-items/playlist-items.component';
import {changeItemPosition, removePlaylistItem} from '../../../shared/utils/playlist-functions';
import {MediaService} from '../../../media/services';
import {PlaylistMediaRemoveComponent} from '../../../media/components/playlist-media-remove/playlist-media-remove.component';

@Component({
  selector: 'app-medias-list',
  templateUrl: './medias-list.component.html',
  styleUrls: ['./medias-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediasListComponent {
  MediaType = MediaType;
  editingMedia?: Partial<Media>;
  removingMedia?: Media;
  editingPlaylist?: Media;
  removingFromPlaylist?: Media;
  removingFromPlaylistItem?: PlaylistItem;

  @Input()
  display: 'short' | 'complete' = 'complete';

  @ViewChild(PlaylistItemsComponent)
  playlistModal: PlaylistItemsComponent;

  @ViewChild(MediaUpdateComponent)
  mediaUpdateModal: MediaUpdateComponent;

  @ViewChild(PlaylistMediaRemoveComponent)
  playlistMediaRemove: PlaylistMediaRemoveComponent;

  @ViewChild(MediaRemoveComponent)
  mediaRemoveModal: MediaRemoveComponent;

  @Input()
  medias: Media[];

  constructor(
    private media: MediaService,
    private change: ChangeDetectorRef
  ) {
  }

  edit(media: Media): void {
    if (this.mediaUpdateModal) {
      this.media.getMedia(media.id).subscribe(fullMedia => {
        this.editingMedia = fullMedia;
        this.mediaUpdateModal.open();
      });
    }
  }

  createPlaylist() {
    if (this.mediaUpdateModal) {
      this.editingMedia = {
        ...defaultMedia(),
        type: MediaType.PLAYLIST
      };

      this.mediaUpdateModal.open();
    }
  }

  editPlaylist(playlist: Media): void {
    if (this.playlistModal) {
      this.editingPlaylist = playlist;
      this.playlistModal.open();
    }
  }

  removeFromPlaylist(playlist: Media, item: PlaylistItem): void {
    if (this.playlistMediaRemove) {
      this.removingFromPlaylist = playlist;
      this.removingFromPlaylistItem = item;
      this.playlistMediaRemove.open();
    }
  }

  remove(media: Media): void {
    if (this.mediaRemoveModal) {
      this.removingMedia = media;
      this.mediaRemoveModal.open();
    }
  }

  removePlaylistItem(playlist: Media, item: PlaylistItem) {
    this.savePlaylist(
      removePlaylistItem(playlist, item)
    );
  }

  changeItemPosition(playlist: Media, item: PlaylistItem, position: number) {
    const newPlaylist = changeItemPosition(playlist, item, position);

    if (!newPlaylist) {
      return;
    }

    this.savePlaylist(newPlaylist);
  }

  savePlaylist(playlist: Media) {
    this.media.updateMedia(playlist).subscribe(
      updated => {
        this.updatePlaylist(updated);
      }
    );
  }

  removePlaylistMedia() {
    if (!this.removingFromPlaylist || !this.removingFromPlaylistItem) {
      return;
    }

    this.removePlaylistItem(this.removingFromPlaylist, this.removingFromPlaylistItem);
    this.playlistMediaRemove.close();
  }

  updatePlaylist(playlist: Media) {
    this.playlistModal.close();
    this.medias = this.medias.map(media => media.id === playlist.id ? playlist : media);
    this.change.markForCheck();
  }

  edited(media: Media): void {
    const index = this.medias.findIndex(md => md.id === media.id);

    if (index !== -1) {
      this.medias = this.medias.map((md, id) => id === index ? media : md);
    } else {
      this.medias = [
        media,
        ...this.medias,
      ];
    }
    this.change.markForCheck();
  }

  removed(media: Media): void {
    this.medias = this.medias.filter(md => md.id !== media.id);
    this.change.markForCheck();
  }

  trackById(index: number, media: Media) {
    return media.id;
  }
}
