import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-content',
  templateUrl: './my-content.component.html',
  styleUrls: ['./my-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
