import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

type unit = 'second' | 'minute' | 'hour' | 'day';

const thresholdMap = { second: 0, minute: 60, hour: 3600, day: 86400 };

const translationMap: Record<string, Record<unit, string>> = {
  en: { second: 'seconds', minute: 'minutes', hour: 'hours', day: 'days' },
  fr: { second: 'secondes', minute: 'minutes', hour: 'heures', day: 'jours' }
};

const precisionMap: Record<unit, number> = {
  second: 0,
  minute: 0,
  hour: 1,
  day: 2
};

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  private readonly units: unit[] = ['second', 'minute', 'hour', 'day'];

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(
    seconds: number = 0,
    // tslint:disable-next-line:no-shadowed-variable
    unit: unit | 'auto' = 'auto',
    precision: number | string = 'auto'
  ): any {
    seconds = parseFloat(String(seconds));

    if (isNaN(seconds) || !isFinite(seconds)) {
      return '??';
    }

    if (unit === 'auto') {
      unit = this.getAutoUnit(seconds);
    }

    seconds /= Math.max(thresholdMap[unit], 1);

    const language = this.locale.substr(0, 2);
    let displayUnit: string;
    if (translationMap.hasOwnProperty(language)) {
      displayUnit = translationMap[language][unit];
    } else {
      displayUnit = translationMap.en[unit];
    }

    if (typeof precision === 'number') {
      return `${seconds.toFixed(+precision)} ${displayUnit}`;
    }

    return `${seconds.toFixed(precisionMap[unit])} ${displayUnit}`;
  }

  getAutoUnit(value: number = 0) {
    let unitIndex = 0;

    while (
      value >= thresholdMap[this.units[unitIndex + 1]] &&
      unitIndex < this.units.length
    ) {
      unitIndex++;
    }

    return this.units[unitIndex];
  }
}
