import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services';
import { forkJoin, Observable } from 'rxjs';
import { ApiCollection } from '@app/shared/models/api-collection';
import { Collection, collectionFromApi } from '@app/shared/models/collection';
import { catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Tag } from '@app/tag/models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  public constructor(private httpService: HttpService) {}

  public loadTags(name: string): Observable<Collection<Tag>> {
    let params = new HttpParams();
    params = params.append('name', name);

    return this.httpService
      .get<ApiCollection<Tag>>('/tags', params)
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public createTag(tag: Tag): Observable<Tag> {
    return this.httpService.post<Tag>('/tags', tag);
  }

  public createTags(tags: Tag[]): Observable<Tag[]> {
    return forkJoin(tags.map(tag => this.createTag(tag)));
  }
}
