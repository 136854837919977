import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';

import { PageActions } from '@app/page/state/actions';

import { Page } from '@app/page/models/page';
import { PageService } from '@app/page/services';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class PageEffects {
  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.loadPage),
      map(action => action.page),
      exhaustMap(pageCustom => {
        return this.pageService.loadPage(pageCustom).pipe(
          map((pages: Page[]) =>
            pages.length > 0
              ? PageActions.loadPageSuccess({ page: pages[0] })
              : PageActions.loadPageFailure({ error: -1 })
          ),
          catchError(error => {
            return of(PageActions.loadPageFailure({ error }));
          })
        );
      })
    )
  );

  notFoundPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PageActions.loadPageFailure),
        filter(
          ({ error }) =>
            error === -1 ||
            (error instanceof HttpErrorResponse && error.status === 404)
        ),
        tap(({ error }) => this.router.navigate(['/fallback', 'not-found']))
      ),
    { dispatch: false }
  );

  public constructor(
    private actions$: Actions,
    private pageService: PageService,
    private router: Router
  ) {}
}
