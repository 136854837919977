import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { NgSelectConfig } from '@ng-select/ng-select';

import { Affiliations, Professions } from '@app/authentication/models/auth';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import { PageFacade } from '@app/page/state/page.facade';
import { Page } from '@app/page/models/page';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'app-first-connection-page',
  templateUrl: './first-connection-page.component.html',
  styleUrls: ['./first-connection-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstConnectionPageComponent implements OnInit {
  user$: Observable<User | null>;
  page$: Observable<Page | null>;

  constructor(
    private authFacade: AuthFacade,
    private pageFacade: PageFacade,
    private config: NgSelectConfig
  ) {
    this.config.loadingText = 'Chargement...';
  }

  ngOnInit() {
    this.user$ = this.authFacade.loggedUser$;
    this.pageFacade.loadPage('pre-home');
    this.page$ = this.pageFacade.page$;
  }
}
