import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

/**
 * @deprecated
 * @todo should be removed and replaced by the new modal components
 */
@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements OnInit {
  @Input() title = '';

  constructor() {}

  ngOnInit() {}
}
