import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-standalone-bar',
  templateUrl: './standalone-bar.component.html',
  styleUrls: ['./standalone-bar.component.scss']
})
export class StandaloneBarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
