import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaFacade} from '@app/media/state/media.facade';
import {AuthFacade} from '@app/authentication/state/auth.facade';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {LayoutCoreActions} from '@app/core/state/actions';
import {GtagService} from '@app/core/services/gtag.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {first, map, withLatestFrom} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {ApiVideoEvent} from '../../models';
import {MediaRoutePipe} from '../../pipes/media-route.pipe';
import {MediaRouteParamsPipe} from '../../pipes/media-route-params.pipe';

@UntilDestroy()
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaComponent implements OnInit, OnDestroy {
  autoNext = false;

  user$ = this.authFacade.loggedUser$;
  media$ = this.mediaFacade.media$;
  mediaExtraData$ = this.mediaFacade.mediaExtraData$;
  suggestedMedias$ = this.mediaFacade.suggestedMedias$;
  contextPlaylistId$ = this.route.queryParams.pipe(
    map(params => params.playlist ? parseInt(params.playlist, 10) : undefined)
  );

  associatedPlaylists$ = combineLatest([
    this.mediaFacade.associatedPlaylists$,
    this.contextPlaylistId$
  ]).pipe(
    map(([playlists, playlistId]) =>
      playlistId !== undefined ? playlists.filter(pl => pl.id === playlistId) : playlists)
  );

  uniquePlaylist$ = this.associatedPlaylists$.pipe(
    map(playlists => playlists.length === 1 ? playlists[0] : undefined)
  );

  uniquePlaylistNextMedia$ = combineLatest([this.media$, this.uniquePlaylist$])
    .pipe(
      map(([media, playlist]) => {
        if (!media || !playlist) {
          return undefined;
        }

        const index = playlist.playlistItems.findIndex(md => md.mediaItemId === media.id);

        if (index === -1) {
          return undefined;
        }

        const nextMedia = playlist.playlistItems[index + 1];
        return nextMedia ?? undefined;
      })
    );

  constructor(
    private authFacade: AuthFacade,
    private mediaFacade: MediaFacade,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private gtag: GtagService,
    private change: ChangeDetectorRef,
    private mediaRoutePipe: MediaRoutePipe,
    private mediaRouteParamsPipe: MediaRouteParamsPipe
  ) {
    this.store.dispatch(LayoutCoreActions.displaySidebar({display: false}));
  }

  ngOnInit() {
    this.route.params.pipe(
      withLatestFrom(this.route.queryParams)
    ).subscribe(([params, queryParams]) => {
      const highlightId = queryParams.highlight ? parseInt(queryParams.highlight, 10) : undefined;

      if (params.id && params.id.length > 0) {
        this.loadMedia(parseInt(params.id, 10), true, true, highlightId);
      }
    });

    this.mediaFacade.loadSuggestedMedias();

    this.mediaFacade.media$.pipe(untilDestroyed(this)).subscribe(media => {
      if (!media) {
        return;
      }

      this.mediaFacade.loadAssociatedPlaylists(media.id);

      this.gtag.event({
        action: 'media',
        category: media.type,
        value: media.id.toString()
      });
    });
  }

  likeMedia(mediaId: number, liked = true) {
    if (liked) {
      this.mediaFacade.likeMedia(mediaId);
    } else {
      this.mediaFacade.unlikeMedia(mediaId);
    }
  }

  loadMedia(mediaId: number, extradata = false, reset = false, highlightId?: number) {
    this.mediaFacade.loadMedia(mediaId, extradata, reset, highlightId);
  }

  videoEvent(type: ApiVideoEvent) {
    switch (type) {
      case ApiVideoEvent.ENDED:
        this.uniquePlaylistNextMedia$.pipe(first()).subscribe(media => {
          if (!media) {
            return;
          }
          this.autoNext = true;
          this.change.detectChanges();
        });
        break;
    }
  }

  closeAutoNext() {
    this.autoNext = false;
    this.change.detectChanges();
  }

  nextVideoOnPlaylist() {
    this.closeAutoNext();

    combineLatest([this.uniquePlaylistNextMedia$, this.uniquePlaylist$])
      .pipe(
        first()
      )
      .subscribe(([media, playlist]) => {
        if (!media || !playlist) {
          return;
        }

        this.router.navigate(
          this.mediaRoutePipe.transform(media.mediaItemId),
          {
            queryParams: this.mediaRouteParamsPipe.transform(media.mediaItemId, playlist)
          }
        );
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(LayoutCoreActions.displaySidebar({display: true}));
  }
}
