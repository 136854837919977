<app-modal [closableByDefault]="false">
  <app-modal-header [centered]="true">
    <app-modal-title>
      <h1>Autorisation de droit à l'image</h1>
      <div>Par l'utilisation de la platforme de partage</div>
      <div>de vidéos pédagogiques mycampusTUBE, le collaborateur :</div>
    </app-modal-title>
  </app-modal-header>

  <div *ngIf="content" class="consent-content">
    <div [innerHTML]="content" class="content"></div>
  </div>

  <div class="buttons">
    <div>
      <label>
        <input type="checkbox" [(ngModel)]="consent"/>
        J'accepte les conditions ci-dessus
      </label>
    </div>

    <app-button-outlined
      text="Envoyer"
      (click)="submit()"
    ></app-button-outlined>
  </div>
</app-modal>
