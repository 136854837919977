<ng-container
  *ngIf="{
    professions: professions$ | async,
    affiliations: affiliations$ | async
  } as data"
>
  <app-first-connection-form
    *ngIf="data.professions"
    [professions]="data.professions"
    [affiliations]="data.affiliations"
    [fromProfile]="fromProfile"
    [user]="user$ | async"
    (save)="onSave($event)"
    (edit)="loadAffiliations()"
  ></app-first-connection-form>
</ng-container>
