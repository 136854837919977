import { createAction, props } from '@ngrx/store';
import { Collection } from '@app/shared/models/collection';
import { ReportReason } from '@app/report/models/report-reason.model';
import { Report } from '@app/report/models/report.model';

const TAG = '[Report/Api]';

export const loadReportReasonsSuccess = createAction(
  `${TAG} Load report reasons SUCCESS`,
  props<{ reasons: Collection<ReportReason> }>()
);

export const loadReportReasonsFailure = createAction(
  `${TAG} Load report reasons FAILURE`,
  props<{ error: any }>()
);

export const addReportSuccess = createAction(
  `${TAG} Add report SUCCESS`,
  props<{ report: Report }>()
);

export const addReportFailure = createAction(
  `${TAG} Add report FAILURE`,
  props<{ error: any }>()
);
