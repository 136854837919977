import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FeaturedMedia} from '../../models/highlighted-media.model';
import {MediaExtraData, MediaType} from '@app/media/models';

@Component({
  selector: 'app-featured-media-card',
  templateUrl: './featured-media-card.component.html',
  styleUrls: ['./featured-media-card.component.scss']
})
export class FeaturedMediaCardComponent {
  MediaType = MediaType;

  @Input()
  featuredMedia: FeaturedMedia;

  @Input()
  featuredMediaExtraData: MediaExtraData;

  @Output()
  needExtraData = new EventEmitter<void>();
}
