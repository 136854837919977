<ng-container
  *ngIf="{ category: category$ | async, color: color$ | async } as categoryData"
>
  <div
    *ngIf="!!categoryData.category && categoryData.color !== null"
    infiniteScroll
    (scrolled)="loadMore()"
  >
    <div class="header">
      <div class="header-background {{ categoryData.color }}-bg"></div>
      <h1>
        <app-category-icon
          class="{{ categoryData.color }}-fill {{
            categoryData.color
          }}-stroke white-bg"
        ></app-category-icon>
        {{ categoryData.category.name }}
      </h1>

      <ng-container
        *ngIf="{
          media: featuredMedia$ | async,
          extra: lastMediaExtradata$ | async
        } as mediaData"
      >
        <app-last-media-card
          *ngIf="mediaData.media !== null"
          [media]="mediaData.media"
          [mediaExtraData]="mediaData.extra"
          (needExtraData)="loadFeaturedExtradata()"
          class="{{ categoryData.color }}-color white-bg black-text-color"
          [@Appear]
        ></app-last-media-card>
      </ng-container>
    </div>

    <div class="media-list">
      <h5 class="{{ categoryData.color }}-color">
        <b>Médias</b>
        {{ mediasCount$ | async }}
      </h5>

      <app-media-list
        *ngIf="medias$ | async as medias"
        [medias]="medias"
      ></app-media-list>
    </div>
  </div>
</ng-container>
