import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services';
import { Observable } from 'rxjs';
import { ApiCollection } from '@app/shared/models/api-collection';
import { Collection, collectionFromApi } from '@app/shared/models/collection';
import { map } from 'rxjs/operators';
import { Media } from '@app/media/models';
import { ReportReason } from '@app/report/models/report-reason.model';
import { Report } from '@app/report/models/report.model';
import { User } from '../../user/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public constructor(private httpService: HttpService) {}

  public loadReportReasons(): Observable<Collection<ReportReason>> {
    return this.httpService
      .get<ApiCollection<ReportReason>>('/media_report_reasons')
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public addReport(report: Report): Observable<Report> {
    return this.httpService.post<Report>(`/media_user_reports`, {
      reason: report.reason.id,
      media: (report.media as Media).id,
      reporter: (report.reporter as User).id
    });
  }
}
