<app-auth-layout title="{{ 'TITLE_RESET_PASSWORD' | translate }}">
  <ng-container *ngIf="status$ | async as status">
    <div
      *ngIf="status === 'errorTokenNotFound'"
      class="alert alert-danger"
      innerHTML="{{ 'ERROR_TOKEN_NOT_FOUND' | translate }}"
    ></div>
    <div
      class="alert alert-success"
      *ngIf="status === 'successPaswordChanged'"
      innerHTML="{{ 'SUCCESS_RESET_PASSWORD' | translate }}"
    ></div>
    <div *ngIf="status === 'init'" class="alert alert-info" translate>
      PROGRESS_VERIF
    </div>
    <app-reset-password-form
      *ngIf="status === 'successConfirmReset'"
      (resetPassword)="onResetPassword($event)"
    ></app-reset-password-form>
    <a
      [routerLink]="['/login']"
      *ngIf="status !== 'init'"
      class="btn btn-link btn-block"
      translate
    >
      CONNECT
    </a>
  </ng-container>
</app-auth-layout>
