import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {defaultMediaSearchModel, Media, MediaType} from '../../models';
import {MediaService} from '../../services';
import {HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {filter, first, switchMap} from 'rxjs/operators';
import {Collection, defaultCollection} from '../../../shared/models/collection';
import {AuthFacade} from '../../../authentication/state/auth.facade';
import {User} from '../../../user/models/user.model';

@Component({
  selector: 'app-playlist-medias-form',
  templateUrl: './playlist-medias-form.component.html',
  styleUrls: ['./playlist-medias-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaylistMediasFormComponent implements OnInit {
  DefaultSearchMedia = 'https://';
  MediaType = MediaType;
  mediasType$ = new BehaviorSubject<MediaType>(MediaType.VIDEO);
  view: 'my-content' | 'other-content' = 'my-content';
  myMedias: Collection<Media> = defaultCollection();
  allOtherMedias: Media[] = [];
  otherMedias: Media[] = [];
  searchedMedias: Media[] = [];
  searchMedia = this.DefaultSearchMedia;
  searching = false;
  pPlaylist?: Media;

  @Output()
  playlistChange = new EventEmitter<Media>();

  constructor(
    private auth: AuthFacade,
    private mediaService: MediaService,
    private change: ChangeDetectorRef
  ) {
  }

  get playlist() {
    return this.pPlaylist;
  }

  @Input()
  set playlist(playlist: Media | null | undefined) {
    this.pPlaylist = playlist ?? undefined;
    this.searchedMedias = [];
    this.allOtherMedias = [];
    this.otherMedias = [];
    this.refreshOtherMedias();
  }

  get mediaType() {
    return this.mediasType$.getValue();
  }

  set mediaType(type: MediaType) {
    this.mediasType$.next(type);
  }

  ngOnInit(): void {
    this.mediasType$
      .pipe(
        switchMap(type =>
          this.mediaService.getMyMedias(
            {
              ...defaultMediaSearchModel(),
              mediaTypes: [type]
            },
            new HttpParams().set('limit', '999')
          )
        )
      )
      .subscribe(medias => {
        this.myMedias = medias;
        this.refreshOtherMedias();
        this.change.markForCheck();
      });
  }

  refreshOtherMedias() {
    const items = this.playlist?.playlistItems;

    if (!items || !items.length) {
      return;
    }

    this.auth.loggedUser$
      .pipe(
        filter((user): user is User => !!user),
        first()
      )
      .subscribe(user => {
        this.otherMedias = items.map(item => item.mediaItem).filter(
          media => (media.creator as User).id !== user.id
        );
        this.refreshAllOtherMedias();
        this.change.markForCheck();
      });
  }

  refreshAllOtherMedias() {
    this.allOtherMedias = [
      ...this.searchedMedias.filter(media => this.otherMedias.findIndex(md => md.id === media.id) === -1),
      ...this.otherMedias
    ];
    this.change.markForCheck();
  }

  getOtherMediaId(): number | undefined {
    const fragments = this.searchMedia.split('/');
    const id = fragments[fragments.length - 1];

    if (!isNaN(parseFloat(id)) && !isNaN(+id)) {
      const nb = parseInt(id, 10);
      return !isNaN(nb) ? nb : undefined;
    }

    return undefined;
  }

  canAddOtherMedia() {
    return this.getOtherMediaId() !== undefined;
  }

  addOtherMedia() {
    const id = this.getOtherMediaId();

    if (id === undefined) {
      return;
    }

    this.searching = true;
    this.mediaService.getMedia(id).subscribe(
      media => {
        this.searchedMedias = [
          ...this.searchedMedias.filter(md => md.id !== media.id),
          media
        ];
        this.searchMedia = this.DefaultSearchMedia;
        this.refreshAllOtherMedias();
      },
      () => {
      },
      () => {
        this.searching = false;
        this.change.markForCheck();
      }
    );
  }
}
