import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {NotificationFacade} from '../../state/notification-facade.service';
import {Notification} from '../../models';
import {map, shareReplay, tap} from 'rxjs/operators';

@Component({
  selector: 'app-notifications-widget',
  templateUrl: './notifications-widget.component.html',
  styleUrls: ['./notifications-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsWidgetComponent implements OnInit {
  notifications$ = this.facade.notifications$;
  count$ = this.notifications$.pipe(
    map(notifications => notifications.filter(notification => notification.viewedAt === null).length)
  );

  visible = false;

  constructor(
    private facade: NotificationFacade
  ) {
  }

  @HostListener('document:click')
  click() {
    this.visible = false;
  }

  ngOnInit(): void {
    this.facade.loadNotifications();
  }

  update(notification: Notification) {
    this.facade.update(notification);
  }
}
