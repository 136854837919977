import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromNavBar from '@app/links-bar/state/reducers';
import { ExternalLinkActions } from '@app/links-bar/state/actions';

@Injectable()
export class ExternalLinkFacade {
  externalLinks$ = this.store.pipe(select(fromNavBar.getExternalLinks));

  constructor(private store: Store<fromNavBar.State>) {}

  loadExternalLinks() {
    // this.store.dispatch(ExternalLinkActions.loadExternalLinks());
  }
}
