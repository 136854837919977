import { props, createAction } from '@ngrx/store';

export const sendMessage = createAction(
  '[Contact] SendMessage',
  props<{ message: string }>()
);
export const sendMessageSuccess = createAction('[Contact] SendMessage succes');
export const sendMessageFailure = createAction(
  '[Contact] SendMessage error',
  props<{ error: any }>()
);
export const sendMessageReset = createAction('[Contact] SendMessage reset');
