import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyContentComponent } from './containers/my-content/my-content.component';
import { UserContentRoutingModule } from '@app/user-content/user-content-routing.module';
import { IconsModule } from '@app/shared/icons';
import { MyMediasComponent } from './containers/my-medias/my-medias.component';
import { MyPlaylistsComponent } from './containers/my-playlists/my-playlists.component';
import { MediasListComponent } from './components/medias-list/medias-list.component';
import { ButtonModule } from '@app/shared/button';
import { MediaModule } from '@app/media/media.module';
import { NestedEllipsisModule } from 'ngx-nested-ellipsis';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {SharedModule} from '../shared';

@NgModule({
  declarations: [
    MyContentComponent,
    MyMediasComponent,
    MyPlaylistsComponent,
    MediasListComponent
  ],
    imports: [
        CommonModule,
        UserContentRoutingModule,
        IconsModule,
        ButtonModule,
        MediaModule,
        NestedEllipsisModule,
        InfiniteScrollModule,
        SharedModule
    ]
})
export class UserContentModule {}
