<div class="content-title">
  <app-icon src="assets/images/cog.svg" class="white-fill"></app-icon>
  <span>Gérer mes contenus</span>
</div>

<div class="content-body">
  <div class="content-menu">
    <a routerLink="medias" routerLinkActive="active">Médias</a>
    <a routerLink="playlists" routerLinkActive="active">Parcours</a>
  </div>
  <div class="content-child">
    <router-outlet></router-outlet>
  </div>
</div>
