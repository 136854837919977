import { createAction, props } from '@ngrx/store';
import {Notification} from '../../models';

const TAG = '[Notification/View]';

export const loadNotifications = createAction(`${TAG} Load notifications`);
export const updateNotification = createAction(
  `${TAG} update`,
  props<{ notification: Notification }>()
);
