import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Category, getColorForCategory} from '@app/category/models';
import {UserFollow} from '../../../user/models/user-follow.model';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @HostBinding('class.visible')
  @Input()
  visible = true;

  @Input()
  categories: Category[] = [];

  @Input()
  followedUsers: UserFollow[] = [];

  constructor() {
  }

  getCategoryColor(category: Category) {
    return getColorForCategory(category.id);
  }

  ngOnInit() {
  }
}
