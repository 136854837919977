import {Component, OnInit, ViewChild} from '@angular/core';
import {PageService} from '../../../page/services';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ModalComponent} from '../../../shared/modal/modal/modal.component';
import {AuthFacade} from '../../state/auth.facade';
import {first} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.scss']
})
export class ConsentModalComponent implements OnInit {
  content: SafeHtml;
  consent: boolean;

  constructor(
    private pageService: PageService,
    private sanitizer: DomSanitizer,
    private auth: AuthFacade,
    private router: Router,
  ) {
  }

  @ViewChild(ModalComponent)
  set modal(modal: ModalComponent) {
    modal.open();
  }

  ngOnInit(): void {
    this.pageService.loadPage('consent').subscribe(pages => {
      const page = pages[0];

      if (!!page) {
        this.content = this.sanitizer.bypassSecurityTrustHtml(page.content);
      }
    });
  }

  submit() {
    if (this.consent) {
      this.auth.loggedUser$
        .pipe(first())
        .subscribe(user => user ? this.auth.consent(user) : this.auth.logout());
    } else {
      this.router.navigate(['/login']);
      this.auth.logout();
    }
  }
}
