import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {User} from '../../../user/models/user.model';
import {LikedComment} from '@app/comment/models/comment-like.model';
import {Media} from '../../../media/models';
import {Comment} from '../../models/comment.model';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent implements AfterViewInit {
  commentsContainerHeight = 0;
  displayChildren = false;
  answer = false;

  @ViewChild('comments')
  commentsContainer: ElementRef;

  @Input()
  user: User;

  @Input()
  media: Media;

  @Input()
  scrollTo: number | undefined;

  @Input()
  comment: Comment;

  @Input()
  canAnswer = true;

  @Output()
  addComment = new EventEmitter<Comment>();

  @Output()
  likeComment = new EventEmitter<LikedComment>();

  constructor(public elementRef: ElementRef, private change: ChangeDetectorRef) {
  }

  get hasScrollToChildren() {
    return this.scrollTo !== undefined ? this.comment.replies?.find(reply => reply.id === this.scrollTo) !== undefined : false;
  }

  updateComments() {
    setTimeout(() => {
      this.commentsContainerHeight = this.commentsContainer.nativeElement.scrollHeight;
      this.change.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.updateComments();
  }

  toggleDisplayChildren() {
    this.displayChildren = !this.displayChildren;
    this.updateComments();
  }
}
