import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromPage from '@app/page/state/reducers/page.reducer';
import * as fromContact from '@app/page/state/reducers/contact.reducer';

export const customPageKey = 'customPage';

export interface PageState {
  page: fromPage.State;
  contact: fromContact.State;
}

export interface State extends fromRoot.State {
  customPage: PageState;
}

export function reducers(state: PageState | undefined, action: Action) {
  return combineReducers({
    page: fromPage.reducer,
    contact: fromContact.reducer
  })(state, action);
}

export const selectPageState = createFeatureSelector<State, PageState>(
  customPageKey
);

export const getPageState = createSelector(
  selectPageState,
  customPage => customPage.page
);

export const getPage = createSelector(
  getPageState,
  fromPage.getPage
);

export const getContactState = createSelector(
  selectPageState,
  customPage => customPage.contact
);

export const getMessageSentSuccess = createSelector(
  getContactState,
  fromContact.getMessageSentSuccess
);

export const getMessageSentError = createSelector(
  getContactState,
  fromContact.getMessageSentError
);

export const getMessageSentLoading = createSelector(
  getContactState,
  fromContact.getMessageSentError
);
