import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playlist-media-form',
  templateUrl: './playlist-media-form.component.html',
  styleUrls: ['./playlist-media-form.component.scss']
})
export class PlaylistMediaFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
