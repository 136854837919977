import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import {
  AuthActions,
  FirstConnectionActions
} from '@app/authentication/state/actions';

import { Affiliations, Professions } from '@app/authentication/models/auth';
import { FirstConnectionService } from '@app/authentication/services/first-connection.service';
import { CRUD_MODAL_CONFIG } from '@app/shared/models/modal-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProfileConfirmationComponent } from '@app/authentication/components/profile-confirmation/profile-confirmation.component';
import { AuthFacade } from '@app/authentication/state/auth.facade';

@Injectable()
export class FirstConnectionEffects {
  loadProfessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirstConnectionActions.loadProfessions),
      switchMap(() => {
        return this.firstConnectionService.loadProfessions().pipe(
          map((professions: Professions) =>
            FirstConnectionActions.loadProfessionsSuccess({ professions })
          ),
          catchError(error => {
            return of(
              FirstConnectionActions.loadProfessionsFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );
  loadAffiliations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirstConnectionActions.loadAffiliations),
      switchMap(() => {
        return this.firstConnectionService.loadAffiliations().pipe(
          map((affiliations: Affiliations) =>
            FirstConnectionActions.loadAffiliationsSuccess({ affiliations })
          ),
          catchError(error => {
            return of(
              FirstConnectionActions.loadAffiliationsFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirstConnectionActions.login),
      exhaustMap(() => {
        return this.firstConnectionService.login().pipe(
          map(() => FirstConnectionActions.loginSuccess()),
          catchError(error => {
            return of(FirstConnectionActions.loginFailure({ error }));
          })
        );
      })
    )
  );
  firstUpdateUserInfos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirstConnectionActions.firstUpdateUserInfos),
      exhaustMap(action => {
        return this.firstConnectionService
          .firstUpdateUserInfos(action.user)
          .pipe(
            map(() => FirstConnectionActions.firstUpdateUserInfosSuccess()),
            catchError(error => {
              return of(
                FirstConnectionActions.firstUpdateUserInfosFailure({
                  error: error.message
                })
              );
            })
          );
      })
    )
  );
  showPopupConfirmation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FirstConnectionActions.fromProfileUpdateUserInfosSuccess),
        tap(() => {
          setTimeout(() => {
            this.modalService.show(
              ProfileConfirmationComponent,
              CRUD_MODAL_CONFIG
            );
          });
        })
      ),
    { dispatch: false }
  );
  fromProfileUpdateUserInfos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirstConnectionActions.formProfileUpdateUserInfos),
      exhaustMap(action => {
        return this.firstConnectionService
          .firstUpdateUserInfos(action.user, true)
          .pipe(
            tap(() => {
              this.authFacade.reloadUser();
            }),
            map(() =>
              FirstConnectionActions.fromProfileUpdateUserInfosSuccess()
            ),
            catchError(error => {
              return of(
                FirstConnectionActions.fromProfileUpdateUserInfosFailure({
                  error: error.message
                })
              );
            })
          );
      })
    )
  );
  firstUpdateUserInfosSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FirstConnectionActions.firstUpdateUserInfosSuccess),
        tap(() => {
          this.router.navigate(['/medias'], { state: { test: true } });
        })
      ),
    { dispatch: false }
  );
  reloadUserAfterUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirstConnectionActions.firstUpdateUserInfosSuccess),
      map(() => AuthActions.reloadUser())
    )
  );
  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FirstConnectionActions.loginSuccess),
        tap(() => {
          this.router.navigate(['/medias']);
        })
      ),
    { dispatch: false }
  );

  public constructor(
    private actions$: Actions,
    private firstConnectionService: FirstConnectionService,
    private router: Router,
    private modalService: BsModalService,
    private authFacade: AuthFacade
  ) {}
}
