import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';
import { Media } from '@app/media/models';
import { Slider } from '@app/media/models/slider';
import {FeaturedMedia, HighlightedMedia} from '../../models/highlighted-media.model';

@Component({
  selector: 'app-media-top-tile',
  templateUrl: './media-top-tile.component.html',
  styleUrls: ['./media-top-tile.component.scss']
})
export class MediaTopTileComponent implements OnInit, Slider {
  @Input()
  media: Media|HighlightedMedia;

  @Input()
  position: number;

  @Input()
  @HostBinding('class.selected')
  selected = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {}

  get finalMedia() {
    return (this.media as HighlightedMedia).media ?? this.media;
  }

  get title() {
    return (this.media as HighlightedMedia).media ? (this.media as HighlightedMedia).name : (this.media as Media).title;
  }

  get positionRight() {
    return (
      this.elementRef.nativeElement.offsetLeft +
      this.elementRef.nativeElement.clientWidth
    );
  }

  get positionLeft() {
    return this.elementRef.nativeElement.offsetLeft;
  }
}
