import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {ApiVideoEvent, Media, MediaExtraData, MediaType} from '@app/media/models';
import {HighlightedMedia} from '../../models/highlighted-media.model';
import {PlayerSdk} from '@api.video/player-sdk';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {ApiVideoService} from '../../services/api-video.service';
import {MediaFacade} from '@app/media/state/media.facade';
import {environment} from '@env/environment';

@Component({
  selector: 'app-media-view',
  templateUrl: './media-view.component.html',
  styleUrls: ['./media-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaViewComponent implements OnInit, OnDestroy {
  MediaType = MediaType;
  cover = false;
  playerId = '';
  player: PlayerSdk | undefined;
  playerEventsSubscription: Subscription;

  videoUrl?: string | undefined;
  videoUrl2?: string | undefined;
  iframeSrc?: SafeResourceUrl;
  mediaType?: MediaType;

  pMedia?: Media;
  pMediaExtraData?: MediaExtraData;

  highlightedMedia?: HighlightedMedia;

  lastVideoPosition = 0;

  @Input()
  autoplay = false;

  @Output()
  needExtraData = new EventEmitter<void>();

  @Output()
  videoEvent = new EventEmitter<ApiVideoEvent>();

  constructor(
    private ngZone: NgZone,
    private domSanitizer: DomSanitizer,
    private apiVideoService: ApiVideoService,
    private mediaFacade: MediaFacade,
    private change: ChangeDetectorRef
  ) {
  }

  @Input()
  set media(media: Media | HighlightedMedia | undefined) {
    this.pMedia = (media as HighlightedMedia)?.media ?? media;
    this.highlightedMedia = (media as HighlightedMedia)?.media ? media as HighlightedMedia : undefined;

    if (media === undefined && this.mediaType !== undefined) {
      this.mediaType = undefined;
    }

    if (media !== undefined && this.mediaType !== this.pMedia.type) {
      this.mediaType = this.pMedia.type;
    }

    this.loadVideo();
  }

  get extraData() {
    return this.pMediaExtraData;
  }

  @Input()
  set extraData(extraData: MediaExtraData | undefined) {
    this.pMediaExtraData = extraData;

    const videoUrl = this.extraData
      ? this.extraData.extraData.iframePlayerUrl
      : '';
    const videoUrl2 = this.extraData
      ? this.extraData.extraData.iframePlayerUrl2
      : '';

    if (videoUrl2 !== '') {
      this.videoUrl2 = videoUrl2;
    }

    if (videoUrl2 === undefined) {
      // Video changed
      if (this.videoUrl !== videoUrl) {
        this.videoUrl = videoUrl;
        this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${videoUrl}#autoplay`
        );
      }
    }

    if (videoUrl2 !== undefined) {
      // Video 2 changed
      if (this.videoUrl2 !== videoUrl2) {
        this.videoUrl2 = videoUrl2;
        this.videoUrl = undefined;
      }
    }

    this.loadVideo();
  }

  get imageUrl() {
    return this.highlightedMedia && this.highlightedMedia.imageUrl
      ? this.highlightedMedia.imageUrl
      : this.pMedia
        ? this.pMedia.imageUrl
        : '';
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.destroy();
    }

    if (this.playerEventsSubscription) {
      this.playerEventsSubscription.unsubscribe();
    }
  }

  loadVideo() {
    if (
      !this.pMedia ||
      !this.extraData ||
      this.extraData['@id'] !== this.pMedia['@id'] ||
      this.pMedia.type !== MediaType.VIDEO ||
      this.pMedia.apiVideoId === null
    ) {
      return;
    }

    if (this.player) {
      this.player = undefined;
    }

    if (this.playerEventsSubscription) {
      this.playerEventsSubscription.unsubscribe();
    }

    const apiVideoId = this.pMedia.apiVideoId;

    this.playerId = `video-player-${apiVideoId}`;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const internalPlayerId = PlayerSdk.nextSdkPlayerId;
        this.player = new PlayerSdk(
          `#${this.playerId}`,
          {
            id: apiVideoId,
            customDomain: environment.apiVideoCustomDomain,
          }
        );

        // save last position in video for current user
        this.player?.addEventListener('timeupdate', (data) => {
          this.saveVideoPosition(Math.round(data.currentTime));
        });
        this.player?.addEventListener('pause', () => {
          this.player?.getCurrentTime().then(currentTime => {
            this.saveVideoPosition(Math.round(currentTime), true);
          });
        });

        // ready
        this.player?.addEventListener('ready', () => {
          this.player?.unmute();
          this.player?.play();
        });

        // add last position in video for current user
        if (this.pMedia) {
          this.lastVideoPosition = this.pMedia.currentUserLastVideoPosition ?? 0;
          this.player?.setCurrentTime(this.lastVideoPosition);
        }

        this.playerEventsSubscription = this.apiVideoService
          .eventsForPlayer(internalPlayerId)
          .subscribe(type => {
            this.videoEvent.emit(type as ApiVideoEvent);
          });

        this.apiVideoService
          .preventAutoPlayMute(internalPlayerId, this.player);
          // .subscribe(() => {
            // // save last position in video for current user
            // this.player?.addEventListener('timeupdate', (data) => {
            //   this.saveVideoPosition(Math.round(data.currentTime));
            // });
            // this.player?.addEventListener('pause', () => {
            //   this.player?.getCurrentTime().then(currentTime => {
            //     this.saveVideoPosition(Math.round(currentTime), true);
            //   });
            // });
            //
            // // ready
            // this.player?.addEventListener('ready', () => {
            //   this.player?.unmute();
            //   this.player?.play();
            // });
          // });
      });
    });

    this.change.detectChanges();
  }

  getProgress(media: Media) {
    return media.currentUserHasCompletedVideo ? 100 : (media.currentUserLastVideoPosition / media.duration) * 100;
  }

  saveVideoPosition(videoPosition: number, force = false) {
    // save video position every 5 seconds or when video is paused
    if (force || this.lastVideoPosition + 5 <= videoPosition) {
      this.lastVideoPosition = videoPosition;
      if (this.pMedia && this.pMedia.id) {
        this.mediaFacade.saveMediaVideoPosition(this.pMedia.id, videoPosition);
      }
    }
  }

  getDuration(media: Media) {
    let hours: string | number = Math.floor(media.duration / 3600);
    let minutes: string | number = Math.floor((media.duration - (hours * 3600)) / 60);
    let seconds: string | number = media.duration - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    return hours + ':' + minutes + ':' + seconds;
  }
}
