<app-button-outlined
  class="plain"
  icon="assets/images/share.svg"
  text="Partager"
  (click)="modal.open()"
  [shrinkable]="true"
></app-button-outlined>

<app-modal>
  <app-modal-header [centered]="false">
    <app-modal-title class="modal-title">
      <app-icon src="assets/images/share.svg"></app-icon>
      <span>Partager</span>
    </app-modal-title>
  </app-modal-header>

  <app-media-share [media]="media"></app-media-share>

  <div class="d-flex flex-row">
    <a [href]="mailTo">Envoyer par mail</a>
  </div>
</app-modal>
