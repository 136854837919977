<button [class.shrinkable]="shrinkable" [disabled]="disabled">
  <app-icon
    *ngIf="!!icon && iconBefore"
    [src]="icon"
    class="icon-left"
  ></app-icon>
  <span class="text">
    {{ text }}
    <ng-content></ng-content>
  </span>
  <app-icon
    *ngIf="!!icon && !iconBefore"
    [src]="icon"
    class="icon-right"
  ></app-icon>
</button>
