<div class="creator theme-{{ theme }}">
  <div class="creator-picture">
    <app-profile-picture [user]="creator"></app-profile-picture>
  </div>
  <div class="creator-infos">
    <a class="creator-name" [routerLink]="['/channel', creator.id]">
      Par {{ creator | user }}
    </a>
    <div class="media-date">{{ media.createdAt | date }}</div>
  </div>
</div>
