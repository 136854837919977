import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) {}

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json, application/ld+json'
    };

    return new HttpHeaders(headersConfig);
  }

  getApiHost() {
    return environment.apiHost;
  }

  getApiBasePath() {
    return environment.apiBasePath;
  }

  getApiPublicBasePath() {
    return environment.apiPublicBasePath;
  }

  getApiSuffix() {
    return environment.apiSuffix;
  }

  getApiPath() {
    return `${this.getApiHost()}${this.getApiBasePath()}`;
  }

  getApiPublicPath() {
    return `${this.getApiHost()}${this.getApiPublicBasePath()}`;
  }

  getApiUrl(url: string) {
    return `${this.getApiPath()}${url}${this.getApiSuffix()}`;
  }

  get<T>(
    url: string,
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        } = new HttpParams()
  ): Observable<T> {
    return this.http.get<T>(this.getApiUrl(url), {
      headers: this.headers,
      params
    });
  }

  getPublic<T>(
    url: string,
    params: HttpParams = new HttpParams()
  ): Observable<T> {
    return this.http.get<T>(this.getApiUrl(url), {
      headers: this.headers,
      params
    });
  }

  post<T>(
    url: string,
    body: any | null,
    headers?: HttpHeaders | null
  ): Observable<T> {
    const options: { headers?: HttpHeaders } = {};

    if (headers !== null) {
      options.headers = headers ? headers : this.headers;
    }

    return this.http.post<T>(this.getApiUrl(url), body, options);
  }

  postPublic<T>(url: string, body: any | null): Observable<T> {
    return this.http.post<T>(this.getApiUrl(url), body, {
      headers: this.headers
    });
  }

  postHost<T>(url: string, body: any | null): Observable<T> {
    return this.http.post<T>(`${this.getApiHost()}${url}`, body, {
      headers: this.headers
    });
  }

  getHost<T>(
    url: string,
    params: HttpParams = new HttpParams()
  ): Observable<T> {
    return this.http.get<T>(`${this.getApiHost()}${url}`, {
      headers: this.headers,
      params
    });
  }

  put<T>(url: string, body: any | null): Observable<T> {
    return this.http.put<T>(this.getApiUrl(url), body, {
      headers: this.headers
    });
  }

  delete(url: string): Observable<any> {
    return this.http.delete(this.getApiUrl(url), { headers: this.headers });
  }
}
