import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAuth from '@app/authentication/state/reducers';
import * as fromPage from '@app/page/state/reducers';
import { PageActions, ContactActions } from '@app/page/state/actions';

@Injectable()
export class PageFacade {
  page$ = this.store.pipe(select(fromPage.getPage));
  messageSentSuccess$ = this.store.pipe(select(fromPage.getMessageSentSuccess));
  messageSentError$ = this.store.pipe(select(fromPage.getMessageSentError));
  messageSentLoading$ = this.store.pipe(select(fromPage.getMessageSentLoading));

  constructor(private store: Store<fromPage.State>) {}

  loadPage(page: string) {
    this.store.dispatch(PageActions.loadPage({ page }));
  }

  sendMessage(message: string) {
    this.store.dispatch(ContactActions.sendMessage({ message }));
  }

  resetMessage() {
    this.store.dispatch(ContactActions.sendMessageReset());
  }
}
