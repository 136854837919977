import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss']
})
export class LikeButtonComponent implements OnInit {
  @Input()
  liked = false;

  @Input()
  count = 0;

  @Output()
  likedChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}
}
