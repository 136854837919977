<div class="research-form" [class.focus]="searchFocused">
  <form [formGroup]="form" (ngSubmit)="submit()" class="input-wrapper">
    <input
      type="text"
      placeholder="Tapez votre recherche"
      formControlName="search"
      (focus)="searchFocused = true"
      (blur)="searchFocused = false"
    />

    <svg
      class="search-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18.945"
      height="19.324"
      viewBox="0 0 18.945 19.324"
      (click)="submit()"
    >
      <g id="surface1" transform="translate(-0.109 0)">
        <path
          id="Tracé_50"
          data-name="Tracé 50"
          d="M13.308,11.467a7.265,7.265,0,1,0-2.235,2.058l5.358,5.326a1.521,1.521,0,0,0,2.205-2.095c-.018-.019-.036-.037-.055-.055Zm-5.935.5a4.686,4.686,0,1,1,4.684-4.7,4.687,4.687,0,0,1-4.684,4.7Zm0,0"
          transform="translate(0 0)"
          fill="#50b9b1"
        />
      </g>
    </svg>
  </form>
</div>
