import { createAction, props } from '@ngrx/store';
import { UserFollow } from '../../../user/models/user-follow.model';
import { Collection } from '../../../shared/models/collection';

const TAG = '[Channel/Api]';

export const loadFollowedUserSuccess = createAction(
  `${TAG} Load followed users success`,
  props<{ followedUsers: Collection<UserFollow> }>()
);
export const loadFollowedUserFailure = createAction(
  `${TAG} Load followed users failure`,
  props<{ error: any }>()
);

export const followSuccess = createAction(
  `${TAG} follow success`,
  props<{ follow: UserFollow }>()
);
export const followFailure = createAction(
  `${TAG} follow failure`,
  props<{ error: any }>()
);

export const unfollowSuccess = createAction(
  `${TAG} unfollow success`,
  props<{ unfollow: UserFollow }>()
);
export const unfollowFailure = createAction(
  `${TAG} unfollow failure`,
  props<{ error: any }>()
);
