import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@app/comment/state/reducers';
import { CommentEffects } from '@app/comment/state/effects/comment.effects';
import { CommentComponent } from '@app/comment/components/comment/comment.component';
import { CommentFormComponent } from '@app/comment/components/comment-form/comment-form.component';
import { CommentListComponent } from '@app/comment/components/comment-list/comment-list.component';
import { CommentFacade } from '@app/comment/state/comment-facade.service';
import { CommentsComponent } from './containers/comments/comments.component';
import { ShellModule } from '@app/shell';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import { UserModule } from '@app/user/user.module';

@NgModule({
  declarations: [
    CommentComponent,
    CommentFormComponent,
    CommentListComponent,
    CommentsComponent
  ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([CommentEffects]),
    StoreModule.forFeature('comment', reducers),
    ReactiveFormsModule,
    SharedModule,
    UserModule
  ],
  exports: [CommentsComponent],
  providers: [CommentFacade]
})
export class CommentModule {}
