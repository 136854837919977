<form
  class="form-wrapper"
  [formGroup]="firstConnectionForm"
  (ngSubmit)="onSubmit()"
  novalidate
>
  <!-- affiliation -->
  <div class="custom-field" *ngIf="user.preHomeData">
    <label for="my-affiliation" [class.profile]="fromProfile">
      {{ 'FIRST_CONNECTION_AFFILIATION_FIELD_LABEL' | translate }}
    </label>
    <ng-select
      id="my-affiliation"
      [items]="getAffiliations()"
      bindLabel="name"
      bindValue="id"
      placeholder="Sélectionnez votre rattachement"
      formControlName="affiliation"
      class="custom"
      (change)="onAffiliationChange(affiliation.value)"
    >
      <ng-option *ngFor="let affiliation of getAffiliations()"></ng-option>
    </ng-select>
  </div>

  <!-- business-unit -->
  <div
    class="custom-field"
    *ngIf="user.preHomeData && businessUnits.length > 0"
  >
    <label for="my-business-units" [class.profile]="fromProfile">
      {{ 'FIRST_CONNECTION_BU_FIELD_LABEL' | translate }}
    </label>
    <ng-select
      id="my-business-units"
      [items]="getBuisnessUnit()"
      [clearable]="true"
      bindLabel="name"
      bindValue="id"
      placeholder="Sélectionnez votre business unit"
      class="custom"
      formControlName="businessUnit"
      (change)="onBusinessUnitChange(businessUnit.value)"
    >
      <ng-option *ngFor="let businessUnit of getBuisnessUnit()"></ng-option>
    </ng-select>
  </div>

  <!-- team-agency -->
  <div class="custom-field" *ngIf="user.preHomeData && teamAgencies">
    <label for="my-team-agencies" [class.profile]="fromProfile">
      {{ 'FIRST_CONNECTION_AGENCY_FIELD_LABEL' | translate }}
    </label>
    <ng-select
      id="my-team-agencies"
      [items]="getTeamAgencies()"
      bindLabel="name"
      bindValue="id"
      placeholder="Sélectionnez votre agence"
      class="custom"
      formControlName="teamAgency"
    >
      <ng-option *ngFor="let teamAgency of getTeamAgencies()"></ng-option>
    </ng-select>
  </div>

  <!-- job -->
  <div class="custom-field" *ngIf="user.preHomeData && jobs">
    <label for="my-jobs" [class.profile]="fromProfile">
      {{ 'FIRST_CONNECTION_JOB_FIELD_LABEL' | translate }}
    </label>
    <ng-select
      id="my-jobs"
      [items]="getJobs()"
      bindLabel="name"
      bindValue="id"
      placeholder="Sélectionnez votre poste"
      class="custom"
      formControlName="job"
    >
      <ng-option *ngFor="let job of getJobs()"></ng-option>
    </ng-select>
  </div>

  <!-- editing button
  <div class="submit-containers" *ngIf="!isEditing()">
    <button
      type="button"
      class="edit"
      [class.loading]="isLoading()"
      (click)="startEditing()"
    >
      <span *ngIf="!isLoading()">Modifier mon rattachement</span>
      <span *ngIf="isLoading()" class="loader"></span>
    </button>
  </div>
  -->

  <!-- Ancienement NOT_EDITABLE_DATA_EXPLAIN-->
  <!--
  <small style="color: lightgray">
    {{ 'UPDATED_DATA_EXPLAIN' | translate }}
  </small>
  -->

  <!-- profession -->
  <div class="custom-field">
    <label for="my-profession" [class.profile]="fromProfile">
      {{ 'FIRST_CONNECTION_PROFESSION_FIELD_LABEL' | translate }}
    </label>
    <ng-select
      id="my-profession"
      [items]="professions"
      bindLabel="name"
      bindValue="id"
      placeholder="Sélectionnez"
      class="custom"
      formControlName="profession"
    >
      <ng-option
        *ngFor="let profession of professions"
        [value]="profession.id"
      ></ng-option>
    </ng-select>
  </div>

  <div class="error" *ngIf="getIsFieldValueNotNull('profession')">
    {{ 'FIELD_IS_REQUIRED' | translate }}
  </div>

  <!-- subordinationName -->
  <div class="custom-field">
    <label for="my-subordination-name" [class.profile]="fromProfile">
      {{ 'FIRST_CONNECTION_SUBORDINATION_NAME_FIELD_LABEL' | translate }}
    </label>
    <ng-select
      id="my-subordination-name"
      [items]="subordinationNames"
      bindLabel="name"
      bindValue="id"
      placeholder="Sélectionnez"
      class="custom"
      formControlName="subordinationName"
    >
      <ng-option
        *ngFor="let subordinationName of subordinationNames"
      ></ng-option>
    </ng-select>
  </div>

  <div
    class="error"
    *ngIf="getIsFieldValueNotNull('subordinationName') && subordinationNames"
  >
    {{ 'FIELD_IS_REQUIRED' | translate }}
  </div>

  <!-- checkbox user validate data -->
  <div class="form-check mt-3" *ngIf="!fromProfile">
    <input
      formControlName="checkValidData"
      class="form-check-input"
      type="checkbox"
      id="check-valid-data"
      style="margin-right: 10px"
      required
    />
    <label class="form-check-label cursor-pointer" for="check-valid-data">
      {{ 'FIRST_CONNECTION_CHECKBOX_LABEL' | translate }}
    </label>
  </div>

  <div class="error" *ngIf="getIsFieldValueNotNull('checkValidData')">
    {{ 'FIELD_IS_REQUIRED' | translate }}
  </div>

  <!-- submit -->
  <div class="submit-container" (click)="onSubmit()" *ngIf="!fromProfile">
    <button class="custom-submit" [class.disabled]="!firstConnectionForm.valid">
      {{ 'CONFIRM' | translate }}
    </button>
  </div>
</form>
