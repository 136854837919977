import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalWrapperComponent } from '@app/shared/modal/modal-wrapper.component';
import { ModalComponent } from './modal/modal.component';
import { IconsModule } from '@app/shared/icons';
import { ModalTitleComponent } from './modal-title/modal-title.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';

@NgModule({
  imports: [CommonModule, IconsModule],
  exports: [
    ModalWrapperComponent,
    ModalComponent,
    ModalTitleComponent,
    ModalHeaderComponent
  ],
  declarations: [
    ModalWrapperComponent,
    ModalComponent,
    ModalTitleComponent,
    ModalHeaderComponent
  ]
})
export class ModalModule {}
