import {createAction, props} from '@ngrx/store';
import {Collection} from '@app/shared/models/collection';
import {Notification} from '../../models';

const TAG = '[Notification/Api]';

export const loadNotificationsSuccess = createAction(
  `${TAG} Load notifications SUCCESS`,
  props<{ notifications: Collection<Notification> }>()
);

export const loadNotificationsFailure = createAction(
  `${TAG} Load notifications FAILURE`,
  props<{ error: any }>()
);

export const updateNotificationSuccess = createAction(
  `${TAG} update SUCCESS`,
  props<{ notification: Notification }>()
);

export const updateNotificationFailure = createAction(
  `${TAG} update FAILURE`,
  props<{ error: any }>()
);
