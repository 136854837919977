import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@app/category/state/reducers';
import { CategoryEffects } from '@app/category/state/effects/category.effects';
import { CategoryIconComponent } from './components/category-icon/category-icon.component';
import { IconsModule } from '@app/shared/icons';
import { CategoryFacade } from '@app/category/state/category.facade';
import { CategoryListFormComponent } from './components/category-list-form/category-list-form.component';

@NgModule({
  declarations: [CategoryIconComponent, CategoryListFormComponent],
  exports: [CategoryIconComponent, CategoryListFormComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([CategoryEffects]),
    StoreModule.forFeature('category', reducers),
    IconsModule
  ],
  providers: [CategoryFacade]
})
export class CategoryModule {}
