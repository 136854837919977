import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  defaultMediaSearchModel,
  MediaSearchModel,
  MediaSearchOrder,
  MediaType
} from '@app/media/models';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface MediaResearchFormValue {
  order: MediaSearchOrder;
  videoType: boolean;
  pdfType: boolean;
  playlistType: boolean;
}

export interface MediaSearchConfig {
  availableOrders: MediaSearchOrder[];
}

export function defaultMediaSearchConfig(): MediaSearchConfig {
  return {
    availableOrders: [
      MediaSearchOrder.PERTINENCE,
      MediaSearchOrder.CREATED_AT,
      MediaSearchOrder.TITLE
    ]
  };
}

export function defaultMediaResearchFormValue() {
  return {
    order: MediaSearchOrder.PERTINENCE,
    videoType: true,
    pdfType: true,
    playlistType: true
  };
}

@Injectable({
  providedIn: 'root'
})
export class MediaSearchService {
  config$ = new BehaviorSubject<MediaSearchConfig>(defaultMediaSearchConfig());
  searchForm$ = new BehaviorSubject<MediaResearchFormValue>(
    this.assertFormValue(defaultMediaResearchFormValue())
  );
  search$ = new BehaviorSubject<MediaSearchModel>(
    this.assertValue(defaultMediaSearchModel())
  );

  public constructor(private route: ActivatedRoute, private router: Router) {
    this.config$.subscribe(() => {
      this.reset();
    });

    this.config$
      .pipe(switchMap(() => this.route.queryParams))
      .subscribe(params => {
        this.searchForm$.next(
          this.assertFormValue({
            order: params.order ? params.order : MediaSearchOrder.PERTINENCE,
            videoType: params.hasOwnProperty('videoType')
              ? params.videoType === '1'
              : true,
            pdfType: params.hasOwnProperty('pdfType')
              ? params.pdfType === '1'
              : true,
            playlistType: params.hasOwnProperty('playlistType')
              ? params.playlistType === '1'
              : true
          })
        );

        const mediaTypes: MediaType[] = [];
        const searchValue = this.searchForm$.getValue();

        if (searchValue.videoType) {
          mediaTypes.push(MediaType.VIDEO);
        }

        if (searchValue.pdfType) {
          mediaTypes.push(MediaType.PDF);
        }

        if (searchValue.playlistType) {
          mediaTypes.push(MediaType.PLAYLIST);
        }

        this.search$.next(
          this.assertValue({
            ...defaultMediaSearchModel(),
            mediaTypes,
            query: params.query ? params.query : '',
            order: {
              by: searchValue.order,
              direction:
                searchValue.order === MediaSearchOrder.TITLE ? 'asc' : 'desc'
            }
          })
        );
      });
  }

  changeConfig(config: MediaSearchConfig) {
    this.config$.next(config);
  }

  search(
    value: MediaResearchFormValue,
    commands: any[] = [],
    relativeToRoute?: ActivatedRoute
  ) {
    this.searchForm$.next(
      this.assertFormValue({
        ...this.searchForm$.getValue(),
        ...value
      })
    );

    this.router.navigate(commands, {
      queryParams: {
        order: value.order,
        videoType: (value.videoType ? 1 : 0).toString(),
        pdfType: (value.pdfType ? 1 : 0).toString(),
        playlistType: (value.playlistType ? 1 : 0).toString()
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
      relativeTo: relativeToRoute ?? null
    });
  }

  assertValue(value: MediaSearchModel): MediaSearchModel {
    const config = this.config$.getValue();

    return {
      ...value,
      order: {
        ...value.order,
        by:
          config.availableOrders.indexOf(value.order.by) === -1
            ? config.availableOrders[0]
            : value.order.by
      }
    };
  }

  assertFormValue(formValue: MediaResearchFormValue): MediaResearchFormValue {
    const config = this.config$.getValue();

    return {
      ...formValue,
      order:
        config.availableOrders.indexOf(formValue.order) === -1
          ? config.availableOrders[0]
          : formValue.order
    };
  }

  reset() {
    this.searchForm$.next(
      this.assertFormValue(defaultMediaResearchFormValue())
    );
  }
}
