import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
  @Input() opened: boolean;
  @Input() loggedUser: User;
  @Output() toggleMenu = new EventEmitter();
  @Output() logout = new EventEmitter();

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  onLogout() {
    this.toggleMenu.emit();
    this.logout.emit();
  }
}
