import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '@app/tag/models/tag.model';

@Component({
  selector: 'app-tag-badge',
  templateUrl: './tag-badge.component.html',
  styleUrls: ['./tag-badge.component.scss']
})
export class TagBadgeComponent {
  @Input()
  tag: Tag;

  @Output()
  remove = new EventEmitter<Tag>();

  constructor() {}
}
