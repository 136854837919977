import { ApiCollection } from '@app/shared/models/api-collection';

export class Collection<T> {
  items: T[];
  count: number;
}

export function defaultCollection<T>(): Collection<T> {
  return {
    items: [],
    count: 0
  };
}

export function collectionFromApi<T>(
  apiCollection: ApiCollection<T>
): Collection<T> {
  return {
    items: apiCollection['hydra:member'],
    count: apiCollection['hydra:totalItems']
  };
}
