<ng-container *ngIf="token$ | async as token">
  <ng-container *ngIf="pdfUrl && token !== undefined">
    <ngx-extended-pdf-viewer
      [src]="pdfUrl"
      [showToolbar]="true"
      [showSidebarButton]="false"
      [showFindButton]="false"
      [showPagingButtons]="true"
      [showZoomButtons]="true"
      [showPresentationModeButton]="true"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="true"
      [showBookmarkButton]="false"
      [showSecondaryToolbarButton]="true"
      [showRotateButton]="true"
      [showHandToolButton]="false"
      [showScrollingButton]="false"
      [showSpreadButton]="false"
      [showPropertiesButton]="false"
      [useBrowserLocale]="true"
      [enablePrint]="false"
      [httpHeaders]="{ authorization: 'Bearer ' + token.token }"
      mobileFriendlyZoom="1.1"
      backgroundColor="#222"
      zoom="page-fit"
    ></ngx-extended-pdf-viewer>
    <span *ngIf="isEdgeLegacy">
      Si vous rencontrez des soucis d'affichage vous pouvez télécharger le PDF
      en cliquant sur
      <svg viewBox="0 0 24 24" style="width: 20px; height: 20px;">
        <path
          fill="currentColor"
          d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z"
        ></path>
      </svg>
      en haut de la liseuse
    </span>
  </ng-container>
</ng-container>
