import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Media } from '@app/media/models';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'app-media-creator',
  templateUrl: './media-creator.component.html',
  styleUrls: ['./media-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCreatorComponent implements OnInit {
  @Input()
  media: Media;

  @Input()
  theme: 'dark' | 'light' = 'dark';

  constructor() {}

  ngOnInit() {}

  get creator() {
    return this.media.creator as User;
  }
}
