import { createReducer, on } from '@ngrx/store';

import { TagActions, TagApiActions } from '@app/tag/state/actions';
import { Tag } from '@app/tag/models/tag.model';
import { Collection, defaultCollection } from '@app/shared/models/collection';

export interface State {
  tags: Collection<Tag>;
  loading: boolean;
  loaded: boolean;
}

export const INITIAL_STATE: State = {
  tags: defaultCollection<Tag>(),
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(TagActions.loadTags, state => ({
    ...state,
    tags: defaultCollection<Tag>(),
    loading: true,
    loaded: false
  })),
  on(TagApiActions.loadTagsSuccess, (state, { tags }) => ({
    ...state,
    tags,
    loading: false,
    loaded: true
  })),
  on(TagApiActions.loadTagsFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  }))
);

export const getTags = (state: State) => state.tags;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
