export function patchModelRecursively<T extends { id?: number }>(
  model: T,
  patch: Partial<T>,
  subFields: string[] = []
) {
  if (patch.id === undefined) {
    throw Error("can't patch without identifier");
  }

  let newModel = Object.assign({}, model);

  if (newModel.id === patch.id) {
    newModel = {
      ...newModel,
      ...patch
    };
  }

  for (const field of subFields) {
    if ((model as any)[field] === undefined) {
      continue;
    }

    const subModels = (model as any)[field];

    if (Array.isArray(subModels)) {
      (newModel as any)[field] = [];
      for (const value of subModels) {
        (newModel as any)[field].push(
          patchModelRecursively(value, patch, subFields)
        );
      }
    } else {
      (newModel as any)[field] = patchModelRecursively(
        subModels,
        patch,
        subFields
      );
    }
  }

  return newModel;
}
