import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommentActions, CommentApiActions } from '@app/comment/state/actions';
import { CommentService } from '@app/comment/services';
import { Iri } from '@app/shared/models/api-entity';

@Injectable()
export class CommentEffects {
  loadComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.loadComments),
      switchMap(({ mediaIri }) => {
        return this.service.loadComments(mediaIri).pipe(
          map(comments => CommentApiActions.loadCommentsSuccess({ comments })),
          catchError(error => {
            return of(
              CommentApiActions.loadCommentsFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  reloadCommentAfterAdding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentApiActions.addCommentSuccess),
      map(({ comment }) =>
        CommentActions.loadComments({ mediaIri: comment.media as Iri })
      )
    )
  );

  addComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.addComment),
      switchMap(({ comment }) => {
        return this.service.addComment(comment).pipe(
          map(addedComment =>
            CommentApiActions.addCommentSuccess({ comment: addedComment })
          ),
          catchError(error => {
            return of(
              CommentApiActions.addCommentFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  likeComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.likeComment),
      switchMap(({ comment }) => {
        return this.service.likeComment(comment.id as number).pipe(
          map(({ likeCount }) =>
            CommentApiActions.likeCommentSuccess({
              id: comment.id as number,
              likeCount
            })
          ),
          catchError(error => {
            return of(
              CommentApiActions.likeCommentFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  unlikeComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.unlikeComment),
      switchMap(({ comment }) => {
        return this.service.unlikeComment(comment.id as number).pipe(
          map(({ likeCount }) =>
            CommentApiActions.unlikeCommentSuccess({
              id: comment.id as number,
              likeCount
            })
          ),
          catchError(error => {
            return of(
              CommentApiActions.unlikeCommentFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private service: CommentService
  ) {}
}
