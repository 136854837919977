import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {interval, Subscription, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-next-media-timer',
  templateUrl: './next-media-timer.component.html',
  styleUrls: ['./next-media-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextMediaTimerComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  maxTime = 5000;
  time = 0;

  @Output()
  timerEnded = new EventEmitter<void>();

  @Output()
  timerNext = new EventEmitter<void>();

  @Output()
  timerCanceled = new EventEmitter<void>();

  constructor(private change: ChangeDetectorRef, private zone: NgZone) {
  }

  get progress() {
    return this.time > (this.maxTimeSeconde - 1) ? 100 : (this.time / (this.maxTimeSeconde - 1)) * 100;
  }

  get maxTimeSeconde() {
    return this.maxTime / 1000;
  }

  cancel() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.timerCanceled.emit();
  }

  next() {
    this.timerNext.emit();
  }

  ngOnInit(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.time = 0;
    this.subscription = interval(1000).pipe(takeUntil(timer(this.maxTime + 500))).subscribe(() => {
      this.zone.run(() => {
        this.time++;

        if (this.time >= this.maxTimeSeconde) {
          this.timerEnded.emit();
        }

        this.change.detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
