import { createAction, props } from '@ngrx/store';
import { ExternalLink } from '@app/links-bar/models/external-link.model';

const TAG = '[ExternalLink/Api]';

export const loadExternalLinksSuccess = createAction(
  `${TAG} Load links SUCCESS`,
  props<{ externalLinks: ExternalLink[] }>()
);

export const loadExternalLinksFailure = createAction(
  `${TAG} Load links FAILURE`,
  props<{ error: any }>()
);
