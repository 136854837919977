<div class="pre-home">
  <div class="main-titles-container">
    <img
      class="logotube"
      src="../../../../assets/images/logo.png?270224"
      alt="Logo MyCampusTube"
    />
    <div class="white_text">VOTRE PLATEFORME DE FORMATION SE REFAIT</div>
    <div class="white_on_white_text">UNE BEAUTÉ !</div>
    <div class="yellow_on_white_text">UNE BEAUTÉ !</div>
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape big_top_right"
      alt="Top Right big shape"
    />
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape medium_top_right"
      alt="Top Right medium shape"
    />
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape small_top_right"
      alt="Top Right small shape"
    />
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape medium_bottom_right"
      alt="Bottom Right medium shape"
    />
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape small_bottom_right"
      alt="Bottom Right small shape"
    />
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape small_bottom_right"
      alt="Bottom Right small shape"
    />
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape big_bottom_left"
      alt="Bottom Left big shape"
    />
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape medium_bottom_left"
      alt="Bottom Left medium shape"
    />
    <img
      src="assets/images/white_triangle_prehome.svg"
      class="shape small_bottom_left"
      alt="Bottom Left small shape"
    />
  </div>

  <div class="form-container">
    <div class="logos-wrapper">
      <img src="assets/images/logo-my-campus.png?270224" alt="Logo MyCampus" />
    </div>

    <div class="welcome-texts-container">
      <app-first-connection-welcome
        [user]="user$ | async"
      ></app-first-connection-welcome>
    </div>

    <app-first-connection></app-first-connection>
  </div>
</div>
