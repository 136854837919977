import {Inject, Injectable, InjectionToken} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {Observable, of, throwError} from 'rxjs';
import {catchError, flatMap, map, take} from 'rxjs/operators';

import {AuthToken, Credentials, Register, SendResetPassword} from '@app/authentication/models/auth';
import {authConfig} from '@app/authentication/auth.config';
import * as fromAuth from '@app/authentication/state/reducers';
import {HttpService} from '@app/core/services/http.service';
import {User} from '../../user/models/user.model';
import {formExceptionNormalize} from '@app/shared/utils/form-exception-normalizer';
import {environment} from '@env/environment';

export function storageFactory() {
  return localStorage;
}

export const LOCAL_STORAGE_TOKEN = new InjectionToken('ad-authentication', {
  factory: storageFactory
});

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private httpService: HttpService,
    private store: Store<fromAuth.State>,
    @Inject(LOCAL_STORAGE_TOKEN) private storage: Storage
  ) {
  }

  get storedToken(): AuthToken | undefined {
    const localStorageObj = this.storage.getItem(authConfig.storageKey);

    if (localStorageObj) {
      return JSON.parse(localStorageObj);
    }

    return undefined;
  }

  clearToken(): Observable<boolean> {
    this.storage.removeItem(authConfig.storageKey);
    return of(true);
  }

  confirmResetting(token: string): Observable<object> {
    return this.httpService.getHost(`/auth/email/${token}`);
  }

  confirmRegistration(token: string): Observable<object> {
    return this.httpService.getPublic(`/register/confirm/${token}`);
  }

  getLoggedUser(forceReload?: boolean): Observable<User> {
    if (forceReload) {
      return this.getMe();
    }
    return this.store.pipe(
      select(fromAuth.getLoggedUser),
      flatMap(loggedUser => {
        if (loggedUser) {
          return of(loggedUser);
        }
        return this.getMe();
      }),
      take(1)
    );
  }

  getMe(): Observable<User> {
    return this.httpService.get<User>(`/users/me`);
  }

  consent(user: User): Observable<User> {
    return this.httpService.put<User>(`/users/${user.id}`, {
      ...user,
      teamAgency: user.teamAgency ? user.teamAgency.id : undefined,
      job: user.job ? user.job.id : undefined,
      businessUnit: user.businessUnit ? user.businessUnit.id : undefined,
      profession: user.profession ? user.profession.id : undefined,
      affiliation: user.affiliation ? user.affiliation.id : undefined,
      consentedAt: (new Date()).toISOString()
    });
  }

  getToken(): Observable<AuthToken> {
    const localStorageObj = this.storedToken;

    if (localStorageObj) {
      return of(localStorageObj);
    }
    return throwError('No local storage');
  }

  toSamlLogin() {
    this.setRedirect(window.location.href);
    window.location.href = `${environment.apiHost}/saml/login/`;
  }

  applyRedirect() {
    const redirect = this.storage.getItem(authConfig.redirectStorageKey);

    if (!redirect) {
      return false;
    }

    this.removeRedirect();
    window.location.href = redirect;
    return true;
  }

  setRedirect(url: string) {
    this.storage.setItem(authConfig.redirectStorageKey, url);
  }

  removeRedirect() {
    this.storage.removeItem(authConfig.redirectStorageKey);
  }

  login(credentials: Credentials): Observable<AuthToken> {
    return this.httpService
      .postHost('/api_login_check', {
        username: credentials.login,
        password: credentials.password
      })
      .pipe(map(res => res as AuthToken));
  }

  refreshToken(): Observable<AuthToken> {
    const authToken = this.storedToken;

    if (authToken === undefined) {
      return throwError('Invalid stored token');
    }

    const refreshToken = (authToken as AuthToken).refresh_token;
    return this.httpService
      .postHost('/api_refresh_token', {
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
      })
      .pipe(map(res => res as AuthToken));
  }

  refreshTokenWithParam(refreshToken: string): Observable<AuthToken> {
    return this.httpService
      .postHost('/api_refresh_token', {
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
      })
      .pipe(map(res => res as AuthToken));
  }

  register(register: Register): Observable<User> {
    const origin = window.location.origin;
    const confirmationUrl = `${origin}/#/${authConfig.path.registerConfirm}/token`;
    const post = {
      ...register,
      confirmationUrl
    };

    return this.httpService.postPublic('/register', post).pipe(
      map(user => user as User),
      catchError(errors => {
        const error = formExceptionNormalize(errors);
        if (error && error.email) {
          return throwError({error});
        } else {
          return throwError(errors);
        }
      })
    );
  }

  reset(username: SendResetPassword): Observable<string> {
    const post = {
      email: username.username
    };
    return this.httpService
      .postHost('/auth/reset/password', post)
      .pipe(map(res => res as string));
  }

  resetPassword(password: string, token: string): Observable<object> {
    return this.httpService.postHost(`/auth/save/password/${token}`, {
      password
    });
  }

  setToken(auth: AuthToken): Observable<AuthToken> {
    this.storage.setItem(authConfig.storageKey, JSON.stringify(auth));
    return of(auth);
  }
}
