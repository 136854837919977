import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ModalService } from '@app/shared/modal/modal.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [ModalService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1, 'padding-top': '10px', overflow: 'auto' }),
        animate('300ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  closableByDefault = true;

  @Input()
  opened = false;

  @Output()
  closing = new EventEmitter<void>();

  constructor(
    private modalService: ModalService,
    private element: ElementRef,
    private change: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.modalService.closableByDefault = this.closableByDefault;
    this.modalService.open$.subscribe(value => {
      this.opened = value;
      this.change.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    document.body.appendChild(this.element.nativeElement);
  }

  open() {
    this.modalService.open();
  }

  close() {
    this.modalService.close();
    this.closing.emit();
  }

  backdropClick(event: Event) {
    if (
      event.currentTarget === event.target &&
      this.modalService.closableByDefault
    ) {
      this.modalService.close();
    }
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.element.nativeElement.remove();
  }
}
