import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { TagActions, TagApiActions } from '@app/tag/state/actions';
import { TagService } from '@app/tag/services';

@Injectable()
export class TagEffects {
  loadTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.loadTags),
      exhaustMap(({ name }) => {
        return this.tagService.loadTags(name).pipe(
          map(tags => TagApiActions.loadTagsSuccess({ tags })),
          catchError(error => {
            return of(TagApiActions.loadTagsFailure({ error }));
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private tagService: TagService
  ) {}
}
