import { createReducer, on } from '@ngrx/store';
import { Affiliations, Professions } from '@app/authentication/models/auth';

import { FirstConnectionActions } from '@app/authentication/state/actions';

export interface State {
  professions: Professions;
  affiliations: Affiliations | null;
  infosSent: boolean;
}

export const INITIAL_STATE: State = {
  professions: null,
  affiliations: null,
  infosSent: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(
    FirstConnectionActions.loadProfessionsSuccess,
    (state, { professions }) => ({
      ...state,
      professions
    })
  ),
  on(
    FirstConnectionActions.loadAffiliationsSuccess,
    (state, { affiliations }) => ({
      ...state,
      affiliations
    })
  ),

  on(FirstConnectionActions.loadProfessionsFailure, state => ({
    ...state,
    professions: null
  })),
  on(FirstConnectionActions.loadAffiliationsFailure, state => ({
    ...state,
    affiliations: null
  })),

  on(FirstConnectionActions.firstUpdateUserInfos, state => ({
    ...state
  }))
);

export const getFirstConnectionProfessions = (state: State) =>
  state.professions;
export const getFirstConnectionAffiliations = (state: State) =>
  state.affiliations;
