import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthFacade } from '@app/authentication/state/auth.facade';

@Component({
  selector: 'app-refresh-token',
  templateUrl: './refresh-token.component.html',
  styleUrls: ['./refresh-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefreshTokenComponent implements OnInit {
  refreshToken: string | null = null;

  constructor(private route: ActivatedRoute, private authFacade: AuthFacade) {}

  ngOnInit() {
    this.refreshToken = this.route.snapshot.paramMap.get('refresh_token');
    this.tokenRefresh();
  }

  tokenRefresh() {
    this.authFacade.loadRefreshToken(this.refreshToken as string);
  }
}
