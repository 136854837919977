import {Component, ElementRef, HostBinding, Input, OnInit} from '@angular/core';
import {Media} from '@app/media/models';
import {Slider} from '@app/media/models/slider';
import {HighlightedMedia} from '../../models/highlighted-media.model';

@Component({
  selector: 'app-media-tile',
  templateUrl: './media-tile.component.html',
  styleUrls: ['./media-tile.component.scss']
})
export class MediaTileComponent implements OnInit, Slider {
  @Input()
  media: Media | HighlightedMedia;

  @Input()
  playlist: Media;

  @HostBinding('class.horizontal')
  @Input()
  horizontal: boolean;

  @Input()
  description = false;

  @HostBinding('class.highlight')
  @Input()
  highlight = false;

  constructor(private elementRef: ElementRef) {
  }

  get finalMedia() {
    return (this.media as HighlightedMedia).media ?? this.media;
  }

  get title() {
    return (this.media as HighlightedMedia).media ? (this.media as HighlightedMedia).name : (this.media as Media).title;
  }

  get positionRight() {
    return (
      this.elementRef.nativeElement.offsetLeft +
      this.elementRef.nativeElement.clientWidth
    );
  }

  get positionLeft() {
    return this.elementRef.nativeElement.offsetLeft;
  }

  get positionTop(): number {
    return this.elementRef.nativeElement.offsetTop;
  }

  ngOnInit() {
  }
}
