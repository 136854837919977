import { createReducer, on } from '@ngrx/store';
import { Collection, defaultCollection } from '@app/shared/models/collection';
import { Category } from '@app/category/models';
import {
  CategoryActions,
  CategoryApiActions
} from '@app/category/state/actions';

export interface State {
  categories: Collection<Category>;
  category?: Category;

  loading: boolean;
  loaded: boolean;
}

export const INITIAL_STATE: State = {
  categories: defaultCollection<Category>(),
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(CategoryActions.loadCategories, state => ({
    ...state,
    categories: defaultCollection<Category>(),
    loading: true,
    loaded: false
  })),
  on(CategoryActions.loadCategory, state => ({
    ...state,
    category: undefined,
    loading: true,
    loaded: false
  })),
  // Api actions
  on(CategoryApiActions.loadCategoriesSuccess, (state, { categories }) => ({
    ...state,
    categories,
    loading: false,
    loaded: true
  })),
  on(CategoryApiActions.loadCategoriesFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(CategoryApiActions.loadCategorySuccess, (state, { category }) => ({
    ...state,
    category,
    loading: false,
    loaded: true
  })),
  on(CategoryApiActions.loadCategoriesFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  }))
);

export const getCategories = (state: State) => state.categories;
export const getCategory = (state: State) => state.category;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
