import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-outlined',
  templateUrl: './button-outlined.component.html',
  styleUrls: ['./button-outlined.component.scss']
})
export class ButtonOutlinedComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string | null = null;
  @Input() shrinkable = false;
  @Input() iconBefore = false;
  @Input() disabled = false;

  constructor() {}

  ngOnInit() {}
}
