import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '../../../user/models/user.model';
import {FormBuilder, Validators} from '@angular/forms';
import {Comment} from '../../models/comment.model';
import {Media} from '../../../media/models';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent {
  form = this.builder.group({
    content: ['', [Validators.minLength(3), Validators.required]]
  });

  @Input()
  user: User;

  @Input()
  media: Media;

  @Input()
  parent: Comment;

  @Output()
  comment = new EventEmitter<Comment>();

  constructor(private builder: FormBuilder) {
  }

  submit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.comment.emit({
      author: this.user,
      content: this.form.controls.content.value,
      media: this.media,
      parent: this.parent,
      liked: false
    });

    this.form.patchValue({
      content: ''
    });
  }
}
