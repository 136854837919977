import { createAction, props } from '@ngrx/store';
import { Media, MediaExtraData } from '@app/media/models/media.model';
import { FeaturedMedia } from '../../models/highlighted-media.model';
import { Collection } from '@app/shared/models/collection';

const TAG = '[Media/Api]';

export const loadMediasSuccess = createAction(
  `${TAG} Load medias SUCCESS`,
  props<{ medias: Collection<Media> }>()
);

export const loadMediasFailure = createAction(
  `${TAG} Load medias FAILURE`,
  props<{ error: any }>()
);

export const loadMyMediasSuccess = createAction(
  `${TAG} Load my medias SUCCESS`,
  props<{ medias: Collection<Media> }>()
);

export const loadMyMediasFailure = createAction(
  `${TAG} Load my medias FAILURE`,
  props<{ error: any }>()
);

export const loadSuggestedMediasSuccess = createAction(
  `${TAG} Load suggested medias SUCCESS`,
  props<{ medias: Collection<Media> }>()
);

export const loadSuggestedMediasFailure = createAction(
  `${TAG} Load suggested medias FAILURE`,
  props<{ error: any }>()
);

export const loadTopMediasSuccess = createAction(
  `${TAG} Load top medias SUCCESS`,
  props<{ medias: Collection<Media> }>()
);

export const loadTopMediasFailure = createAction(
  `${TAG} Load top medias FAILURE`,
  props<{ error: any }>()
);

export const loadContinueMediasSuccess = createAction(
  `${TAG} Load continue medias SUCCESS`,
  props<{ medias: Collection<Media> }>()
);

export const loadContinueMediasFailure = createAction(
  `${TAG} Load continue medias FAILURE`,
  props<{ error: any }>()
);

export const loadRecommendedMediasSuccess = createAction(
  `${TAG} Load recommended medias SUCCESS`,
  props<{ medias: Collection<FeaturedMedia> }>()
);

export const loadRecommendedMediasFailure = createAction(
  `${TAG} Load recommended medias FAILURE`,
  props<{ error: any }>()
);

export const loadAssociatedPlaylistsSuccess = createAction(
  `${TAG} Load associated playlists SUCCESS`,
  props<{ medias: Collection<Media> }>()
);

export const loadAssociatedPlaylistsFailure = createAction(
  `${TAG} Load associated playlists FAILURE`,
  props<{ error: any }>()
);

export const loadMediaSuccess = createAction(
  `${TAG} Load media SUCCESS`,
  props<{ media: Media }>()
);

export const loadMediaFailure = createAction(
  `${TAG} Load media FAILURE`,
  props<{ error: any }>()
);

export const loadFeaturedMediasSuccess = createAction(
  `${TAG} Load featured medias SUCCESS`,
  props<{ featuredMedias: Collection<FeaturedMedia> }>()
);

export const loadFeaturedMediasFailure = createAction(
  `${TAG} Load featured medias FAILURE`,
  props<{ error: any }>()
);

export const loadMediaExtraDataSuccess = createAction(
  `${TAG} Load media extra data SUCCESS`,
  props<{ mediaExtraData: MediaExtraData }>()
);

export const loadMediaExtraDataFailure = createAction(
  `${TAG} Load media extra data FAILURE`,
  props<{ error: any }>()
);

export const loadFeaturedMediaExtraDataSuccess = createAction(
  `${TAG} Load featured media extra data SUCCESS`,
  props<{ mediaExtraData: MediaExtraData }>()
);

export const loadFeaturedMediaExtraDataFailure = createAction(
  `${TAG} Load featured media extra data FAILURE`,
  props<{ error: any }>()
);

export const searchMediasSuccess = createAction(
  `${TAG} Load search medias SUCCESS`,
  props<{ medias: Collection<Media> }>()
);

export const searchMediasFailure = createAction(
  `${TAG} Load search medias FAILURE`,
  props<{ error: any }>()
);

export const likeMediaSuccess = createAction(
  `${TAG} Like media SUCCESS`,
  props<{ likeCount: number }>()
);
export const likeMediaFailure = createAction(
  `${TAG} Like media FAILURE`,
  props<{ error: any }>()
);
export const unlikeMediaSuccess = createAction(
  `${TAG} Unlike media SUCCESS`,
  props<{ likeCount: number }>()
);
export const unlikeMediaFailure = createAction(
  `${TAG} Unlike media FAILURE`,
  props<{ error: any }>()
);
export const saveMediaVideoPositionSuccess = createAction(
  `${TAG} Save media video position SUCCESS`,
  props<{ currentUserLastVideoPosition: number }>()
);
export const saveMediaVideoPositionFailure = createAction(
  `${TAG} Save media video position FAILURE`,
  props<{ error: any }>()
);
