import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-media-research-input',
  templateUrl: 'media-research-input.component.html',
  styleUrls: ['media-research-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaResearchInputComponent {
  @Output() searchChange = new EventEmitter<string>();

  @Input()
  set search(search: string) {
    this.form.patchValue({ search });
  }

  form = this.builder.group({
    search: ['']
  });

  searchFocused = false;

  constructor(private builder: FormBuilder) {}

  submit() {
    this.searchChange.emit(this.form.value.search);
  }
}
