import { Pipe, PipeTransform } from '@angular/core';
import { MediaVisibility } from '@app/media/models';

@Pipe({
  name: 'visibility'
})
export class VisibilityPipe implements PipeTransform {
  transform(value: MediaVisibility, maleGender = true): string {
    switch (value) {
      case MediaVisibility.PRIVATE:
        return maleGender ? 'Privé' : 'Privée';
      case MediaVisibility.PUBLIC:
        return 'Publique';
      case MediaVisibility.UNLISTED:
        return maleGender ? 'Non répertorié' : 'Non répertoriée';
      default:
        return '-';
    }
  }
}
