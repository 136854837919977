import * as fromRoot from '@app/core/state/reducers';
import * as fromExternaLinks from './external-link.reducers';
import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

export interface State extends fromRoot.State {
  externalLinks: fromExternaLinks.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    externalLinks: fromExternaLinks.reducer
  })(state, action);
}

export const selectExternalLinksState = createFeatureSelector<State>(
  'externalLinks'
);

export const getExternalLinksState = createSelector(
  selectExternalLinksState,
  state => state.externalLinks
);

export const getExternalLinks = createSelector(
  getExternalLinksState,
  fromExternaLinks.getExternalLinks
);
