<h3 *ngIf="optional">
  Ou vous pouvez aussi
  <br />
  personnaliser la miniature
</h3>

<div class="wrapper">
  <div class="left">
    <img
      *ngIf="preview === undefined"
      src="assets/images/default_thumbnail_upload.svg"
    />
    <img *ngIf="preview !== undefined" [src]="preview" #previewImg />
  </div>

  <div class="right">
    <input
      class="hide"
      type="file"
      (change)="inputChange($event)"
      accept="image/png, image/jpeg"
      #input
    />
    <span>Format minimum : 1280x600 px</span>

    <app-button-outlined
      text="Choisir une image"
      (click)="input.click()"
    ></app-button-outlined>
  </div>
</div>
