import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  UserChannelActions,
  UserChannelApiActions
} from '@app/channel-media/state/actions';
import { UserService } from '@app/user/services/user.service';
import { MediaService } from '@app/media/services';
import { UserChannelFacade } from '@app/channel-media/state/user-channel-facade.service';
import { HttpParams } from '@angular/common/http';
import { User } from '@app/user/models/user.model';
import { ChannelApiActions } from '../../../channel/state/actions';

@Injectable()
export class UserChannelEffects {
  loadUserChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserChannelActions.loadUserChannel),
      switchMap(({ id }) => {
        return this.userService.getUser(id).pipe(
          map(user => UserChannelApiActions.loadUserChannelSuccess({ user })),
          catchError(error => {
            return of(
              UserChannelApiActions.loadUserChannelFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  reloadUserChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChannelApiActions.followSuccess,
        ChannelApiActions.unfollowSuccess
      ),
      withLatestFrom(this.userChannelFacade.getUserChannel$),
      map(([_, userChannel]) => userChannel),
      filter((userChannel): userChannel is User => !!userChannel?.id),
      map(userChannel =>
        UserChannelActions.loadUserChannel({ id: userChannel.id, reset: false })
      )
    )
  );

  autoloadUserChannelMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserChannelActions.loadUserChannel),
      filter(({ reset }) => reset),
      map(({ id }) => UserChannelActions.loadUserChannelMedias({ userId: id }))
    )
  );

  autoloadUserChannelMediaSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserChannelActions.changeUserChannelMediaSearch),
      withLatestFrom(this.userChannelFacade.getUserChannel$),
      filter(([_, user]) => user !== undefined),
      map(([_, user]) =>
        UserChannelActions.loadUserChannelMedias({ userId: (user as User).id })
      )
    )
  );

  loadUserChannelMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserChannelActions.loadUserChannelMedias),
      withLatestFrom(this.userChannelFacade.getUserChannelMediasSearch$),
      switchMap(([{ userId }, search]) => {
        return this.mediaService
          .getMedias({ creator: userId.toString() }, search)
          .pipe(
            map(medias =>
              UserChannelApiActions.loadUserChannelMediasSuccess({ medias })
            )
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private userService: UserService,
    private mediaService: MediaService,
    private userChannelFacade: UserChannelFacade
  ) {}
}
