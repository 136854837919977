import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services';
import { Observable } from 'rxjs';
import { Category } from '@app/category/models';
import { ApiCollection } from '@app/shared/models/api-collection';
import { map } from 'rxjs/operators';
import { Collection, collectionFromApi } from '@app/shared/models/collection';

@Injectable({ providedIn: 'root' })
export class CategoryService {
  public constructor(private httpService: HttpService) {}

  getCategories(): Observable<Collection<Category>> {
    return this.httpService
      .get<ApiCollection<Category>>('/categories/me')
      .pipe(map(collection => collectionFromApi(collection)));
  }

  getCategory(id: number): Observable<Category> {
    return this.httpService.get<Category>(`/categories/${id}`);
  }
}
