import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CategoryService } from '@app/category/services/category.service';
import {
  CategoryActions,
  CategoryApiActions
} from '@app/category/state/actions';

@Injectable()
export class CategoryEffects {
  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.loadCategories),
      switchMap(() => {
        return this.service.getCategories().pipe(
          map(categories =>
            CategoryApiActions.loadCategoriesSuccess({ categories })
          ),
          catchError(error => {
            return of(
              CategoryApiActions.loadCategoriesFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.loadCategory),
      switchMap(({ id }) => {
        return this.service.getCategory(id).pipe(
          map(category => CategoryApiActions.loadCategorySuccess({ category })),
          catchError(error => {
            return of(
              CategoryApiActions.loadCategoryFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private service: CategoryService
  ) {}
}
