<div class="list">
  <div class="list-header flex-row">
    <div class="item-remove"></div>
    <div class="item-thumbnail">Miniature</div>
    <div class="flex-1 item-title">Titre et description</div>
    <div class="text-center item-date">Date</div>
    <div class="text-center item-view" *ngIf="display === 'complete'">Vues</div>
    <div class="text-center item-comment" *ngIf="display === 'complete'">Commentaires</div>
    <div class="text-center item-like" *ngIf="display === 'complete'">J'aime</div>
    <div class="text-center item-visibility">Visibilité</div>
    <div class="text-center item-action">
      <app-button-outlined
        *ngIf="display === 'short'"
        class="create-playlist"
        text="Créer un parcours"
        (click)="createPlaylist()"
      ></app-button-outlined>
    </div>
  </div>

  <div class="list-body flex-column">
    <div class="list-item" *ngFor="let media of medias; trackBy:trackById">
      <div class="list-item-wrapper flex-row">
        <div class="item-remove">
          <app-button-outlined
            class="remove"
            icon="assets/images/remove.svg"
            (click)="remove(media)"
          ></app-button-outlined>
        </div>

        <div class="item-thumbnail">
          <a class="wrapper" [routerLink]="media | mediaRoute">
            <div class="media-view">
              <app-media-view [media]="media"></app-media-view>
            </div>
          </a>
        </div>

        <div class="flex-1 item-title">
          <div class="media-info">
            <a
              class="media-title"
              [routerLink]="['/medias', media.id]"
              [title]="media.title"
            >
              {{ media.title }}
            </a>

            <div class="media-description">
              <div *nestedEllipsis="true" [innerHTML]="media.description"></div>
            </div>
          </div>
        </div>

        <div class="text-center item-date">
          {{ media.createdAt | date }}
        </div>

        <div class="text-center item-view" *ngIf="display === 'complete'">
          {{ media.viewCount }}
        </div>

        <div class="text-center item-comment" *ngIf="display === 'complete'">
          {{ media.comments?.length ? media.comments?.length : 0 }}
        </div>

        <div class="text-center item-like" *ngIf="display === 'complete'">
          {{ media.likeCount }}
        </div>

        <div class="text-center item-visibility">
          {{ media.visibility | visibility: media.type === MediaType.PDF }}
        </div>

        <div class="item-action">
          <app-button-outlined
            icon="assets/images/cog.svg"
            text="Modifier"
            (click)="edit(media)"
          ></app-button-outlined>
        </div>
      </div>

      <div class="playlist" *ngIf="media.type === MediaType.PLAYLIST">
        <div class="playlist-items expandable" #container hidden [style.max-height.px]="container.scrollHeight">
          <div class="playlist-item flex-row" *ngFor="let item of media.playlistItems">
            <div class="item-remove"></div>

            <div class="item-order">
              <span>Affiché en :</span>
              <app-select-position
                [count]="item.position"
                (countChange)="changeItemPosition(media, item, $event)"
              ></app-select-position>
            </div>

            <div class="item-wrapper flex-1">
              <div class="item-thumbnail-small">
                <a class="wrapper" [routerLink]="item.mediaItem | mediaRoute">
                  <div class="media-view">
                    <app-media-view [media]="item.mediaItem"></app-media-view>
                  </div>
                </a>
              </div>

              <div class="item-title">
                <div class="media-info">
                  <a
                    class="media-title"
                    [routerLink]="item.mediaItem | mediaRoute"
                    [title]="item.mediaItem.title"
                  >
                    {{ item.mediaItem.title }}
                  </a>

                  <div class="media-description">
                    <div *nestedEllipsis="true" [innerHTML]="item.mediaItem.description"></div>
                  </div>
                </div>
              </div>

              <div class="item-date">
                {{ item.mediaItem.createdAt | date }}
              </div>

              <div class="item-action-small">
                <a (click)="removeFromPlaylist(media, item)">
                  <span>Supprimer du parcours</span>
                  <app-icon src="assets/images/remove.svg" class="dark-green-fill delete"></app-icon>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="playlist-action">
          <app-button-outlined
            class="button-plain"
            text="Ajouter un contenu"
            (click)="editPlaylist(media)"
          ></app-button-outlined>

          <a (click)="container.hidden = !container.hidden" *ngIf="media.playlistItems.length">
            <span>{{!container.hidden ? 'Masquer le détail' : 'Afficher le détail'}}</span>
            <app-icon src="assets/images/slide-up.svg" *ngIf="!container.hidden"></app-icon>
            <app-icon src="assets/images/slide-down.svg" *ngIf="container.hidden"></app-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-media-update
  [media]="editingMedia"
  (mediaChange)="edited($event)"
></app-media-update>

<app-media-remove
  [media]="removingMedia"
  (mediaRemoved)="removed($event)"
></app-media-remove>

<app-playlist-media-remove
  [item]="removingFromPlaylistItem"
  (mediaRemove)="removePlaylistMedia()"
></app-playlist-media-remove>

<app-playlist-items
  [playlist]="editingPlaylist"
  (playlistChanged)="updatePlaylist($event)"
></app-playlist-items>
