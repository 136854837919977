import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';

@Directive({
  selector: '[appFitText]',
})
export class FitTextDirective implements AfterViewInit, OnInit, OnChanges, AfterViewChecked {
  @Input() appFitText: any;
  @Input() activateOnResize: boolean;
  @Input() container: HTMLElement;
  @Input() activateOnInputEvents: boolean;
  @Input() minFontSize = 7;
  @Input() maxFontSize = 1000;
  @Input() modelToWatch: any;

  @Output() fontSizeChanged: EventEmitter<number> = new EventEmitter();

  private fontSize = 1000;
  private speed = 1.05;
  private done = false;

  constructor(public el: ElementRef<HTMLElement>) {
  }

  setFontSize(fontSize: number): void {
    if (this.isVisible()) {
      if (fontSize < this.minFontSize) {
        fontSize = this.minFontSize;
      }

      if (fontSize > this.maxFontSize) {
        fontSize = this.maxFontSize;
      }

      this.fontSize = fontSize;
      this.fontSizeChanged.emit(fontSize);
      this.el.nativeElement.style.setProperty('font-size', fontSize.toString() + 'px');
      this.el.nativeElement.style.setProperty('line-height', fontSize.toString() + 'px');
    }
  }

  calculateFontSize(fontSize: number, speed: number): number {
    return Math.floor(fontSize / speed);
  }

  checkOverflow(parent: HTMLElement | null, children: HTMLElement): boolean {
    return parent ? (
      this.hasXAxisOverflow(parent, children) ||
      this.hasYAxisOverflow(parent, children)
    ) : false;
  }

  hasXAxisOverflow(parent: HTMLElement, children: HTMLElement): boolean {
    return children.scrollWidth - parent.clientWidth > 0;
  }

  hasYAxisOverflow(parent: HTMLElement, children: HTMLElement): boolean {
    return children.clientHeight - parent.clientHeight > 0;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.done = false;
    if (this.activateOnResize && this.appFitText) {
      if (this.activateOnInputEvents && this.appFitText) {
        this.setFontSize(this.getStartFontSizeFromHeight());
      } else {
        this.setFontSize(this.getStartFontSizeFromWeight());
      }

      this.ngAfterViewInit();
    }
  }

  @HostListener('input', ['$event'])
  onInputEvents(event: Event) {
    this.done = false;
    if (this.activateOnInputEvents && this.appFitText) {
      this.setFontSize(this.getStartFontSizeFromHeight());
      this.ngAfterViewInit();
    }
  }

  ngOnInit() {
    this.done = false;
    this.el.nativeElement.style.setProperty('will-change', 'content');
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isVisible() && !this.isDone()) {
        if (this.appFitText) {
          if (this.hasOverflow()) {
            if (this.fontSize > this.minFontSize) {
              // iterate only until font size is bigger than minimal value
              this.setFontSize(this.calculateFontSize(this.fontSize, this.speed));
              this.ngAfterViewInit();
            }
          } else {
            this.done = true;
          }
        }
      }
    });
  }

  ngOnChanges(changes: any): void {
    if (changes.modelToWatch) {
      // change of model to watch - call ngAfterViewInit where is implemented logic to change size
      setTimeout(() => {
        this.done = false;
        this.setFontSize(this.maxFontSize);
        this.ngAfterViewInit();
      });
    }
  }

  ngAfterViewChecked() {
    if (this.fontSize >= this.minFontSize) {
      setTimeout(() => {
        this.setFontSize(this.getStartFontSizeFromHeight());
        this.ngAfterViewInit();
      });
    }
  }

  getStartFontSizeFromHeight(): number {
    return this.container
      ? this.container.clientHeight
      : this.el.nativeElement.parentElement?.clientHeight ?? 0;
  }

  isDone(): boolean {
    return this.done;
  }

  isVisible(): boolean {
    return this.getStartFontSizeFromHeight() > 0;
  }

  hasOverflow(): boolean {
    return this.container
      ? this.checkOverflow(this.container, this.el.nativeElement)
      : this.checkOverflow(
        this.el.nativeElement.parentElement,
        this.el.nativeElement
      );
  }

  private getStartFontSizeFromWeight(): number {
    return this.container
      ? this.container.clientWidth
      : this.el.nativeElement.parentElement?.clientWidth ?? 0;
  }
}
