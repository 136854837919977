import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { of } from 'rxjs';

import { UserService } from '@app/user/services/user.service';
import { ProfileActions } from '@app/user/state/actions';
import { AuthActions } from '@app/authentication/state/actions';
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class FileUploadEffects {
  uploadRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.uploadRequest),
      map(action => action.file),
      switchMap(file => {
        return this.service.addImage(file).pipe(
          map(() => AuthActions.reloadUser()),
          catchError(error => {
            let message =
              "Une erreur s'est produite, veuillez réessayer ultérieurement";

            if (
              error.error &&
              error.error.violations &&
              error.error.violations.length
            ) {
              message = error.error.violations[0].message;
            }

            this.toaster.pop('error', message);
            return of(ProfileActions.uploadFailure({ error: message }));
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private service: UserService,
    private toaster: ToasterService
  ) {}
}
