<ng-container
  *ngIf="{
    media: featuredMedia$ | async,
    extraData: featuredMediaExtraData$ | async
  } as data"
>
  <app-featured-media-card
    *ngIf="data.media"
    [featuredMedia]="data.media"
    [featuredMediaExtraData]="data.extraData"
    (needExtraData)="loadFeaturedMediaExtraData()"
  ></app-featured-media-card>
</ng-container>

<div class="media-list light-green-fill">
  <h5>
    <b>
      <app-icon class="big" src="assets/images/media-suggestion.svg"></app-icon>
      Suggestion de médias
    </b>
    {{ suggestedMediasCount$ | async }}
  </h5>

  <app-media-slider [medias]="suggestedMedias$ | async"></app-media-slider>
</div>

<ng-container *ngIf="recommendedMediasCount$ | async as count">
  <div class="media-list light-green-fill" *ngIf="count !== null && count >= 1">
    <h5>
      <b>
        <app-icon class="big" src="assets/images/media-suggestion.svg"></app-icon>
        Recommandations par BNP Paribas
      </b>
      {{ count }}
    </h5>

    <app-media-slider [medias]="recommendedMedias$ | async"></app-media-slider>
  </div>
</ng-container>

<ng-container *ngIf="topMediasCount$ | async as count">
  <div
    class="media-list type-top darkest-green-fill"
    *ngIf="count !== null && count >= 10"
  >
    <h5>
      <b>
        <app-icon src="assets/images/media-top.svg"></app-icon>
        Classement des médias les plus vues ce mois
      </b>
    </h5>

    <app-media-slider
      [medias]="topMedias$ | async"
      displayType="top"
      [itemsMargin]="0"
      [buttonMargin]="0"
      [margin]="0"
    ></app-media-slider>
  </div>
</ng-container>

<div class="media-list light-green-fill">
  <h5>
    <b>
      <app-icon src="assets/images/media-play.svg"></app-icon>
      Reprendre la lecture
    </b>
    {{ continueMediasCount$ | async }}
  </h5>

  <app-media-slider [medias]="continueMedias$ | async"></app-media-slider>
</div>

<div class="media-upload-button justify-content-center align-items-center">
  <app-upload-widget [routerLink]="['/medias/upload']"></app-upload-widget>
</div>
