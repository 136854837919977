import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCategory from '@app/category/state/reducers';
import { map } from 'rxjs/operators';
import { CategoryActions } from '@app/category/state/actions';

@Injectable()
export class CategoryFacade {
  loading$ = this.store.pipe(select(fromCategory.getLoading));
  loaded$ = this.store.pipe(select(fromCategory.getLoaded));

  categories$ = this.store.pipe(
    select(fromCategory.getCategories),
    map(categories => categories.items)
  );
  categoriesCount$ = this.store.pipe(
    select(fromCategory.getCategories),
    map(categories => categories.count)
  );

  category$ = this.store.pipe(select(fromCategory.getCategory));

  constructor(private store: Store<fromCategory.State>) {}

  loadCategories() {
    this.store.dispatch(CategoryActions.loadCategories());
  }

  loadCategory(id: number) {
    this.store.dispatch(CategoryActions.loadCategory({ id }));
  }
}
