import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-badge',
  templateUrl: './media-badge.component.html',
  styleUrls: ['./media-badge.component.scss']
})
export class MediaBadgeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
