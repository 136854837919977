<app-modal #modal>
  <app-modal-header [centered]="true" *ngIf="pMedia && pMedia.id">
    <app-modal-title *ngIf="pMedia.type !== MediaType.PLAYLIST">Modifier un média</app-modal-title>
    <app-modal-title *ngIf="pMedia.type === MediaType.PLAYLIST">Modifier un parcours</app-modal-title>
  </app-modal-header>

  <app-modal-header [centered]="true" *ngIf="pMedia && !pMedia.id">
    <app-modal-title *ngIf="pMedia.type !== MediaType.PLAYLIST">Ajouter un média</app-modal-title>
    <app-modal-title *ngIf="pMedia.type === MediaType.PLAYLIST">Ajouter un parcours</app-modal-title>
  </app-modal-header>

  <app-upload-detail
    *ngIf="pMedia && modal.opened"
    [(media)]="pMedia"
    [canPublish]="canSubmit"
    (cancel)="close()"
    (save)="submit()"
    [thumbnail]="pMedia.imageUrl"
    (thumbnailChange)="thumbnail = $event"
  ></app-upload-detail>
</app-modal>
