import {Injectable} from '@angular/core';
import {HttpService} from '@app/core/services';
import {Observable} from 'rxjs';
import {ApiCollection} from '@app/shared/models/api-collection';
import {map} from 'rxjs/operators';
import {Collection, collectionFromApi} from '@app/shared/models/collection';
import {Notification} from '../models';

@Injectable({providedIn: 'root'})
export class NotificationService {
  public constructor(
    private httpService: HttpService
  ) {
  }

  getNotifications(): Observable<Collection<Notification>> {
    return this.httpService.get<ApiCollection<Notification>>('/user_notifications/me').pipe(
      map(collection => collectionFromApi(collection))
    );
  }

  update(notification: Notification): Observable<Notification> {
    return this.httpService.put<Notification>(`/user_notifications/${notification.id.toString()}`, notification);
  }
}
