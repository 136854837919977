<app-modal>
  <app-modal-header [centered]="true">
    <app-modal-title>
      <app-icon src="assets/images/alert.svg" class="orange-fill"></app-icon>
      Supprimer un contenu du parcours
    </app-modal-title>
  </app-modal-header>

  <span>
    Êtes vous sûr de vouloir supprimer ce média du parcours ? <br/>
    Le média restera visible en dehors du parcours.
  </span>

  <div *ngIf="media" class="media">
    <a class="wrapper" [routerLink]="media | mediaRoute">
      <div class="media-view">
        <app-media-view [media]="media"></app-media-view>
      </div>
    </a>

    <div class="media-info">
      <a
        class="media-title"
        [routerLink]="media | mediaRoute"
        [title]="media.title"
      >
        {{ media.title }}
      </a>

      <div class="media-description">
        <div *nestedEllipsis="true" [innerHTML]="media.description"></div>
      </div>
    </div>
  </div>

  <div class="buttons">
    <app-button-outlined
      class="button-cancel"
      text="Annuler"
      (click)="close()"
    ></app-button-outlined>

    <app-button-outlined
      text="Supprimer"
      (click)="remove()"
    ></app-button-outlined>
  </div>
</app-modal>
