<div
  class="media-title router-link"
  [routerLink]="media | mediaRoute"
  [queryParams]="media | mediaRouteParams"
  [title]="title"
>
  <span class="media-title-text">{{ title }}</span>
  <span class="media-title-pos">{{ position }}</span>
</div>

<div class="wrapper">
  <div class="media-view">
    <app-media-view
      [media]="media"
      class="router-link"
      [routerLink]="media | mediaRoute"
      [queryParams]="media | mediaRouteParams"
    ></app-media-view>
  </div>
</div>

<div class="media-info">
  <div class="media-user">
    <app-media-creator [media]="finalMedia" theme="dark"></app-media-creator>
  </div>
</div>
