import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReloadService {
  constructor(private router: Router) {}

  navigateWithReload(commands: any[], extras?: NavigationExtras) {
    // Force reload the component (it's a bit hacky)
    // TODO: review because it's not supposed to have visual artifacts but it has some
    this.router
      .navigateByUrl('/reload', { skipLocationChange: true })
      .then(() => this.router.navigate(commands));
  }
}
