<app-button-outlined
  text="signaler"
  icon="assets/images/report.svg"
  (click)="openModal()"
></app-button-outlined>

<app-modal [opened]="false" #modal>
  <app-modal-header [centered]="true">
    <app-modal-title>
      Signaler un contenu
      <span>Pour quelle raison jugez-vous ce contenu inapproprié ?</span>
    </app-modal-title>
  </app-modal-header>

  <div class="reasons" *ngIf="reasons$ | async as reasons">
    <div
      class="reason"
      *ngFor="let reason of reasons"
      (click)="selectedReason = reason"
    >
      <div class="reason-message">{{ reason.message }}</div>
      <input type="radio" [checked]="selectedReason?.id === reason.id" />
    </div>
  </div>

  <div class="buttons">
    <app-button-outlined
      class="button-cancel"
      text="Annuler"
      (click)="modal.close()"
    ></app-button-outlined>

    <app-button-outlined
      text="Envoyer"
      (click)="sendReport()"
    ></app-button-outlined>
  </div>
</app-modal>
