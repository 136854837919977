<app-auth-layout title="{{ 'RESET_PASSWORD' | translate }}">
  <ng-container *ngIf="status$ | async as status">
    <ng-container *ngIf="status !== 'mailSended'">
      <div
        *ngIf="status === 'errorTokenNotFound'"
        class="alert alert-danger"
        innerHTML="{{ 'ERROR_TOKEN_NOT_FOUND' | translate }}"
      ></div>
      <div
        *ngIf="status === 'mailAlreadySended'"
        class="alert alert-danger"
        innerHTML="{{ 'RESET_SEND_MAIL_ERROR' | translate }}"
      ></div>
      <app-send-reset-password-form
        (resetPassword)="onReset($event)"
        [processing]="status === 'processing'"
      ></app-send-reset-password-form>
    </ng-container>
    <div
      *ngIf="status === 'mailSended'"
      class="alert alert-success"
      innerHTML="{{
        'RESET_SEND_MAIL_SUCCCESS' | translate: { email: username }
      }}"
    ></div>
    <a [routerLink]="['/login']" class="btn btn-link btn-block" translate>
      CONNECT
    </a>
  </ng-container>
</app-auth-layout>
