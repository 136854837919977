<div class="notifications flex-column">
  <ng-container *ngIf="notifications?.length > 0; else noNotifications">
    <app-notification-item
      *ngFor="let notification of notifications; trackBy: TrackById"
      [notification]="notification"
      (notificationChange)="notificationChange.emit($event)"
      (redirect)="redirect.emit()"
    ></app-notification-item>
  </ng-container>
</div>

<ng-template #noNotifications>
  <div class="empty">Aucune notification</div>
</ng-template>
