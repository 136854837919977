import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/authentication/services';
import { MyContentComponent } from '@app/user-content/containers/my-content/my-content.component';
import { MyMediasComponent } from '@app/user-content/containers/my-medias/my-medias.component';
import { MyPlaylistsComponent } from '@app/user-content/containers/my-playlists/my-playlists.component';
import { MediaType } from '@app/media/models';

const USER_CONTENT_ROUTES: Routes = [
  {
    path: 'my-content',
    component: MyContentComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'medias/:type',
        component: MyMediasComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'playlists',
        component: MyPlaylistsComponent,
        canActivate: [AuthGuard]
      },
      { path: '**', redirectTo: `medias/${MediaType.VIDEO}` }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(USER_CONTENT_ROUTES)],
  exports: [RouterModule]
})
export class UserContentRoutingModule {}
