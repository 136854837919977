import { createAction, props } from '@ngrx/store';
import { User } from '@app/user/models/user.model';
import { Media } from '@app/media/models';
import { Collection } from '@app/shared/models/collection';

const TAG = '[UserChannel/Api]';

export const loadUserChannelSuccess = createAction(
  `${TAG} Load user channel SUCCESS`,
  props<{ user: User }>()
);
export const loadUserChannelFailure = createAction(
  `${TAG} Load user channel FAILURE`,
  props<{ error: any }>()
);

export const loadUserChannelMediasSuccess = createAction(
  `${TAG} Load user channel medias SUCCESS`,
  props<{ medias: Collection<Media> }>()
);
export const loadUserChannelMediasFailure = createAction(
  `${TAG} Load user channel medias FAILURE`,
  props<{ error: any }>()
);
