import { createAction, props } from '@ngrx/store';
import {User} from '../../../user/models/user.model';

export const loadLoggedUser = createAction('[Auth] Load logged user');
export const loadToken = createAction('[Auth] load token');
export const consent = createAction('[Auth] consent',
  props<{ user: User }>());
export const logout = createAction('[Auth] log out');
export const showLogoutModal = createAction(
  '[Auth] show log out modal confirm'
);
export const reloadUser = createAction('[Auth] Reload user');
export const loadRefreshToken = createAction(
  '[Auth] Refresh token',
  props<{ refreshToken: string }>()
);
