import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UploadService } from '@app/upload/services';
import { UploadActions, UploadApiActions } from '@app/upload/state/actions';

@Injectable()
export class UploadEffects {
  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadActions.uploadFile),
      exhaustMap(({ upload }) => {
        return this.uploadService.uploadFile(upload).pipe(
          map(updatedUpload =>
            updatedUpload.response
              ? UploadApiActions.uploadFileSuccess({ upload: updatedUpload })
              : UploadApiActions.uploadFileProgress({ upload: updatedUpload })
          ),
          catchError(error => {
            return of(
              UploadApiActions.uploadFileFailure({
                upload: {
                  ...upload,
                  error
                }
              })
            );
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private uploadService: UploadService
  ) {}
}
