import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Page } from '@app/page/models/page';

@Component({
  selector: 'app-first-connection-text',
  templateUrl: './first-connection-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstConnectionTextComponent implements OnInit {
  @Input() page: Page | null;

  constructor() {}

  ngOnInit() {}
}
