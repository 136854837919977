import { createAction, props } from '@ngrx/store';
import { MediaSearchModel, MediaType } from '@app/media/models';

const TAG = '[Media/View]';

export const loadMedias = createAction(`${TAG} Load medias`);
export const loadSuggestedMedias = createAction(`${TAG} Load suggest medias`);
export const loadTopMedias = createAction(`${TAG} Load top medias`);
export const loadContinueMedias = createAction(`${TAG} Load continue medias`);
export const loadRecommendedMedias = createAction(`${TAG} Load recommended medias`);
export const loadMyMedias = createAction(
  `${TAG} Load my medias`,
  props<{ search: MediaSearchModel }>()
);
export const loadMediasWithFilters = createAction(
  `${TAG} Load medias with filters`
);
export const loadMedia = createAction(
  `${TAG} Load media`,
  props<{ id: number; reset: boolean }>()
);
export const loadMediaExtraData = createAction(
  `${TAG} Load media extra data`,
  props<{ id: number; reset: boolean; highlightId?: number }>()
);
export const loadFeaturedMedias = createAction(`${TAG} Load featured medias`);
export const loadFeatureMediaExtraData = createAction(
  `${TAG} Load feature media extra data`,
  props<{ id: number }>()
);
export const loadAssociatedPlaylists = createAction(
  `${TAG} Load associated playlists`,
  props<{ id: number }>()
);
export const searchMedias = createAction(
  `${TAG} Search medias`,
  props<{ search: MediaSearchModel }>()
);
export const likeMedia = createAction(
  `${TAG} Like media`,
  props<{ id: number }>()
);
export const unlikeMedia = createAction(
  `${TAG} Unlike media`,
  props<{ id: number }>()
);
export const saveMediaVideoPosition = createAction(
  `${TAG} Save media video position`,
  props<{ id: number, videoPosition: number }>()
);
