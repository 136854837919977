import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services';
import { Observable, of, throwError } from 'rxjs';
import { ApiCollection } from '@app/shared/models/api-collection';
import { Collection, collectionFromApi } from '@app/shared/models/collection';
import { map } from 'rxjs/operators';
import { Comment } from '@app/comment/models/comment.model';
import { Media } from '@app/media/models';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  public constructor(private httpService: HttpService) {}

  public loadComments(mediaIri: string): Observable<Collection<Comment>> {
    return this.httpService
      .get<ApiCollection<Comment>>('/comments', {
        media: mediaIri.toString(),
        'exists[parent]': 'false'
      })
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public addComment(comment: Comment): Observable<Comment> {
    if (!comment.media) {
      return throwError('Post:Comment Should have a media');
    }

    return this.httpService.post<Comment>(`/comments`, {
      author: comment.author.id,
      content: comment.content,
      media: (comment.media as Media).id,
      parent: comment.parent ? comment.parent.id : undefined
    });
  }

  public likeComment(id: number): Observable<{ likeCount: number }> {
    return this.httpService.put<{ likeCount: number }>(
      `/comments/${id}/user_liked`,
      {}
    );
  }

  public unlikeComment(id: number): Observable<{ likeCount: number }> {
    return this.httpService.put<{ likeCount: number }>(
      `/comments/${id}/user_unliked`,
      {}
    );
  }
}
