import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromComment from '@app/comment/state/reducers/comment.reducer';

export interface State extends fromRoot.State {
  comment: fromComment.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    comment: fromComment.reducer
  })(state, action);
}

export const selectCommentState = createFeatureSelector<State>('comment');

export const getCommentState = createSelector(
  selectCommentState,
  state => state.comment
);

export const getAddedComment = createSelector(
  getCommentState,
  fromComment.getAddedComment
);

export const getComments = createSelector(
  getCommentState,
  fromComment.getComments
);

export const getLoading = createSelector(
  getCommentState,
  fromComment.getLoading
);

export const getLoaded = createSelector(
  getCommentState,
  fromComment.getLoaded
);
