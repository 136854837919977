import { Pipe, PipeTransform } from '@angular/core';
import { Upload } from '@app/upload/models/upload.model';
import { FileSizePipe } from '@app/shared/pipes/file-size.pipe';
import { DurationPipe } from '@app/shared/pipes/duration.pipe';

@Pipe({
  name: 'uploadProgress'
})
export class UploadProgressPipe implements PipeTransform {
  constructor(
    public fileSizePipe: FileSizePipe,
    public durationPipe: DurationPipe
  ) {}

  transform(upload: Upload): any {
    const sizeUnit = this.fileSizePipe.getAutoUnit(upload.file.size);
    const progress =
      upload.progress && upload.progress.progress
        ? upload.progress.progress
        : 0;
    const uploaded = upload.file.size * (progress / 100);

    const startDate =
      upload.progress && upload.progress.startedAt
        ? upload.progress.startedAt
        : new Date();
    const timeElapsed = (Date.now() - startDate.getTime()) / 1000;
    const timeTotal = (timeElapsed * 100) / progress;
    const timeRemaining = timeTotal - timeElapsed;

    const currentSizeWithUnit = this.fileSizePipe.transform(uploaded, sizeUnit);
    const totalSizeWithUnit = this.fileSizePipe.transform(
      upload.file.size,
      sizeUnit
    );
    const timeRemainingWithUnit = this.durationPipe.transform(timeRemaining);

    return `${currentSizeWithUnit} / ${totalSizeWithUnit}, ${timeRemainingWithUnit} restantes`;
  }
}
