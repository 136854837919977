import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ReportActions, ReportApiActions } from '@app/report/state/actions';
import { ReportService } from '@app/report/services';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import { User } from '../../../user/models/user.model';
import { Iri } from '@app/shared/models/api-entity';

@Injectable()
export class ReportEffects {
  loadReportReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.loadReportReasons),
      switchMap(() => {
        return this.service.loadReportReasons().pipe(
          map(reasons =>
            ReportApiActions.loadReportReasonsSuccess({ reasons })
          ),
          catchError(error => {
            return of(
              ReportApiActions.loadReportReasonsFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  addComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.addReport),
      withLatestFrom(this.auth.loggedUser$),
      filter(([_, user]) => user !== null),
      switchMap(([{ report }, user]) => {
        return this.service
          .addReport({
            ...report,
            reporter: user as User
          })
          .pipe(
            map(addedReport =>
              ReportApiActions.addReportSuccess({ report: addedReport })
            ),
            catchError(error => {
              return of(
                ReportApiActions.addReportFailure({
                  error: error.message
                })
              );
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private service: ReportService,
    private auth: AuthFacade
  ) {}
}
