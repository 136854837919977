import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Media, MediaExtraData } from '@app/media/models';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'app-media-action',
  templateUrl: './media-action.component.html',
  styleUrls: ['./media-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaActionComponent implements OnInit {
  @Input()
  media: Media;

  @Input()
  extraData: MediaExtraData;

  @Input()
  user: User;

  @Output()
  likeMedia = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}
}
