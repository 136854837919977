import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../models/user.model';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FirstConnectionService } from '@app/authentication/services/first-connection.service';

@Component({
  selector: 'app-profile-card',
  templateUrl: 'profile-card.component.html',
  styleUrls: ['profile-card.component.scss']
})
export class ProfileCardComponent {
  faUser = faUser;

  @Input() user: User;
  @Output() uploadFile = new EventEmitter<any>();

  constructor(public service: FirstConnectionService) {}

  onUploadFile(file: File) {
    this.uploadFile.emit(file);
  }
}
