<div
  class="backdrop"
  *ngIf="opened"
  @inOutAnimation
  (click)="backdropClick($event)"
>
  <div class="wrapper">
    <div class="popin">
      <div class="header">
        <ng-content select="app-modal-header"></ng-content>
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
