import { createReducer, on } from '@ngrx/store';
import { Collection, defaultCollection } from '@app/shared/models/collection';
import { Report } from '@app/report/models/report.model';
import { ReportActions, ReportApiActions } from '@app/report/state/actions';
import { ReportReason } from '@app/report/models/report-reason.model';

export interface State {
  reasons: Collection<ReportReason>;
  addedReport?: Report;
  loading: boolean;
  loaded: boolean;
}

export const INITIAL_STATE: State = {
  reasons: defaultCollection<ReportReason>(),
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(ReportActions.loadReportReasons, state => ({
    ...state,
    reasons: defaultCollection<ReportReason>(),
    loading: true,
    loaded: false
  })),
  on(ReportActions.addReport, state => ({
    ...state,
    addedReport: undefined,
    loading: true,
    loaded: false
  })),
  on(ReportApiActions.loadReportReasonsSuccess, (state, { reasons }) => ({
    ...state,
    reasons,
    loading: false,
    loaded: true
  })),
  on(ReportApiActions.addReportSuccess, (state, { report }) => ({
    ...state,
    addedReport: report,
    loading: false,
    loaded: true
  })),
  on(ReportApiActions.loadReportReasonsFailure, state => ({
    ...state,
    comments: defaultCollection<Report>(),
    loading: false,
    loaded: false
  })),
  on(ReportApiActions.addReportFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  }))
);

export const getReportReasons = (state: State) => state.reasons;
export const getAddedReport = (state: State) => state.addedReport;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
