import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../models/user.model';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-image-profile',
  templateUrl: 'image-profile.component.html',
  styleUrls: ['image-profile.component.scss']
})
export class ImageProfileComponent implements OnInit {
  maxSize = 2000000; // 2Mo
  minWidth = 300;
  minHeight = 300;

  @Input() user: User;
  @Output() uploadFile = new EventEmitter<File>();

  constructor(private toaster: ToasterService) {}

  get picture() {
    return this.user.avatarUrl
      ? this.user.avatarUrl
      : '/assets/images/placeholder_profile.png';
  }

  ngOnInit() {}

  onUploadFile(event: any) {
    const files: FileList = event.target.files;
    const file = files.item(0) as File;
    event.srcElement.value = null;

    if (file.size > this.maxSize) {
      this.toaster.pop(
        'error',
        `Le fichier est trop volumineux et ne doit pas dépasser les ${this
          .maxSize / 1000000} Mo`
      );
      return;
    }

    const image = new Image();
    const imageUrl = URL.createObjectURL(file);
    image.onload = () => {
      if (
        image.naturalWidth < this.minWidth ||
        image.naturalHeight < this.minHeight
      ) {
        this.toaster.pop(
          'error',
          "L'image est trop petite et devrait faire au moins 300x300 px"
        );
      }

      this.uploadFile.emit(file);
      URL.revokeObjectURL(imageUrl);
    };
    image.src = imageUrl;
  }
}
