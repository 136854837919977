import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromReport from '@app/report/state/reducers/comment.reducer';

export interface State extends fromRoot.State {
  report: fromReport.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    report: fromReport.reducer
  })(state, action);
}

export const selectReportState = createFeatureSelector<State>('report');

export const getReportState = createSelector(
  selectReportState,
  state => state.report
);

export const getReportReasons = createSelector(
  getReportState,
  fromReport.getReportReasons
);

export const getAddedReport = createSelector(
  getReportState,
  fromReport.getAddedReport
);

export const getLoading = createSelector(getReportState, fromReport.getLoading);

export const getLoaded = createSelector(getReportState, fromReport.getLoaded);
