import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/authentication/services';
import {CategoryComponent} from '@app/category-media/containers/category/category.component';

const CATEGORY_ROUTES: Routes = [
  {
    path: 'category/:id',
    component: CategoryComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(CATEGORY_ROUTES)],
  exports: [RouterModule]
})
export class CategoryMediaRoutingModule {}
