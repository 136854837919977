<div
  class="opacity-filter"
  [class.visible]="opened"
  (click)="onToggleMenu()"
></div>

<div class="sliding-menu" [class.visible]="opened">
  <app-links-bar *ngIf="loggedUser && loggedUser.preHomeData"></app-links-bar>

  <a
    class="search-wrapper"
    [routerLink]="['/research']"
    (click)="onToggleMenu()"
  >
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      enable-background="new 0 0 512 512"
    >
      <path
        d="M495,466.2L377.2,348.4c29.2-35.6,46.8-81.2,46.8-130.9C424,103.5,331.5,11,217.5,11C103.4,11,11,103.5,11,217.5   S103.4,424,217.5,424c49.7,0,95.2-17.5,130.8-46.7L466.1,495c8,8,20.9,8,28.9,0C503,487.1,503,474.1,495,466.2z M217.5,382.9   C126.2,382.9,52,308.7,52,217.5S126.2,52,217.5,52C308.7,52,383,126.3,383,217.5S308.7,382.9,217.5,382.9z"
      />
    </svg>
    <span>Rechercher un support</span>
  </a>

  <a
    *ngIf="loggedUser"
    [routerLink]="['/profile']"
    class="profile-wrapper"
    (click)="onToggleMenu()"
  >
    <app-profile-picture></app-profile-picture>
    <div class="infos-wrapper">
      <span class="name">
        {{ loggedUser.firstname }} {{ loggedUser.lastname }}
      </span>
      <br />
      <span class="territory-job">
        {{ loggedUser.affiliation ? loggedUser.affiliation.name : '' }}
      </span>
    </div>
  </a>
  <div class="regular-links-wrapper">
    <a
      *ngIf="loggedUser && loggedUser.haveEmployees"
      class="regular-link-item"
      [routerLink]="['/radar']"
      (click)="onToggleMenu()"
    >
      Radar Manager
    </a>
    <a
      class="regular-link-item"
      [routerLink]="['/page/a-propos']"
      (click)="onToggleMenu()"
    >
      À propos
    </a>
    <a
      class="regular-link-item"
      [routerLink]="['/page/faq']"
      (click)="onToggleMenu()"
    >
      Faq
    </a>
    <a
      class="regular-link-item"
      [routerLink]="['/page/mentions-legales']"
      (click)="onToggleMenu()"
    >
      Mentions légales
    </a>

    <button (click)="onLogout()" class="logout">Déconnexion</button>
  </div>

  <div class="all-logos">
    <img src="/assets/images/bnp_logo.png" alt="Logo BNP Paribas" />
    <img src="/assets/images/logo-my-campus.png?270224" alt="Logo My Campus" />
  </div>
</div>
