import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromChannel from './reducers';
import { ChannelActions } from './actions';
import { UserFollow } from '../../user/models/user-follow.model';
import { map } from 'rxjs/operators';
import { User } from '../../user/models/user.model';

@Injectable()
export class ChannelFacade {
  followedUsers$ = this.store.pipe(
    select(fromChannel.getFollowedUsers),
    map(collection => collection.items)
  );
  followedUsersCount$ = this.store.pipe(
    select(fromChannel.getFollowedUsers),
    map(collection => collection.count)
  );
  followedUser$ = this.store.pipe(select(fromChannel.getFollowedUser));
  unfollowedUser$ = this.store.pipe(select(fromChannel.getUnfollowedUser));
  loading$ = this.store.pipe(select(fromChannel.getLoading));
  loaded$ = this.store.pipe(select(fromChannel.getLoaded));

  constructor(private store: Store<any>) {}

  selectFollowedUser(userId: number) {
    return this.followedUsers$.pipe(
      map(follows =>
        follows.find(follow => (follow.followed as User)?.id === userId)
      )
    );
  }

  loadFollowedUser() {
    this.store.dispatch(ChannelActions.loadFollowedUser());
  }

  follow(userId: number) {
    this.store.dispatch(ChannelActions.follow({ userId }));
  }

  unfollow(follow: UserFollow) {
    this.store.dispatch(ChannelActions.unfollow({ follow }));
  }
}
