import { ApiEntity } from '@app/shared/models/api-entity';

export interface Tag extends ApiEntity {
  id?: number;
  name: string;
}

export const defaultTag: () => Tag = () => ({
  name: ''
});
