import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '@app/core/services/http.service';
import { Page } from '@app/page/models/page';
import { ApiCollection } from '@app/shared/models/api-collection';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private path = '/pages';

  public constructor(private httpService: HttpService) {}

  public loadPage(page: string): Observable<Page[]> {
    const params = new HttpParams().append('devKey', page);
    return this.httpService
      .get<ApiCollection<Page>>(`${this.path}`, params)
      .pipe(map(response => response['hydra:member']));
  }

  public sendMessage(message: string): Observable<any> {
    return this.httpService.post<any>('/users/me/send_contact_message', {
      message
    });
  }
}
