import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

type unit = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';

const translationMap: Record<string, Record<unit, string>> = {
  en: { B: 'B', KB: 'KB', MB: 'MB', GB: 'GB', TB: 'TB', PB: 'PB' },
  fr: { B: 'o', KB: 'Ko', MB: 'Mo', GB: 'Go', TB: 'To', PB: 'Po' }
};

const precisionMap: Record<unit, number> = {
  B: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2
};

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  private readonly units: unit[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(
    bytes: number = 0,
    // tslint:disable-next-line:no-shadowed-variable
    unit: unit | 'auto' = 'auto',
    precision: number | string = 'auto'
  ): any {
    bytes = parseFloat(String(bytes));

    if (isNaN(bytes) || !isFinite(bytes)) {
      return '??';
    }

    if (unit === 'auto') {
      unit = this.getAutoUnit(bytes);
    }

    const unitIndex = this.units.indexOf(unit);
    bytes /= Math.pow(1024, unitIndex);

    const language = this.locale.substr(0, 2);
    let displayUnit: string;
    if (translationMap.hasOwnProperty(language)) {
      displayUnit = translationMap[language][unit];
    } else {
      displayUnit = translationMap.en[unit];
    }

    if (typeof precision === 'number') {
      return `${bytes.toFixed(+precision)} ${displayUnit}`;
    }

    return `${bytes.toFixed(precisionMap[unit])} ${displayUnit}`;
  }

  getAutoUnit(bytes: number = 0) {
    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    return this.units[unitIndex];
  }
}
