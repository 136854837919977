import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsInputComponent } from './containers/tags-input/tags-input.component';
import { TagBadgeComponent } from './components/tag-badge/tag-badge.component';
import { IconsModule } from '@app/shared/icons';
import { TagFacade } from '@app/tag/state/tag-facade.service';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@app/tag/state/reducers';
import { TagEffects } from '@app/tag/state/effects/tag-effects.service';

@NgModule({
  declarations: [TagsInputComponent, TagBadgeComponent],
  imports: [
    CommonModule,
    IconsModule,
    FormsModule,
    EffectsModule.forFeature([TagEffects]),
    StoreModule.forFeature('tag', reducers)
  ],
  exports: [TagsInputComponent],
  providers: [TagFacade]
})
export class TagModule {}
