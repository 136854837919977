export enum ApiVideoEvent {
  // The player is ready to play
  READY = 'ready',

  // The video started to play for the first time
  FIRST_PLAY = 'firstplay',
  // The video started to play (for the first time or after having been paused)
  PLAY = 'play',

  // The video has been paused
  PAUSE = 'pause',

  // The playback as reached the ended of the video
  ENDED = 'ended',

  // An error occured
  ERROR = 'error',

  // The player goes to (or goes back from) full screen
  FULLSCREEN_CHANGE = 'fullscreenchange',

  // The player size has changed
  PLAYER_RESIZE = 'playerresize',

  // The player is seeking
  SEEKING = 'seeking',
}
