import {Action, combineReducers, createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromNotification from '@app/notification/state/reducers/notification.reducer';

export interface State extends fromRoot.State {
  notification: fromNotification.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    notification: fromNotification.reducer
  })(state, action);
}

export const selectNotificationState = createFeatureSelector<State>('notification');

export const getNotificationState = createSelector(
  selectNotificationState,
  state => state.notification
);

export const getNotifications = createSelector(
  getNotificationState,
  fromNotification.getNotifications
);

export const getLoading = createSelector(
  getNotificationState,
  fromNotification.getLoading
);

export const getLoaded = createSelector(
  getNotificationState,
  fromNotification.getLoaded
);
