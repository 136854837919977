import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/authentication/services';
import { HistoryComponent } from '@app/library/containers/history/history.component';
import { LikedComponent } from './containers/liked/liked.component';
import { ResumeComponent } from './containers/resume/resume.component';

const ROUTES: Routes = [
  {
    path: 'library/history',
    component: HistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'library/liked',
    component: LikedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'library/resume',
    component: ResumeComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class LibraryRoutingModule {}
