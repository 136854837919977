import { createReducer, on } from '@ngrx/store';
import { ExternalLink } from '@app/links-bar/models/external-link.model';
import {
  ExternalLinkActions,
  ExternalLinkApiActions
} from '@app/links-bar/state/actions';

export interface State {
  externalLinks: ExternalLink[];
  loading: boolean;
  loaded: boolean;
}

export const INITIAL_STATE: State = {
  externalLinks: [],
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(ExternalLinkActions.loadExternalLinks, state => ({
    ...state,
    loaded: false,
    loading: true
  })),
  on(
    ExternalLinkApiActions.loadExternalLinksSuccess,
    (state, { externalLinks }) => ({
      ...state,
      externalLinks,
      loaded: false,
      loading: true
    })
  )
);

export const getExternalLinks = (state: State) => state.externalLinks;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
