import {NgModule} from '@angular/core';

import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {NgSelectModule} from '@ng-select/ng-select';

import {SharedModule} from '@app/shared/shared.module';
import {AuthRoutingModule} from '@app/authentication/auth-routing.module';
import {AuthEffects, FirstConnectionEffects} from '@app/authentication/state/effects';
import {reducers} from '@app/authentication/state/reducers';
import {AuthFacade} from '@app/authentication/state/auth.facade';

import {
  FirstConnectionComponent,
  FirstConnectionPageComponent,
  LoginViewComponent,
  ResetPasswordViewComponent,
  SendResetPasswordViewComponent
} from '@app/authentication/containers';

import {
  AuthLayoutComponent,
  FirstConnectionFormComponent,
  LoginFormComponent,
  ResetPasswordFormComponent,
  SendResetPasswordFormComponent
} from '@app/authentication/components';
import {FirstConnectionTextComponent} from './components/first-connection-text/first-connection-text.component';
import {
  FirstConnectionWelcomeComponent
} from './components/first-connection-welcome/first-connection-welcome.component';
import {AuthLayoutTextComponent} from '@app/authentication/components/auth-layout-text/auth-layout-text.component';
import {RefreshTokenComponent} from './containers/refresh-token/refresh-token.component';
import {
  ProfileConfirmationComponent
} from '@app/authentication/components/profile-confirmation/profile-confirmation.component';
import {ReloadComponent} from './containers/reload/reload.component';
import {ConsentModalComponent} from './components/consent-modal/consent-modal.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    AuthRoutingModule,
    SharedModule,
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects, FirstConnectionEffects]),
    NgSelectModule,
    FormsModule
  ],
  declarations: [
    AuthLayoutComponent,
    AuthLayoutTextComponent,
    FirstConnectionComponent,
    FirstConnectionFormComponent,
    FirstConnectionTextComponent,
    FirstConnectionWelcomeComponent,
    LoginViewComponent,
    LoginFormComponent,
    SendResetPasswordViewComponent,
    SendResetPasswordFormComponent,
    ResetPasswordViewComponent,
    ResetPasswordFormComponent,
    FirstConnectionPageComponent,
    RefreshTokenComponent,
    ProfileConfirmationComponent,
    ReloadComponent,
    ConsentModalComponent
  ],
  exports: [FirstConnectionComponent, ConsentModalComponent],
  providers: [AuthFacade],
  entryComponents: [ProfileConfirmationComponent]
})
export class AuthModule {
}
