import { createReducer, on } from '@ngrx/store';

import { TagActions } from '@app/tag/state/actions';
import { Upload } from '@app/upload/models/upload.model';
import { UploadActions, UploadApiActions } from '@app/upload/state/actions';

export interface State {
  uploads: Upload[];
}

export const INITIAL_STATE: State = {
  uploads: []
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(UploadActions.uploadFile, (state, { upload }) => ({
    ...state,
    uploads: [...state.uploads, upload]
  })),
  on(UploadApiActions.uploadFileProgress, (state, { upload }) => ({
    ...state,
    uploads: [...state.uploads.filter(up => up.id !== upload.id), upload]
  })),
  on(UploadApiActions.uploadFileSuccess, (state, { upload }) => ({
    ...state,
    uploads: [...state.uploads.filter(up => up.id !== upload.id), upload]
  })),
  on(UploadApiActions.uploadFileFailure, (state, { upload }) => ({
    ...state,
    uploads: [...state.uploads.filter(up => up.id !== upload.id), upload]
  }))
);

export const getUploads = (state: State) => state.uploads;
