import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';
import {Media} from '../../models';
import {MediaService} from '../../services';
import {forkJoin} from 'rxjs';
import {MediaTileComponent} from '../media-tile/media-tile.component';

interface PlaylistData {
  playlist: Media;
  medias: Media[];
}

@Component({
  selector: 'app-playlist-media-list',
  templateUrl: './playlist-media.component.html',
  styleUrls: ['./playlist-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaylistMediaComponent implements OnInit {
  pPlaylists: PlaylistData[] = [];

  @Input()
  media?: Media;

  constructor(
    private mediaService: MediaService,
    private change: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
  }

  @Input()
  set playlists(playlists: Media[] | null | undefined) {
    playlists = playlists ?? [];

    forkJoin(playlists.map(playlist => this.mediaService.getMedia(playlist.id))).subscribe(pls => {
      this.pPlaylists = pls.map(playlist => ({
        playlist,
        medias: playlist.playlistItems.map(item => item.mediaItem)
      }));

      this.change.markForCheck();
    });
  }

  get uniquePlaylist() {
    return this.pPlaylists.length === 1 ? this.pPlaylists[0] : undefined;
  }

  get isUniquePlaylist() {
    return this.uniquePlaylist !== undefined;
  }

  ngOnInit(): void {
  }

  trackById(index: number, item: PlaylistData) {
    return item.playlist.id;
  }

  scrollToMedia(comp: MediaTileComponent) {
    if (!this.elementRef) {
      return;
    }

    setTimeout(() => {
      this.elementRef.nativeElement.scrollTop = comp.positionTop - 50;
    });
  }
}
