import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Category } from '@app/category/models';

@Component({
  selector: 'app-category-icon',
  templateUrl: './category-icon.component.html',
  styleUrls: ['./category-icon.component.scss']
})
export class CategoryIconComponent implements OnInit {
  @HostBinding('class.selected')
  selected = false;

  constructor() {}

  ngOnInit(): void {}
}
