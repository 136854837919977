import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {NotificationActions, NotificationApiActions} from '@app/notification/state/actions';
import {NotificationService} from '../../services/notification.service';

@Injectable()
export class NotificationEffects {
  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.loadNotifications),
      switchMap(() => {
        return this.service.getNotifications().pipe(
          map(notifications =>
            NotificationApiActions.loadNotificationsSuccess({notifications})
          ),
          catchError(error => {
            return of(
              NotificationApiActions.loadNotificationsFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.updateNotification),
      switchMap(({notification}) => {
        return this.service.update(notification).pipe(
          map(updated => NotificationApiActions.updateNotificationSuccess({notification: updated})),
          catchError(error => {
            return of(
              NotificationApiActions.updateNotificationFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private service: NotificationService,
  ) {
  }
}
