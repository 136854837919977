import { props, createAction } from '@ngrx/store';
import { UserFollow } from '../../../user/models/user-follow.model';

const TAG = '[Channel/View]';

export const loadFollowedUser = createAction(`${TAG} Load followed users`);
export const follow = createAction(
  `${TAG} follow`,
  props<{ userId: number }>()
);
export const unfollow = createAction(
  `${TAG} unfollow`,
  props<{ follow: UserFollow }>()
);
