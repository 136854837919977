import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ActionsItemsModule } from '@app/shared/actions-item';
import { PageInnerModule } from '@app/shared/page-inner';
import { IconsModule } from '@app/shared/icons';
import { FormModule } from '@app/shared/form';
import { ValidationActionModule } from '@app/shared/validation-action';
import { ModalModule } from '@app/shared/modal';
import { ButtonModule } from '@app/shared/button';
import { LikeButtonComponent } from './like-button/like-button.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { ProfileWidgetComponent } from '@app/shared/profile-widget/profile-widget.component';
import { RouterModule } from '@angular/router';
import { StandaloneBarComponent } from './standalone-bar/standalone-bar.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { SelectPositionComponent } from './select-position/select-position.component';
import { FitTextDirective } from './fit-text/fit-text.directive';

const MODULES_EXPORT = [
  CommonModule,
  ReactiveFormsModule,
  TranslateModule,
  ActionsItemsModule,
  PageInnerModule,
  IconsModule,
  FormModule,
  ValidationActionModule,
  ModalModule,
  ButtonModule
];

@NgModule({
  imports: [CommonModule, RouterModule, IconsModule],
  exports: [
    ...MODULES_EXPORT,
    LikeButtonComponent,
    ProfilePictureComponent,
    ProfileWidgetComponent,
    StandaloneBarComponent,
    FileSizePipe,
    SelectPositionComponent,
    FitTextDirective
  ],
  declarations: [
    LikeButtonComponent,
    ProfilePictureComponent,
    ProfileWidgetComponent,
    StandaloneBarComponent,
    FileSizePipe,
    DurationPipe,
    SelectPositionComponent,
    FitTextDirective
  ]
})
export class SharedModule {}
