import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromComment from '@app/comment/state/reducers';
import { map } from 'rxjs/operators';
import { CommentActions } from '@app/comment/state/actions';
import { Comment } from '@app/comment/models/comment.model';

@Injectable()
export class CommentFacade {
  loading$ = this.store.pipe(select(fromComment.getLoading));
  loaded$ = this.store.pipe(select(fromComment.getLoaded));
  addedComment$ = this.store.pipe(select(fromComment.getAddedComment));
  comments$ = this.store.pipe(
    select(fromComment.getComments),
    map(comments => comments.items)
  );
  commentsCount$ = this.store.pipe(
    select(fromComment.getComments),
    map(comments => comments.count)
  );

  constructor(private store: Store<fromComment.State>) {}

  loadComments(mediaIri: string) {
    this.store.dispatch(CommentActions.loadComments({ mediaIri }));
  }

  addComment(comment: Comment) {
    this.store.dispatch(CommentActions.addComment({ comment }));
  }

  likeComment(comment: Comment) {
    this.store.dispatch(CommentActions.likeComment({ comment }));
  }

  unlikeComment(comment: Comment) {
    this.store.dispatch(CommentActions.unlikeComment({ comment }));
  }
}
