import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';

import { ContactActions } from '@app/page/state/actions';

import { PageService } from '@app/page/services';

@Injectable()
export class ContactEffects {
  public constructor(
    private actions$: Actions,
    private pageService: PageService
  ) {}
  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.sendMessage),
      map(action => action.message),
      exhaustMap(message => {
        return this.pageService.sendMessage(message).pipe(
          map(() => ContactActions.sendMessageSuccess()),
          catchError(error => {
            return of(ContactActions.sendMessageFailure({ error }));
          })
        );
      })
    )
  );
}
