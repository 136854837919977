import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Media} from '@app/media/models';
import {animate, style, transition, trigger} from '@angular/animations';
import {MediaTileComponent} from '../media-tile/media-tile.component';

@Component({
  selector: 'app-media-vertical-list',
  templateUrl: './media-vertical-list.component.html',
  styleUrls: ['./media-vertical-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('Appear', [
      transition(':enter', [
        style({opacity: 0}),
        animate(100, style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate(100, style({opacity: 0}))
      ])
    ])
  ]
})
export class MediaVerticalListComponent implements AfterViewInit {
  @ViewChildren(MediaTileComponent)
  mediaComponents: QueryList<MediaTileComponent>;

  @Input()
  medias: Media[];

  @Input()
  highlight?: Media;

  @Input()
  playlist?: Media;

  @Input()
  description = true;

  @Input()
  cardLink = false;

  @Input()
  size: 'normal' | 'small' = 'normal';

  @Output()
  highlightComponent = new EventEmitter<MediaTileComponent>();

  ngAfterViewInit(): void {
    if (!this.highlight) {
      return;
    }

    this.mediaComponents.forEach(comp => {
      if (comp.media.id !== this.highlight?.id) {
        return;
      }

      this.highlightComponent.emit(comp);
    });
  }
}
