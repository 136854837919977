import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user.model';
import { capitalizeFirstLetter } from '@app/shared/utils/string-functions';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {
  transform(value: User): any {
    return (
      capitalizeFirstLetter(value.firstname ?? '') +
      ' ' +
      capitalizeFirstLetter(value.lastname ?? '')
    );
  }
}
