import { Component, OnInit } from '@angular/core';
import { MediaFacade } from '@app/media/state/media.facade';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.scss']
})
export class MediasComponent implements OnInit {
  featuredMedia$ = this.mediaFacade.featuredMedias$.pipe(
    map(medias => (medias.length ? medias[0] : undefined))
  );
  featuredMediaExtraData$ = this.mediaFacade.featuredMediaExtraData$;

  suggestedMedias$ = this.mediaFacade.suggestedMedias$;
  suggestedMediasCount$ = this.mediaFacade.suggestedMediasCount$;

  recommendedMedias$ = this.mediaFacade.recommendedMedias$;
  recommendedMediasCount$ = this.mediaFacade.recommendedMediasCount$;

  topMedias$ = this.mediaFacade.topMedias$;
  topMediasCount$ = this.mediaFacade.topMediasCount$;

  continueMedias$ = this.mediaFacade.continueMedias$;
  continueMediasCount$ = this.mediaFacade.continueMediasCount$;

  constructor(private mediaFacade: MediaFacade) {}

  ngOnInit() {
    this.mediaFacade.loadFeaturedMedias();
    this.mediaFacade.loadSuggestedMedias();
    this.mediaFacade.loadTopMedias();
    this.mediaFacade.loadContinueMedias();
    this.mediaFacade.loadRecommendedMedias();
  }

  loadFeaturedMediaExtraData() {
    this.featuredMedia$.pipe(first()).subscribe(featured => {
      if (featured !== undefined) {
        this.mediaFacade.loadFeatureMediaExtraData(featured.media.id);
      }
    });
  }
}
