import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MediaSearchConfig, MediaSearchService} from '../../../media/services/media.search.service';
import {Media, MediaSearchOrder, MediaType} from '../../../media/models';
import {Collection, defaultCollection} from '../../../shared/models/collection';
import {first, map, tap, withLatestFrom} from 'rxjs/operators';
import {MediaFacade} from '../../../media/state/media.facade';
import {ActivatedRoute} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-my-playlists',
  templateUrl: './my-playlists.component.html',
  styleUrls: ['./my-playlists.component.scss']
})
export class MyPlaylistsComponent implements OnInit {
  config$ = new BehaviorSubject<MediaSearchConfig>({
    availableOrders: [MediaSearchOrder.CREATED_AT]
  });

  medias: Collection<Media> = defaultCollection<Media>();
  loading$ = this.mediaFacade.loading$;
  hasNextPage = true;

  constructor(
    private mediaFacade: MediaFacade,
    private route: ActivatedRoute,
    private mediaSearchService: MediaSearchService,
    private change: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.mediaSearchService.search$
      .pipe(untilDestroyed(this))
      .subscribe(search => {
        this.mediaFacade.loadMyMedias({
          ...search,
          mediaTypes: [MediaType.PLAYLIST]
        });
      });

    this.config$
      .pipe(untilDestroyed(this))
      .subscribe(config => this.mediaSearchService.changeConfig(config));

    this.mediaFacade.myMedias$
      .pipe(
        untilDestroyed(this),
        // Ensure playlistItems are following each others (in case of deletion it can create absent position)
        map(medias => medias.map(
          playlist => ({
            ...playlist,
            playlistItems: [...playlist.playlistItems]
              .sort((a, b) => a.position - b.position)
              .map((item, index) => ({...item, position: index + 1}))
          })
        )),
        withLatestFrom(
          this.mediaFacade.myMediasCount$
        )
      )
      .subscribe(([medias, count]) => {
        const search = this.mediaSearchService.search$.getValue();

        this.hasNextPage = medias.length >= 10;

        this.medias = {
          count: this.medias.count + count,
          items: search.page === 1 ? medias : [...this.medias.items, ...medias]
        };
        this.change.markForCheck();
      });
  }

  loadMore() {
    this.loading$.pipe(first()).subscribe(loading => {
      if (loading || !this.hasNextPage) {
        return;
      }

      const search = this.mediaSearchService.search$.getValue();
      this.mediaSearchService.search$.next({
        ...this.mediaSearchService.search$.getValue(),
        page: search.page + 1
      });
    });
  }
}
