import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as fromAuth from '@app/authentication/state/reducers';
import * as fromRoot from '@app/core/state/reducers';
import { AuthActions } from '@app/authentication/state/actions';
import { User } from './user/models/user.model';
import { Menu } from '@app/shell/shared/menu.model';
import { LayoutCoreActions } from '@app/core/state/actions';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import { CategoryFacade } from '@app/category/state/category.facade';
import { Category } from '@app/category/models';
import { NavigationEnd, Router } from '@angular/router';
import {filter, map} from 'rxjs/operators';
import { GtagService } from '@app/core/services/gtag.service';
import { UserFollow } from './user/models/user-follow.model';
import { ChannelFacade } from './channel/state/channel-facade.service';

declare global {
  interface Window {
    gtag: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  opened$: Observable<boolean>;
  displaySidebar$: Observable<boolean>;
  previousLink$: Observable<string[] | null>;
  pageTitle$: Observable<string>;
  categories$: Observable<Category[]>;
  userMenu$: Observable<Menu[]>;
  followedUsers$: Observable<UserFollow[]>;

  loggedUser$ = this.auth.loggedUser$;
  isConsentedUser$ = this.auth.loggedUser$.pipe(map(user => !!user?.consentedAt));
  isNotConsentedUserAndLogged$ = this.auth.loggedUser$.pipe(map(user => !!user && user.consentedAt === null));
  isConsentedUserOrOffline$ = this.auth.loggedUser$.pipe(map(user => !user || user.consentedAt !== null));

  public constructor(
    private translate: TranslateService,
    private auth: AuthFacade,
    private categoryFacade: CategoryFacade,
    private channelFacade: ChannelFacade,
    private router: Router,
    private gtag: GtagService,
    private store: Store<fromRoot.State & fromAuth.State>
  ) {
    const userLang = 'fr';

    this.translate.setDefaultLang('fr');
    this.translate.use(userLang);

    this.loggedUser$ = this.store.pipe(select(fromAuth.getLoggedUser));
    this.followedUsers$ = this.channelFacade.followedUsers$;
    this.opened$ = this.store.pipe(select(fromRoot.getShowSidenav));
    this.pageTitle$ = this.store.pipe(select(fromRoot.getPageTitle));
    this.displaySidebar$ = this.store.pipe(select(fromRoot.getDisplaySidebar));
    this.previousLink$ = this.store.pipe(select(fromRoot.getPreviousLink));
    this.categories$ = this.categoryFacade.categories$;

    this.loggedUser$
      .pipe(filter(user => !!user?.id))
      .subscribe(() => this.categoryFacade.loadCategories());

    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;

    navEndEvent$.subscribe(evt => this.gtag.page(evt.urlAfterRedirects));
  }

  displayLogout() {
    this.auth.logout();
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  onToggleMenu() {
    this.store.dispatch(LayoutCoreActions.toggleMenu());
  }
}
