import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserFollow } from '../../../user/models/user-follow.model';
import { getColorForNumber } from '../../../shared/models/colors';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelListComponent {
  @Input()
  followedUsers: UserFollow[];

  getColor(user: User) {
    return getColorForNumber(user.id);
  }
}
