import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportButtonComponent } from './components/report-button/report-button.component';
import { ButtonModule } from '@app/shared/button';
import { SharedModule } from '@app/shared';
import { ReportFacade } from '@app/report/state/report-facade.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from '@app/report/state/reducers';
import { ReportEffects } from '@app/report/state/effects/report.effects';

@NgModule({
  declarations: [ReportButtonComponent],
  exports: [ReportButtonComponent],
  imports: [
    CommonModule,
    ButtonModule,
    SharedModule,
    EffectsModule.forFeature([ReportEffects]),
    StoreModule.forFeature('report', reducers)
  ],
  providers: [ReportFacade]
})
export class ReportModule {}
