import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[clickOutside]'
})
export class NavbarDirective {
  // tslint:disable-next-line:variable-name
  constructor(private _elementRef: ElementRef) {}

  // tslint:disable-next-line:no-output-rename
  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseEnter(
    targetElement: HTMLElement
  ) {
    /*Exception cause the first click on profile image in the navbar to open the widget shouldn't close it... */
    if (!targetElement.classList.contains('ng-star-inserted')) {
      const clickedInside = this._elementRef.nativeElement.contains(
        targetElement
      );
      if (!clickedInside) {
        this.clickOutside.emit(null);
      }
    }
  }
}
