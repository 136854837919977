import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Notification} from '../../models';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListComponent {

  @Input()
  notifications?: Notification[] | null;

  @Output()
  notificationChange = new EventEmitter<Notification>();

  @Output()
  redirect = new EventEmitter<void>();

  TrackById(index: number, notification: Notification) {
    return notification.id;
  }
}
