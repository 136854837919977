<div class="media-types underline-menu">
  <a [routerLink]="['../', MediaType.VIDEO]" routerLinkActive="active">
    Vidéos
  </a>
  <a [routerLink]="['../', MediaType.PDF]" routerLinkActive="active">Pdf</a>
</div>

<app-medias-list
  [medias]="medias.items"
  infiniteScroll
  (scrolled)="loadMore()"
></app-medias-list>
