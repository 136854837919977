import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { Page } from '@app/page/models/page';
import { PageFacade } from '@app/page/state/page.facade';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { PageActions } from '@app/page/state/actions';

@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageContentComponent implements OnInit {
  processing$: Observable<boolean>;
  error$: Observable<boolean>;
  page$: Observable<Page | null>;

  constructor(private facade: PageFacade) {}

  ngOnInit() {
    this.page$ = this.facade.page$;
  }
}
