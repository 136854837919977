import {Component, Input, OnInit} from '@angular/core';
import {Media} from '@app/media/models';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent implements OnInit {
  @Input()
  medias: Media[];

  constructor() {
  }

  ngOnInit() {
  }

  trackById(index: number, item: Media) {
    return item.id;
  }
}
