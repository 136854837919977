<div class="media-play" *ngIf="highlight">
  <app-icon src="/assets/images/media-play.svg" class="white-fill"></app-icon>
</div>

<div class="wrapper">
  <div class="media-view">
    <app-media-view
      [media]="media"
      class="router-link"
      [routerLink]="media | mediaRoute"
      [queryParams]="media | mediaRouteParams:playlist"
    ></app-media-view>
  </div>
</div>

<div class="media-info">
  <div
    class="media-title router-link"
    [routerLink]="media | mediaRoute"
    [queryParams]="media | mediaRouteParams:playlist"
    [title]="finalMedia.title"
  >
    {{ finalMedia.title }}
  </div>

  <div class="media-description" *ngIf="description">
    <div *nestedEllipsis="true" [innerHTML]="finalMedia.description"></div>
  </div>

  <div class="media-user">
    <app-media-creator [media]="finalMedia" [theme]="highlight ? 'light' : 'dark'"></app-media-creator>
  </div>
</div>
