import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy
} from '@angular/core';
import { AuthService } from '@app/authentication/services';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-media-pdf-viewer',
  templateUrl: './media-pdf-viewer.component.html',
  styleUrls: ['./media-pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaPdfViewerComponent {
  token$ = this.auth.getToken();
  url$ = new BehaviorSubject<string>('');

  constructor(private auth: AuthService) {}

  get pdfUrl() {
    return this.url$.getValue();
  }

  @Input()
  set pdfUrl(url: string) {
    this.url$.next(url);
  }

  get isEdgeLegacy() {
    return window.navigator.userAgent.indexOf('Edge/') > -1;
  }
}
