<form [formGroup]="form">
  <div class="title" [class.video]="isVideo">
    <ng-container *ngIf="!isEditing">
      <span *ngIf="isVideo">Complétez les détails de votre vidéo</span>
      <span *ngIf="isPdf">Complétez les détails de votre PDF</span>
      <span *ngIf="isPlaylist">Complétez les détails de votre parcours</span>
    </ng-container>
    <span class="empty"></span>

    <button class="button-cancel" (click)="cancel.emit()">
      {{ isEditing ? 'Annuler' : 'Abandonner' }}
    </button>
    <button class="button-save" (click)="save.emit()">
      {{ isEditing ? 'Enregistrer' : 'Publier' }}
    </button>
  </div>

  <div class="content">
    <!-- Upload -->
    <div class="upload section" *ngIf="!isEditing && !isPlaylist">
      <div class="section-title" *ngIf="isVideo">Votre vidéo</div>
      <div class="section-title" *ngIf="isPdf">Votre PDF</div>

      <div class="upload-content">
        <div class="upload-left">
          <!-- UPLOADER -->
          <app-upload-progress
            [upload]="upload"
            *ngIf="!!upload"
          ></app-upload-progress>
        </div>

        <div class="upload-right">
          <div class="upload-info">
            <span class="upload-info-label">Nom du fichier</span>
            <span class="upload-info-value">{{ upload?.file?.name }}</span>
          </div>

          <div class="upload-info" *ngIf="isVideo">
            <span class="upload-info-label">Durée</span>
            <span class="upload-info-value">
              {{
                videoDuration
                  ? (videoDuration * 1000 | date: 'HH:mm:ss':'UTC')
                  : '-'
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Visibility -->
    <div class="media-title section">
      <label class="section-title" for="visibility" *ngIf="isVideo">
        Visibilité de la vidéo
      </label>
      <label class="section-title" for="visibility" *ngIf="isPdf">
        Visibilité du PDF
      </label>
      <label class="section-title" for="visibility" *ngIf="isPlaylist">
        Visibilité du parcours
      </label>

      <select
        class="large"
        formControlName="visibility"
        id="visibility"
        #select
      >
        <option [value]="MediaVisibility.PUBLIC">
          {{ MediaVisibility.PUBLIC | visibility: isPlaylist }}
        </option>
        <option [value]="MediaVisibility.PRIVATE">
          {{ MediaVisibility.PRIVATE | visibility: isPlaylist }}
        </option>
        <option [value]="MediaVisibility.UNLISTED">
          {{ MediaVisibility.UNLISTED | visibility: isPlaylist }}
        </option>
      </select>

      <ng-container [ngSwitch]="select.value">
        <div class="section-infos" *ngSwitchCase="MediaVisibility.PUBLIC">
          <span *ngIf="isVideo">
            En public votre vidéo sera accessible par tous et apparaitra dans les recherches
          </span>
          <span *ngIf="isPdf">
            En public votre PDF sera accessible par tous et apparaitra dans les recherches
          </span>
          <span *ngIf="isPlaylist">
            En public votre parcours sera accessible par tous et apparaitra dans les recherches
          </span>
          <span *ngIf="isPlaylist && !media.playlistItems?.length" class="orange-text">
            Notez que le parcours doit contenir au moins 1 média avant d'être visible
          </span>
        </div>

        <div class="section-infos" *ngSwitchCase="MediaVisibility.PRIVATE">
          <span *ngIf="isVideo">
            En privée votre vidéo ne sera accessible que par vous
          </span>
          <span *ngIf="isPdf">
            En privé votre PDF ne sera accessible que par vous
          </span>
          <span *ngIf="isPlaylist">
            En privé votre parcours ne sera accessible que par vous
          </span>
        </div>

        <div class="section-infos" *ngSwitchCase="MediaVisibility.UNLISTED">
          <span *ngIf="isVideo">
            En non répertoriée votre vidéo sera visible uniquement via le lien URL. Elle ne sera pas visible dans le moteur de recherche.
          </span>
          <span *ngIf="isPdf">
            En non répertorié votre PDF sera visible uniquement via le lien URL. Il ne sera pas visible dans le moteur de recherche.
          </span>
          <span *ngIf="isPlaylist">
            En non répertorié votre parcours sera visible uniquement via le lien URL. Il ne sera pas visible dans le moteur de recherche.
          </span>
        </div>
      </ng-container>
    </div>

    <!-- Thumbnail -->
    <div class="thumbnail section">
      <div class="section-title" *ngIf="isVideo">Miniature de la vidéo</div>
      <div class="section-title" *ngIf="isPdf">Miniature du PDF</div>
      <div class="section-title" *ngIf="isPlaylist">Miniature du parcours</div>
      <div class="section-infos" *ngIf="isVideo && !isEditing">
        Une image aléatoire piochée dans la vidéo sera générée et servira de
        vignette de présentation.
      </div>
      <div class="section-infos" *ngIf="isPlaylist">
        Par défaut le parcours affichera l'image du premier média.
      </div>
      <div class="section-infos" *ngIf="!isPlaylist && (isPdf || isEditing)">
        L'image servira de vignette de présentation.
      </div>

      <div class="thumbnail-content">
        <div class="thumbnail-left" *ngIf="isVideo || validThumbnail">
          <img *ngIf="validThumbnail" [src]="thumbnail" class="thumbnail" />
          <div
            *ngIf="!validThumbnail && isVideo"
            class="thumbnail thumbnail-default"
          >
            <div>Veuillez attendre la fin de l'envoi de la vidéo</div>
          </div>
        </div>
        <div class="thumbnail-right">
          <app-media-thumbnail-input
            [optional]="isVideo || isPlaylist"
            (thumbnail)="thumbnailChange.emit($event)"
          ></app-media-thumbnail-input>
        </div>
      </div>
    </div>

    <!-- Title -->
    <div class="media-title section">
      <label class="section-title" for="title" *ngIf="isVideo">
        Titre de la vidéo
      </label>
      <label class="section-title" for="title" *ngIf="isPdf">
        Titre du PDF
      </label>
      <label class="section-title" for="title" *ngIf="isPlaylist">
        Titre du parcours
      </label>

      <div class="section-infos" *ngIf="isVideo">
        Ajouter un titre pour présenter au mieux votre vidéo.
      </div>
      <div class="section-infos" *ngIf="isPdf">
        Ajouter un titre pour présenter au mieux votre PDF.
      </div>
      <div class="section-infos" *ngIf="isPlaylist">
        Ajouter un titre pour présenter au mieux votre parcours.
      </div>

      <div class="instruction">200 signes max</div>
      <input
        id="title"
        type="text"
        maxlength="200"
        formControlName="title"
        [class.error]="hasError('title')"
      />

      <div class="form-error" *ngIf="errors('title')?.required">
        Veuillez ajouter un titre
      </div>

      <div
        class="form-error"
        *ngIf="
          errors('title')?.maxLength !== null ||
          errors('title')?.minLength !== null
        "
      >
        Le titre doit faire entre 5 et 200 caractères
      </div>
    </div>

    <!-- Description -->
    <div class="media-description section">
      <label class="section-title" for="description" *ngIf="isVideo">
        Description de la vidéo
      </label>
      <label class="section-title" for="description" *ngIf="isPdf">
        Description du PDF
      </label>
      <label class="section-title" for="description" *ngIf="isPlaylist">
        Description du parcours
      </label>

      <div class="section-infos" *ngIf="isVideo">
        Présentez votre vidéo aux autres utilisateurs.
      </div>
      <div class="section-infos" *ngIf="isPdf">
        Présentez votre PDF aux autres utilisateurs.
      </div>
      <div class="section-infos" *ngIf="isPlaylist">
        Présentez votre parcours aux autres utilisateurs.
      </div>

      <div class="instruction">1000 signes max</div>
      <textarea
        id="description"
        type="text"
        maxlength="1000"
        formControlName="description"
        [class.error]="hasError('description')"
      ></textarea>

      <div class="form-error" *ngIf="errors('description')?.required">
        Veuillez ajouter une description
      </div>

      <div
        class="form-error"
        *ngIf="
          errors('description')?.maxLength !== null ||
          errors('description')?.minLength !== null
        "
      >
        Le description doit faire entre 10 et 1000 caractères
      </div>
    </div>

    <!-- Category -->
    <div *ngIf="categories$ | async as categs" class="section">
      <label class="section-title" for="description" *ngIf="isVideo">
        Catégories de la vidéo
      </label>
      <label class="section-title" for="description" *ngIf="isPdf">
        Catégories du PDF
      </label>
      <label class="section-title" for="description" *ngIf="isPlaylist">
        Catégories du parcours
      </label>

      <div class="section-infos" *ngIf="isVideo">
        Sélectionnez les catégories auxquelles cette vidéo appartient.
      </div>
      <div class="section-infos" *ngIf="isPdf">
        Sélectionnez les catégories auxquelles ce PDF appartient.
      </div>
      <div class="section-infos" *ngIf="isPlaylist">
        Sélectionnez les catégories auxquelles ce parcours appartient.
      </div>

      <app-category-list-form
        [categories]="categs"
        [(categoriesSelected)]="categories"
        [class.error]="hasError('categoriesCount')"
      ></app-category-list-form>

      <div
        class="form-error"
        *ngIf="
          errors('categoriesCount')?.required ||
          errors('categoriesCount')?.minLength !== null
        "
      >
        Veuillez sélectionner au moins une catégorie
      </div>
    </div>

    <!-- Tags -->
    <div class="tags section">
      <label class="section-title" for="description" *ngIf="isVideo">
        Tags de la vidéo
      </label>
      <label class="section-title" for="description" *ngIf="isPdf">
        Tags du PDF
      </label>
      <label class="section-title" for="description" *ngIf="isPlaylist">
        Tags du parcours
      </label>
      <div class="section-infos" *ngIf="isVideo">
        Les tags ne sont pas visibles sur la plateforme mais servent au moteur
        de recherche pour retrouver votre vidéo. N'hésitez pas à intégrer aussi
        les mots mal orthographiés.
      </div>
      <div class="section-infos" *ngIf="isPdf">
        Les tags ne sont pas visibles sur la plateforme mais servent au moteur
        de recherche pour retrouver votre PDF. N'hésitez pas à intégrer aussi
        les mots mal orthographiés.
      </div>
      <div class="section-infos" *ngIf="isPlaylist">
        Les tags ne sont pas visibles sur la plateforme mais servent au moteur
        de recherche pour retrouver votre parcours. N'hésitez pas à intégrer aussi
        les mots mal orthographiés.
      </div>

      <div class="instruction">20 tags max</div>
      <app-tags-input
        [(tags)]="tags"
        [class.error]="hasError('tagsCount')"
      ></app-tags-input>
      <div class="sub-instruction">Séparez chaque tag par une virgule</div>

      <div
        class="form-error"
        *ngIf="
          errors('tagsCount')?.maxLength !== null ||
          errors('tagsCount')?.minLength !== null
        "
      >
        Veuillez ajouter entre 1 et 20 tags
      </div>
    </div>
  </div>

  <div class="title" [class.video]="isVideo">
    <span class="empty"></span>

    <button class="button-cancel" (click)="cancel.emit()">
      {{ isEditing ? 'Annuler' : 'Abandonner' }}
    </button>
    <button class="button-save" (click)="save.emit()">
      {{ isEditing ? 'Enregistrer' : 'Publier' }}
    </button>
  </div>
</form>
