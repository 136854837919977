import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonCircleRightComponent } from '@app/shared/button/button-circle-right';
import { ButtonOutlinedComponent } from './button-outlined/button-outlined.component';
import { IconsModule } from '@app/shared/icons';
import { ButtonTileComponent } from './button-tile/button-tile.component';

@NgModule({
  imports: [CommonModule, IconsModule],
  exports: [
    ButtonCircleRightComponent,
    ButtonOutlinedComponent,
    ButtonTileComponent
  ],
  declarations: [
    ButtonCircleRightComponent,
    ButtonOutlinedComponent,
    ButtonTileComponent
  ]
})
export class ButtonModule {}
