import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromCategory from '@app/category/state/reducers/category.reducer';

export interface State extends fromRoot.State {
  category: fromCategory.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    category: fromCategory.reducer
  })(state, action);
}

export const selectCategoryState = createFeatureSelector<State>('category');

export const getCategoryState = createSelector(
  selectCategoryState,
  state => state.category
);

export const getCategory = createSelector(
  getCategoryState,
  fromCategory.getCategory
);

export const getCategories = createSelector(
  getCategoryState,
  fromCategory.getCategories
);

export const getLoading = createSelector(
  getCategoryState,
  fromCategory.getLoading
);

export const getLoaded = createSelector(
  getCategoryState,
  fromCategory.getLoaded
);
