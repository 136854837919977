import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'angular2-toaster';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private toast: ToasterService) {}

  pop(error: any) {
    if (error instanceof HttpErrorResponse) {
      let message =
        "Une erreur s'est produite, veuillez réessayer ultérieurement";

      if (
        error.error &&
        error.error.violations &&
        error.error.violations.length
      ) {
        message = error.error.violations[0].message;
      }

      this.toast.pop('error', message);
    } else {
      this.toast.pop('error', error);
    }
  }
}
