import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Category, getColorForCategory} from '@app/category/models';

@Component({
  selector: 'app-category-list-form',
  templateUrl: './category-list-form.component.html',
  styleUrls: ['./category-list-form.component.scss']
})
export class CategoryListFormComponent implements OnInit {
  @Input()
  categories: Category[] = [];

  @Input()
  categoriesSelected: Category[] = [];

  @Output()
  categoriesSelectedChange = new EventEmitter<Category[]>();

  constructor() {
  }

  ngOnInit(): void {
  }

  isSelected(category: Category) {
    return (
      this.categoriesSelected.findIndex(ct => ct.id === category.id) !== -1
    );
  }

  getColor(category: Category) {
    return getColorForCategory(category.id);
  }

  selectCategory(category: Category) {
    const index = this.categoriesSelected.findIndex(
      ct => ct.id === category.id
    );

    if (index !== -1) {
      this.categoriesSelectedChange.emit(
        this.categoriesSelected.filter(ct => ct.id !== category.id)
      );
    } else {
      this.categoriesSelectedChange.emit([
        ...this.categoriesSelected,
        category
      ]);
    }
  }
}
