<div class="no-game-container">
  <div class="row row-head">
    <div class="profil-label">
      <fa-icon [icon]="faUser" class="icon-fa-user"></fa-icon>
      &nbsp;MON PROFIL
      <button class="button-save" (click)="service.applyModifications()">
        Enregistrer
      </button>
      &nbsp;
      <button class="button-cancel" (click)="service.cancelModifications()">
        Annuler les modifications
      </button>
      &nbsp;
    </div>
  </div>

  <div class="row profil-no-game-wrapper">
    <div class="col-md-7 profil-form">
      <div class="form-profile">
        <div class="name">{{ user.firstname }} {{ user.lastname }}</div>
        <app-first-connection [fromProfile]="true"></app-first-connection>
      </div>
    </div>
    <div class="col-md-5 profil-picture">
      <app-image-profile
        [user]="user"
        (uploadFile)="onUploadFile($event)"
      ></app-image-profile>
    </div>
  </div>
</div>
