import { UploadProgress } from '@app/upload/models/upload-progress.model';
import { MediaPdf } from '@app/upload/models/media-pdf.model';
import { ApiVideo } from '@app/upload/models/api-video.model';
import { Params } from '@angular/router';
import { MediaImage } from '@app/upload/models/media-image.model';

export enum UploadType {
  MEDIA_IMAGE = 'media_image',
  MEDIA_PDF = 'media_pdf',
  API_VIDEO = 'api_video'
}

export type UploadedResponse = MediaImage | MediaPdf | ApiVideo;

export interface Upload {
  id: number;
  file: File;
  type: UploadType;
  params?: Params;
  response?: UploadedResponse;
  progress?: UploadProgress;
  error?: string;
  chunks?: UploadChunk[];
  chunkId?: number;
  chunkUploaded?: boolean;
  apiVideoId?: string;
}

export interface UploadChunk {
  data: Blob;
  start: number;
  end: number;
}

let lastId = 0;
export const uploadFactory = (file: File, type: UploadType, params = {}) => ({
  id: lastId++,
  file,
  type,
  params
});
