<form [formGroup]="resettingForm" (ngSubmit)="onReset()" novalidate>
  <div class="custom-field">
    <app-text-input
      label="{{ 'EMAIL' | translate }}"
      [submitted]="submitted"
      formControlName="username"
      helpText="{{ 'HELP_FIELD_MAIL_RESET_PASSWORD' | translate }}"
      placeholder="{{ 'PLACEHOLDER_LOGIN' | translate }}"
    ></app-text-input>
  </div>
  <div class="submit-container">
    <button
      type="submit"
      class="custom-submit"
      [disabled]="processing"
      translate
    >
      SEND_PASSWORD_RESET_MAIL
    </button>
  </div>
</form>
