import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Page } from '@app/page/models/page';
// import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-page-header-display',
  templateUrl: './page-header-display.component.html',
  styleUrls: ['./page-header-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderDisplayComponent {
  // faQuestionCircle = faQuestionCircle;
  @Input() page: Page | null;

  constructor() {}
}
