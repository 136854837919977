import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { User } from '../../../user/models/user.model';

@Component({
  selector: 'app-first-connection-welcome',
  templateUrl: './first-connection-welcome.component.html',
  styleUrls: ['./first-connection-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstConnectionWelcomeComponent {
  @Input() user: User | null;

  constructor() {}
}
