import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input
} from '@angular/core';

import { Observable } from 'rxjs';

import { NgSelectConfig } from '@ng-select/ng-select';

import { Affiliations, Professions } from '@app/authentication/models/auth';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import { PageFacade } from '@app/page/state/page.facade';
import { Page } from '@app/page/models/page';
import { User } from '../../../user/models/user.model';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { FirstConnectionActions } from '@app/authentication/state/actions';

@Component({
  selector: 'app-first-connection',
  templateUrl: './first-connection.component.html',
  styleUrls: ['./first-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstConnectionComponent implements OnInit {
  @Input() fromProfile = false;
  processing$: Observable<boolean>;
  error$: Observable<boolean>;

  user$: Observable<User | null>;
  affiliations$: Observable<Affiliations | null>;
  professions$: Observable<Professions>;
  page$: Observable<Page | null>;

  constructor(
    private authFacade: AuthFacade,
    private pageFacade: PageFacade,
    private config: NgSelectConfig
  ) {
    this.config.loadingText = 'Chargement...';
  }

  ngOnInit() {
    this.user$ = this.authFacade.loggedUser$;

    this.professions$ = this.authFacade.professions$;
    this.authFacade.loadProfessions();

    this.affiliations$ = this.authFacade.affiliations$;

    this.pageFacade.loadPage('pre-home');
    this.page$ = this.pageFacade.page$;
  }

  loadAffiliations() {
    this.authFacade.loadAffiliations();
  }

  onSave(value: any) {
    if (!this.fromProfile) {
      this.authFacade.firstUpdateUserInfos(value);
    } else {
      this.authFacade.fromProfileUpdateUserInfos(value);
    }
  }
}
