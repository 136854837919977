import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromNotification from '@app/notification/state/reducers';
import {map} from 'rxjs/operators';
import {NotificationActions} from './actions';
import {Notification} from '../models';

@Injectable()
export class NotificationFacade {
  loading$ = this.store.pipe(select(fromNotification.getLoading));
  loaded$ = this.store.pipe(select(fromNotification.getLoaded));

  notifications$ = this.store.pipe(
    select(fromNotification.getNotifications),
    map(notifications => notifications.items)
  );
  notificationsCount$ = this.store.pipe(
    select(fromNotification.getNotifications),
    map(notifications => notifications.count)
  );

  constructor(private store: Store<fromNotification.State>) {
  }

  loadNotifications() {
    this.store.dispatch(NotificationActions.loadNotifications());
  }

  update(notification: Notification) {
    this.store.dispatch(NotificationActions.updateNotification({notification}));
  }
}
