import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-media-thumbnail-input',
  templateUrl: './media-thumbnail-input.component.html',
  styleUrls: ['./media-thumbnail-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaThumbnailInputComponent implements OnInit {
  file?: File;
  preview: any;
  minWidth = 1280;
  minHeight = 600;
  maxSize = 5000000; // 5Mo

  @Output()
  thumbnail = new EventEmitter<File>();

  @Input()
  optional = true;

  constructor(
    private change: ChangeDetectorRef,
    private toaster: ToasterService
  ) {}

  @ViewChild('previewImg')
  set previewImg(previewImg: ElementRef) {
    if (previewImg === undefined) {
      return;
    }

    previewImg.nativeElement.onload = () => {
      if (
        previewImg.nativeElement.naturalWidth < this.minWidth ||
        previewImg.nativeElement.naturalHeight < this.minHeight
      ) {
        this.toaster.pop(
          'error',
          "L'image de miniature est trop petite et devrait faire au moins 1280x600 px"
        );
        delete this.preview;
        delete this.file;
        this.change.markForCheck();
      } else {
        this.thumbnail.emit(this.file);
      }
    };
  }

  ngOnInit() {}

  inputChange(event: Event) {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];

      if (file.size > this.maxSize) {
        this.toaster.pop(
          'error',
          `Le fichier est trop volumineux et ne doit pas dépasser les ${this
            .maxSize / 1000000} Mo`
        );
        return;
      }

      this.file = file;

      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.preview = reader.result;
        this.change.detectChanges();
      };
    }
  }
}
