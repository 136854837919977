import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryComponent } from './containers/history/history.component';
import { LibraryRoutingModule } from '@app/library/library-routing.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IconsModule } from '@app/shared/icons';
import { MediaModule } from '@app/media/media.module';
import { LikedComponent } from './containers/liked/liked.component';
import { MediaPageComponent } from './components/media-page/media-page.component';
import { ResumeComponent } from './containers/resume/resume.component';

@NgModule({
  declarations: [
    HistoryComponent,
    LikedComponent,
    MediaPageComponent,
    ResumeComponent
  ],
  imports: [
    CommonModule,
    LibraryRoutingModule,
    InfiniteScrollModule,
    IconsModule,
    MediaModule
  ]
})
export class LibraryModule {}
