<div class="media-views">
  <b>{{ media.viewCount }}</b>
  {{ media.viewCount > 1 ? 'vues' : 'vue' }}
</div>

<div class="media-likes">
  <app-like-button
    [count]="media.likeCount"
    [liked]="extraData.extraData.liked"
    (likedChange)="likeMedia.emit($event)"
  ></app-like-button>
</div>
