<div class="not-found-container">
  <svg
    class="big-left-triangle"
    xmlns="http://www.w3.org/2000/svg"
    width="429.261"
    height="500.307"
    viewBox="0 0 429.261 500.307"
  >
    <path
      id="Polygone_32"
      data-name="Polygone 32"
      d="M246.7,5.93a4,4,0,0,1,6.912,0L496.8,423.247a4,4,0,0,1-3.456,6.014H6.961A4,4,0,0,1,3.5,423.247Z"
      transform="translate(429.261) rotate(90)"
      fill="#fff"
      opacity="0.242"
    />
  </svg>
  <svg
    class="small-left-triangles"
    xmlns="http://www.w3.org/2000/svg"
    width="192.261"
    height="216.307"
    viewBox="0 0 192.261 216.307"
  >
    <g
      id="Groupe_425"
      data-name="Groupe 425"
      transform="translate(-308.876 -522.986)"
      opacity="0.442"
    >
      <path
        id="Polygone_16"
        data-name="Polygone 16"
        d="M91.7,5.929a4,4,0,0,1,6.912,0L186.8,157.247a4,4,0,0,1-3.456,6.014H6.961a4,4,0,0,1-3.456-6.014Z"
        transform="translate(472.137 522.986) rotate(90)"
        fill="#fff"
        opacity="0.374"
      />
      <path
        id="Polygone_17"
        data-name="Polygone 17"
        d="M22.69,5.994a4,4,0,0,1,6.927,0L48.839,39.26a4,4,0,0,1-3.463,6H6.931a4,4,0,0,1-3.463-6Z"
        transform="translate(501.137 686.986) rotate(90)"
        fill="#fff"
        opacity="0.73"
      />
    </g>
  </svg>
  <svg
    class="top-right-triangles"
    xmlns="http://www.w3.org/2000/svg"
    width="249.205"
    height="239.593"
    viewBox="0 0 249.205 239.593"
  >
    <g
      id="Groupe_429"
      data-name="Groupe 429"
      transform="translate(-16688.659 -468.068)"
    >
      <g
        id="Groupe_426"
        data-name="Groupe 426"
        transform="translate(16441.783 -12.918)"
        opacity="0.442"
      >
        <path
          id="Polygone_16"
          data-name="Polygone 16"
          d="M114.466,5.923a4,4,0,0,1,6.91,0l110.957,190.2a4,4,0,0,1-3.455,6.016H6.964a4,4,0,0,1-3.455-6.016Z"
          transform="translate(496.08 480.986) rotate(90)"
          fill="#fff"
          opacity="0.568"
        />
        <path
          id="Polygone_17"
          data-name="Polygone 17"
          d="M20.427,5.994a4,4,0,0,1,6.927,0l16.96,29.35a4,4,0,0,1-3.463,6H6.931a4,4,0,0,1-3.463-6Z"
          transform="translate(479.571 672.797) rotate(90)"
          fill="#fff"
          opacity="0.568"
        />
      </g>
      <g id="Groupe_428" data-name="Groupe 428">
        <path
          id="Polygone_29"
          data-name="Polygone 29"
          d="M43.458,5.988a4,4,0,0,1,6.925,0L90.371,75.134a4,4,0,0,1-3.463,6H6.934a4,4,0,0,1-3.463-6Z"
          transform="translate(16769.795 590.368) rotate(90)"
          fill="#fff"
          opacity="0.412"
        />
      </g>
    </g>
  </svg>
  <svg
    class="purple-triangle"
    xmlns="http://www.w3.org/2000/svg"
    width="35.261"
    height="40.307"
    viewBox="0 0 35.261 40.307"
  >
    <path
      id="Polygone_17"
      data-name="Polygone 17"
      d="M16.681,6.076a4,4,0,0,1,6.946,0l13.26,23.2a4,4,0,0,1-3.473,5.985H6.893a4,4,0,0,1-3.473-5.985Z"
      transform="translate(35.261) rotate(90)"
      fill="#e41d90"
    />
  </svg>
  <svg
    class="orange-triangle"
    xmlns="http://www.w3.org/2000/svg"
    width="27.136"
    height="30.842"
    viewBox="0 0 27.136 30.842"
  >
    <path
      id="Polygone_28"
      data-name="Polygone 28"
      d="M12.813,4.59a3,3,0,0,1,5.217,0L28.295,22.654a3,3,0,0,1-2.608,4.482H5.155a3,3,0,0,1-2.608-4.482Z"
      transform="translate(27.136) rotate(90)"
      fill="#f9a13f"
    />
  </svg>

  <svg
    class="orange-dark-triangle"
    xmlns="http://www.w3.org/2000/svg"
    width="27.136"
    height="30.842"
    viewBox="0 0 27.136 30.842"
  >
    <path
      id="Polygone_25"
      data-name="Polygone 25"
      d="M12.813,4.59a3,3,0,0,1,5.217,0L28.295,22.654a3,3,0,0,1-2.608,4.482H5.155a3,3,0,0,1-2.608-4.482Z"
      transform="translate(27.136) rotate(90)"
      fill="#fc6a13"
    />
  </svg>
  <div class="texte">
    <div class="title">DÉSOLÉ,</div>
    <div class="sub-title">LA PAGE QUE VOUS RECHERCHEZ SEMBLE INTROUVABLE.</div>
    <div class="home-button" routerLink="/">RETOURNER À L'ACCUEIL</div>
  </div>
</div>
