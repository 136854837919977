import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/authentication/services';
import { NotFoundComponent } from '@app/fallback/containers/not-found/not-found.component';

const FALLBACK_ROUTES: Routes = [
  {
    path: 'fallback',
    children: [
      {
        path: 'not-found',
        component: NotFoundComponent,
        canActivate: [AuthGuard]
      },
      { path: '', redirectTo: 'not-found', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(FALLBACK_ROUTES)],
  exports: [RouterModule]
})
export class FallbackRoutingModule {}
