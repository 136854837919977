import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { User } from '../../user/models/user.model';
import {
  animate,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';

@Component({
  selector: 'app-profile-widget',
  templateUrl: './profile-widget.component.html',
  styleUrls: ['./profile-widget.component.scss'],
  animations: [
    trigger('Appear', [
      transition(':enter', [
        style({ right: '-200px', opacity: 0 }),
        animate(200, style({ right: '20px', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ right: '20px', opacity: 1 }),
        animate(200, style({ right: '-200px', opacity: 0 }))
      ])
    ])
  ]
})
export class ProfileWidgetComponent implements OnInit {
  @HostBinding('@Appear')
  appear = true;

  @Input() user: User;
  @Output() routing = new EventEmitter<void>();
  @Output() logout = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
