import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MediaSearchOrder, MediaSearchOrderText } from '@app/media/models';
import { Subscription } from 'rxjs';
import {
  defaultMediaSearchConfig,
  MediaSearchConfig
} from '../../services/media.search.service';

export interface MediaResearchFormValue {
  order: MediaSearchOrder;
  videoType: boolean;
  pdfType: boolean;
  playlistType: boolean;
}

@Component({
  selector: 'app-media-research-form',
  templateUrl: './media-research-form.component.html',
  styleUrls: ['./media-research-form.component.scss']
})
export class MediaResearchFormComponent implements OnInit, OnDestroy {
  MediaSearchOrderText = MediaSearchOrderText;
  MediaSearchOrder = MediaSearchOrder;
  pConfig: MediaSearchConfig = defaultMediaSearchConfig();
  subscription: Subscription;

  form = this.builder.group({
    order: [MediaSearchOrder.PERTINENCE],
    videoType: [true],
    pdfType: [true],
    playlistType: [true],
  });

  @Output()
  searchChange = new EventEmitter<MediaResearchFormValue>();

  constructor(private builder: FormBuilder) {}

  @Input()
  set search(search: MediaResearchFormValue) {
    this.form.patchValue(search, { emitEvent: false });
  }

  @Input()
  set config(config: MediaSearchConfig) {
    this.pConfig = config;
    const order = this.form.value.order;

    if (config.availableOrders.indexOf(order) === -1) {
      this.form.patchValue({
        order: config.availableOrders[0]
      });
    }
  }

  gerOrderText(order: MediaSearchOrder) {
    return this.MediaSearchOrderText[order];
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(values => {
      this.searchChange.emit(values);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
