import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-underliner',
  templateUrl: './underliner.component.html',
  styleUrls: ['./underliner.component.scss']
})
export class UnderlinerComponent implements OnInit {
  @Input() max = 10;
  @Input() hover = false;

  constructor() {}

  ngOnInit() {}
}
