<app-button-outlined
  *ngIf="{ userFollow: userFollow$ | async } as data"
  [text]="data.userFollow !== undefined ? 'Abonné' : 'S\'abonner'"
  [class.subscribed]="data.userFollow !== undefined"
  [icon]="
    data.userFollow !== undefined
      ? 'assets/images/subscribed.svg'
      : 'assets/images/arrow-subscribe.svg'
  "
  (click)="data.userFollow !== undefined ? unsubscribe() : subscribe()"
></app-button-outlined>
