import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromMedia from '@app/media/state/reducers/media.reducer';

export interface State extends fromRoot.State {
  media: fromMedia.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    media: fromMedia.reducer
  })(state, action);
}

export const selectMediaState = createFeatureSelector<State>('media');

export const getMediaState = createSelector(
  selectMediaState,
  state => state.media
);

export const getMedia = createSelector(getMediaState, fromMedia.getMedia);

export const getMediaExtraData = createSelector(
  getMediaState,
  fromMedia.getMediaExtraData
);

export const getMedias = createSelector(getMediaState, fromMedia.getMedias);
export const getMyMedias = createSelector(getMediaState, fromMedia.getMyMedias);

export const getSuggestedMedias = createSelector(
  getMediaState,
  fromMedia.getSuggestedMedias
);

export const getTopMedias = createSelector(
  getMediaState,
  fromMedia.getTopMedias
);

export const getContinueMedias = createSelector(
  getMediaState,
  fromMedia.getContinueMedias
);

export const getRecommendedMedias = createSelector(
  getMediaState,
  fromMedia.getRecommendedMedias
);

export const getFeaturedMedias = createSelector(
  getMediaState,
  fromMedia.getFeaturedMedias
);

export const getFeaturedMediaExtraData = createSelector(
  getMediaState,
  fromMedia.getFeaturedMediaExtraData
);

export const getSearchedMedias = createSelector(
  getMediaState,
  fromMedia.getSearchedMedias
);

export const getAssociatedPlaylists = createSelector(
  getMediaState,
  fromMedia.getAssociatedPlaylists
);

export const getSearch = createSelector(getMediaState, fromMedia.getSearch);

export const getLoading = createSelector(getMediaState, fromMedia.getLoading);

export const getLoaded = createSelector(getMediaState, fromMedia.getLoaded);
