<div class="button-validation-action">
  <button
    *ngIf="withEdit"
    type="button"
    class="btn btn-outline-primary"
    (click)="onEdit()"
  >
    <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
  </button>
  <button
    *ngIf="withDelete"
    type="button"
    class="btn btn-outline-danger"
    (click)="onDelete()"
  >
    <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
  </button>
</div>
