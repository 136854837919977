<div *ngIf="comment" class="comment">
  <app-profile-picture [user]="comment.author"></app-profile-picture>

  <div class="comment-info">
    <div class="comment-author">
      <span class="comment-author-name">{{ comment.author | user }}</span>
      <span class="comment-date">{{ comment.createdAt | date }}</span>
    </div>

    <div class="comment-content">
      {{ comment.content }}
    </div>

    <div class="comment-action">
      <app-like-button
        [count]="comment.likeCount"
        [liked]="comment.liked"
        (likedChange)="likeComment.emit({ comment: comment, liked: $event })"
      ></app-like-button>

      <a *ngIf="canAnswer && !answer" (click)="answer = true">Répondre</a>

      <span class="comment-children-button" *ngIf="comment.replies?.length">
        <a (click)="toggleDisplayChildren()">
          {{ displayChildren ? 'Masquer' : 'Afficher' }} les
          {{ comment.replies?.length }} réponses
          <span>
            <img
              *ngIf="!displayChildren"
              src="assets/images/arrow-down.svg"
              alt="afficher"
            />
            <img
              *ngIf="displayChildren"
              src="assets/images/arrow-up.svg"
              alt="masquer"
            />
          </span>
        </a>
      </span>
    </div>

    <app-comment-form
      [media]="media"
      [user]="user"
      (comment)="addComment.emit($event)"
      [parent]="comment"
      *ngIf="answer"
    ></app-comment-form>

    <div
      class="comment-children expandable"
      [style.max-height.px]="commentsContainerHeight"
      [hidden]="!(comment.replies?.length && (displayChildren || hasScrollToChildren))"
      #comments
    >
      <app-comment-list
        [user]="user"
        [media]="media"
        [comments]="comment.replies"
        (like)="likeComment.emit($event)"
        (addComment)="addComment.emit($event)"
        [canAnswer]="false"
        [scrollTo]="scrollTo"
      ></app-comment-list>
    </div>
  </div>
</div>
