<div class="search-content" infiniteScroll (scrolled)="loadMore()">
  <app-media-research-form
    [search]="searchForm$ | async"
    [config]="searchConfig$ | async"
    (searchChange)="search($event)"
  ></app-media-research-form>

  <hr />

  <app-media-vertical-list
    [medias]="searchedMedias$ | async"
  ></app-media-vertical-list>
</div>
<div *ngIf="empty$ | async" class="no-result">
  Aucun média n'a été trouvé
</div>
