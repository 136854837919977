import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Media, MediaSearchOrder, MediaType } from '@app/media/models';
import { MediaFacade } from '@app/media/state/media.facade';
import { ActivatedRoute } from '@angular/router';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  MediaSearchConfig,
  MediaSearchService
} from '../../../media/services/media.search.service';
import {
  Collection,
  defaultCollection
} from '../../../shared/models/collection';

@UntilDestroy()
@Component({
  selector: 'app-my-medias',
  templateUrl: './my-medias.component.html',
  styleUrls: ['./my-medias.component.scss']
})
export class MyMediasComponent implements OnInit {
  hasMoreMedia = true;
  config$ = new BehaviorSubject<MediaSearchConfig>({
    availableOrders: [MediaSearchOrder.CREATED_AT]
  });
  MediaType = MediaType;
  medias: Collection<Media> = defaultCollection<Media>();
  loading$ = this.mediaFacade.loading$;

  mediaType$ = this.route.params.pipe(
    map(params => params.type),
    map(type => type ?? MediaType.VIDEO)
  ) as Observable<MediaType>;

  constructor(
    private mediaFacade: MediaFacade,
    private route: ActivatedRoute,
    private mediaSearchService: MediaSearchService,
    private change: ChangeDetectorRef
  ) {}

  get nextPage() {
    return this.medias ? this.medias.items.length < this.medias.count : false;
  }

  ngOnInit(): void {
    combineLatest([this.mediaSearchService.search$, this.mediaType$])
      .pipe(untilDestroyed(this))
      .subscribe(([search, type]) => {
        this.mediaFacade.loadMyMedias({
          ...search,
          mediaTypes: [type]
        });
      });

    this.config$
      .pipe(untilDestroyed(this))
      .subscribe(config => this.mediaSearchService.changeConfig(config));

    this.mediaFacade.myMedias$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(
          this.mediaFacade.myMediasCount$
        )
      )
      .subscribe(([medias, count]) => {
        const search = this.mediaSearchService.search$.getValue();
        this.hasMoreMedia = medias.length >= 10;

        this.medias = {
          count: this.medias.count + count,
          items: search.page === 1 ? medias : [...this.medias.items, ...medias]
        };

        this.change.markForCheck();
      });
  }

  loadMore() {
    this.loading$.pipe(first()).subscribe(loading => {
      if (loading || !this.hasMoreMedia) {
        return;
      }

      const search = this.mediaSearchService.search$.getValue();
      this.mediaSearchService.search$.next({
        ...search,
        page: search.page + 1
      });
    });
  }
}
