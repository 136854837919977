import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  ExternalLinkActions,
  ExternalLinkApiActions
} from '@app/links-bar/state/actions';
import { ExternalLinkService } from '@app/links-bar/services/external-link.service';

@Injectable()
export class ExternalLinkEffects {
  public constructor(
    private actions$: Actions,
    private service: ExternalLinkService
  ) {}

  loadExternalLinks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExternalLinkActions.loadExternalLinks),
      switchMap(_ => {
        return this.service.loadExternalLinks().pipe(
          map(externalLinks =>
            ExternalLinkApiActions.loadExternalLinksSuccess({ externalLinks })
          ),
          catchError(() =>
            of(
              ExternalLinkApiActions.loadExternalLinksFailure({
                error: 'Can not load overview'
              })
            )
          )
        );
      })
    );
  });
}
