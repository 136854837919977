<div class="background" [class.fullscreen]="isFullScreen"></div>

<app-standalone-bar>
  <app-icon src="/assets/images/add.svg"></app-icon>
  <span>Partager mes connaissances</span>
</app-standalone-bar>

<ng-container [ngSwitch]="currentStep">
  <div *ngSwitchCase="UploadStep.MEDIA_TYPE" class="step-1" @inOutAnimation>
    <app-button-tile
      color="light-green"
      icon="/assets/images/filetype-pdf.svg"
      text="Partager un PDF"
      class="shadow-box"
      (click)="mediaType = MediaType.PDF"
    ></app-button-tile>

    <app-button-tile
      color="light-blue"
      icon="/assets/images/filetype-video.svg"
      text="Partager une vidéo"
      class="shadow-box"
      (click)="mediaType = MediaType.VIDEO"
    ></app-button-tile>
  </div>

  <div *ngSwitchCase="UploadStep.MEDIA_FILE" class="step-2" @inOutAnimation>
    <div class="step-2-container">
      <div style="background: white; width: 100%; height: 350px">
        <app-upload-file-input-form
          class="shadow-box"
          [mediaType]="mediaType"
          (fileChange)="inputFile = $event"
        ></app-upload-file-input-form>
      </div>

      <div class="buttons-footer">
        <app-button-outlined
          icon="/assets/images/slide-left.svg"
          iconBefore="true"
          text="retour"
          (click)="mediaType = undefined"
        ></app-button-outlined>

        <app-button-outlined
          icon="/assets/images/slide-right.svg"
          text="suivant"
          [disabled]="inputFile === undefined"
          (click)="file = inputFile"
        ></app-button-outlined>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="UploadStep.MEDIA_INFO" class="step-3" @inOutAnimation>
    <app-upload-detail
      class="shadow-box"
      [upload]="uploadMediaResource$ | async"
      [(media)]="media"
      [videoDuration]="videoDuration"
      [thumbnail]="thumbnailVideo$ | async"
      [canPublish]="canSubmit"
      (save)="submit()"
      (cancel)="modalAbort.open()"
      (thumbnailChange)="thumbnail = $event"
    ></app-upload-detail>
  </div>
</ng-container>

<app-modal #modalAbort>
  <app-modal-header [centered]="true">
    <app-modal-title>êtes-vous sûr de vouloir abandonner ?</app-modal-title>
  </app-modal-header>
  <p>Cette action est irreversible, vos informations seront perdues.</p>
  <div class="d-flex flex-row">
    <app-button-outlined
      text="Annuler"
      (click)="modalAbort.close()"
    ></app-button-outlined>
    <app-button-outlined
      class="plain"
      text="Abandonner"
      [routerLink]="['/medias']"
    ></app-button-outlined>
  </div>
</app-modal>

<app-modal #modalPublish [closableByDefault]="false">
  <app-modal-header [centered]="true">
    <app-modal-title>
      Félicitations, votre
      {{ mediaType === MediaType.VIDEO ? 'vidéo' : 'PDF' }} a été publiée !
    </app-modal-title>
  </app-modal-header>

  <p *ngIf="mediaType === MediaType.VIDEO">
    Votre vidéo apparaitra sur la plateforme d’ici quelques minutes.
  </p>

  <app-media-share
    *ngIf="uploadedMedia"
    [media]="uploadedMedia"
  ></app-media-share>

  <app-button-outlined
    class="plain"
    text="Publier un autre contenu"
    (click)="modalPublish.close(); restart($event)"
  ></app-button-outlined>

  <a [routerLink]="['/medias']" (click)="modalPublish.close()">
    Retour à la plateforme
  </a>
</app-modal>
