import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {AuthActions, FirstConnectionActions} from '@app/authentication/state/actions';
import * as fromAuth from '@app/authentication/state/reducers';
import {User} from '../../user/models/user.model';

@Injectable()
export class AuthFacade {
  loggedUser$ = this.store.pipe(select(fromAuth.getLoggedUser));
  professions$ = this.store.pipe(
    select(fromAuth.getFirstConnectionProfessions)
  );
  affiliations$ = this.store.pipe(
    select(fromAuth.getFirstConnectionAffiliations)
  );

  constructor(private store: Store<fromAuth.State>) {
  }

  isAuthorized(roles: string[]): Observable<boolean> {
    return this.store.pipe(select(fromAuth.getAuthorized, {roles}));
  }

  loadProfessions(): void {
    this.store.dispatch(FirstConnectionActions.loadProfessions());
  }

  loadAffiliations(): void {
    this.store.dispatch(FirstConnectionActions.loadAffiliations());
  }

  firstUpdateUserInfos(user: Partial<User>): void {
    this.store.dispatch(FirstConnectionActions.firstUpdateUserInfos({user}));
  }

  fromProfileUpdateUserInfos(user: Partial<User>): void {
    this.store.dispatch(
      FirstConnectionActions.formProfileUpdateUserInfos({user})
    );
  }

  login(value: any) {
    this.store.dispatch(FirstConnectionActions.login({value}));
  }

  consent(user: User) {
    this.store.dispatch(AuthActions.consent({user}));
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  loadRefreshToken(refreshToken: string) {
    this.store.dispatch(AuthActions.loadRefreshToken({refreshToken}));
  }

  reloadUser() {
    this.store.dispatch(AuthActions.reloadUser());
  }
}
