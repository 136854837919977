<!-- Categories -->
<div
  class="regular-link-item link-item light-green-bg cursor-pointer"
  (click)="categoriesContainer.hidden = !categoriesContainer.hidden"
>
  <app-category-icon
    class="icon white-fill white-stroke grey-bg"
  ></app-category-icon>
  <span>Catégories</span>
  <app-icon
    class="arrow"
    src="assets/images/slide-right.svg"
    [class.rotate-90]="!categoriesContainer.hidden"
  ></app-icon>
</div>

<div #categoriesContainer hidden [style.max-height.px]="categoriesContainer.scrollHeight" class="expandable">
  <a
    *ngFor="let category of categories"
    class="category-link-item link-item {{ getCategoryColor(category) }}-bg"
    [routerLink]="['/category', category.id]"
    routerLinkActive="active"
  >
    <app-category-icon
      class="white-fill white-stroke {{ getCategoryColor(category) }}-bg"
    ></app-category-icon>
    <span>{{ category.name }}</span>
  </a>
</div>
<div class="separator"></div>

<!-- Like -->
<a
  class="regular-link-item link-item red-bg"
  [routerLink]="['/library/liked']"
  routerLinkActive="active"
  (click)="visible = false"
>
  <app-icon class="icon" src="assets/images/like.svg"></app-icon>
  <span>Les contenus que j'aime</span>
  <app-icon class="arrow" src="assets/images/slide-right.svg"></app-icon>
</a>
<div class="separator"></div>

<!-- Resume -->
<a
  class="regular-link-item link-item blue-bg"
  [routerLink]="['/library/resume']"
  routerLinkActive="active"
  (click)="visible = false"
>
  <app-icon class="icon" src="assets/images/media-play.svg"></app-icon>
  <span>Reprendre</span>
  <app-icon class="arrow" src="assets/images/slide-right.svg"></app-icon>
</a>
<div class="separator"></div>

<!-- Historic -->
<a
  class="regular-link-item link-item light-green-bg"
  [routerLink]="['/library/history']"
  routerLinkActive="active"
  (click)="visible = false"
>
  <app-icon class="icon" src="assets/images/clock.svg"></app-icon>
  <span>
    Mon historique
  </span>
  <app-icon class="arrow" src="assets/images/slide-right.svg"></app-icon>
</a>
<div class="separator"></div>

<!-- Channels -->
<div
  class="regular-link-item link-item light-green-bg cursor-pointer"
  (click)="channelsContainer.hidden = !channelsContainer.hidden"
>
  <app-icon class="icon" src="assets/images/arrow-subscribe.svg"></app-icon>
  <span>
    Mes abonnements
  </span>
  <app-icon
    class="arrow"
    src="assets/images/slide-right.svg"
    [class.rotate-90]="!channelsContainer.hidden"
  ></app-icon>
</div>
<div #channelsContainer hidden [style.max-height.px]="channelsContainer.scrollHeight" class="expandable">
  <app-channel-list [followedUsers]="followedUsers"></app-channel-list>
</div>
<div class="separator"></div>
