import {Component, OnDestroy, OnInit} from '@angular/core';
import {CategoryFacade} from '@app/category/state/category.facade';
import {ActivatedRoute} from '@angular/router';
import {distinctUntilChanged, map, scan, shareReplay, startWith, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {MediaService} from '@app/media/services';
import {Collection, defaultCollection} from '@app/shared/models/collection';
import {Media, MediaExtraData} from '@app/media/models';
import {themeColors} from '@app/shared/models/colors';
import {animate, style, transition, trigger} from '@angular/animations';
import {getColorForCategory} from '../../../category/models';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  animations: [
    trigger('Appear', [
      transition(':enter', [
        style({transform: 'scaleY(0)'}),
        animate(200, style({transform: 'scaleY(1)'}))
      ]),
      transition(':leave', [
        style({transform: 'scaleY(1)'}),
        animate(200, style({transform: 'scaleY(0)'}))
      ])
    ])
  ]
})
export class CategoryComponent implements OnInit, OnDestroy {
  colors = themeColors;
  page$ = new BehaviorSubject(1);
  loadingPage = false;
  nextPage = true;
  category$ = this.categoryFacade.category$;
  color$ = this.category$.pipe(
    map(category => (category ? getColorForCategory(category.id) : 'light-green'))
  );

  mediaCollection$ = this.category$.pipe(
    tap(() => this.page$.next(1)),
    switchMap(category =>
      this.page$.pipe(
        // loading
        tap(() => (this.loadingPage = true)),
        // load a page of medias
        switchMap(page =>
          category
            ? this.mediaService.getMedias({
              categories: category.id.toString(),
              page: page.toString(),
              'order[id]': 'desc'
            })
            : of(defaultCollection<Media>())
        ),
        // keep if other pages
        tap(collection => (this.nextPage = !!collection.items.length)),
        // default value
        startWith(defaultCollection<Media>()),
        // reduce medias over time (accumulate them)
        scan(
          (previousMedia, newMedias) =>
            ({
              items: [...previousMedia.items, ...newMedias.items],
              count: newMedias.count
            } as Collection<Media>)
        ),
        // end loading
        tap(() => (this.loadingPage = false))
      )
    ),
    // Share same observable replaying last result
    shareReplay(1)
  );

  mediasCount$ = this.mediaCollection$.pipe(
    map(collection => collection.count)
  );

  // TODO Replace with store or categories search
  medias$ = this.mediaCollection$.pipe(
    map(collection => collection.items.slice(1, collection.items.length))
  );

  featuredMedia$ = this.mediaCollection$.pipe(
    map(medias => (medias.items.length ? medias.items[0] : null)),
    distinctUntilChanged(),
    startWith(null)
  );

  lastMediaExtradata$: Observable<MediaExtraData | null> = of(null);
  paramSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private categoryFacade: CategoryFacade,
    private mediaService: MediaService
  ) {
  }

  ngOnInit(): void {
    this.paramSubscription = this.route.params
      .pipe(
        map(params =>
          params?.id
            ? this.categoryFacade.loadCategory(parseInt(params.id, 10))
            : undefined
        )
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.paramSubscription && !this.paramSubscription.closed) {
      this.paramSubscription.unsubscribe();
    }
  }

  loadFeaturedExtradata() {
    this.lastMediaExtradata$ = this.featuredMedia$.pipe(
      switchMap(media =>
        media ? this.mediaService.loadMediaExtraData(media.id) : of(null)
      ),
      startWith(null)
    );
  }

  loadMore() {
    if (this.loadingPage || !this.nextPage) {
      return;
    }

    this.page$.next(this.page$.getValue() + 1);
  }
}
