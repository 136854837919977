import { props, createAction } from '@ngrx/store';

import { Page } from '@app/page/models/page';

export const loadPage = createAction(
  '[Page] Load page',
  props<{ page: string }>()
);
export const loadPageSuccess = createAction(
  '[Page] Load page success',
  props<{ page: Page }>()
);
export const loadPageFailure = createAction(
  '[Page] Load page failure',
  props<{ error: any }>()
);
