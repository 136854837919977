import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { User } from '../../user/models/user.model';
import * as fromAuth from '@app/authentication/state/reducers';
import { ReloadService } from '@app/authentication/services/reload.service';

@Injectable({
  providedIn: 'root'
})
export class PreHomeGuard implements CanActivate {
  public constructor(
    private store: Store<fromAuth.State>,
    private reloadService: ReloadService
  ) {}

  public isUserConnected(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getIsLogged),
      filter((loaded: boolean) => loaded),
      take(1)
    );
  }

  public canActivate(): Observable<boolean> {
    return this.isUserConnected().pipe(
      switchMap(() => this.getLoggeddUser()),
      switchMap((user: User) => {
        if (!!user.preHomeData) {
          this.reloadService.navigateWithReload(['/medias']);
          return of(false);
        }
        return of(true);
      })
    );
  }

  public getLoggeddUser(): Observable<any> {
    return this.store.pipe(select(fromAuth.getLoggedUser), take(1));
  }
}
