<ng-container *ngIf="loggedUser$ | async as user">
  <div class="footer-container" *ngIf="isLogged(user) && hasPreHomeValue(user)">
    <footer>
      <div>
        <a [routerLink]="['/medias']" class="logo-wrapper left-part">
          <img src="assets/images/bnp_logo.png" alt="Logo BNP Paribas" />
        </a>
      </div>

      <div><a [routerLink]="['/medias']">Accueil</a></div>
      <div><a [routerLink]="['/page/faq']">FAQ</a></div>
      <div><a [routerLink]="['/profile']">Mon profil</a></div>
      <div>
        <a [routerLink]="['/page/mentions-legales']">Mentions légales</a>
      </div>
    </footer>
  </div>
</ng-container>
