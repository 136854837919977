import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export interface GtagEvent {
  action: string;
  category?: string;
  label?: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class GtagService {
  constructor() {
    if (!!(window as any).dataLayer || !this.hasValidConfiguration) {
      return;
    }

    const script = document.createElement('script');
    const script2 = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtmId}`;

    script2.innerText = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag(\'js\', new Date());
    gtag('config', '${environment.gtmId}', {'send_page_view': false});
    `;

    document.head.appendChild(script);
    document.head.appendChild(script2);
  }

  get hasValidConfiguration() {
    return !(
      !environment.gtmId ||
      environment.gtmId.length <= 0
    );
  }

  page(url: string) {
    if (!this.hasValidConfiguration) {
      return;
    }

    (window as any).gtag('config', environment.gtmId, {
      page_path: url
    });
  }

  event(event: GtagEvent) {
    if (!this.hasValidConfiguration) {
      return;
    }

    let data: any = {
      value: event.value
    };

    if (!!event.category) {
      data = { ...data, event_category: event.category };
    }

    if (!!event.label) {
      data = { ...data, event_label: event.label };
    }

    (window as any).gtag('event', event.action, data);
  }
}
