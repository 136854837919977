import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/shared/modal/modal.service';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {
  @Input() centered = false;

  constructor(public modalService: ModalService) {}

  ngOnInit() {}
}
