<div
  class="button-validation-action text-center d-flex justify-content-end align-items-center p-3-group"
  [ngClass]="formGroupClass"
>
  <button
    *ngIf="labelCancel !== '' && !confirmDisabled"
    type="button"
    (click)="onCancel()"
    class="btn btn-cancel btn-lg"
    ngClass="btn-{{ classCancel }}"
  >
    {{ labelCancel }}
  </button>
  <button
    *ngIf="labelConfirm !== ''"
    [type]="typeConfirm"
    (click)="onConfirm()"
    class="btn btn-confirm btn-lg"
    [disabled]="confirmDisabled"
    ngClass="btn-{{ classConfirm }}"
  >
    <span
      class="spinner-border spinner-border-sm align-baseline"
      style="font-size: 1rem;"
      *ngIf="confirmDisabled"
    ></span>
    <span *ngIf="confirmDisabled">&nbsp;{{ 'LOADING' | translate }}</span>
    <span *ngIf="!confirmDisabled">{{ labelConfirm }}</span>
  </button>
</div>
