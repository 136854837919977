import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  icon$: Observable<SafeHtml>;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
  }

  // If the svg file doesn't have embedded color (like style="fill:#...") you can apply it with css.
  @Input()
  set src(icon: string) {
    this.icon$ = this.http
      .get(icon, {
        headers: {Accept: 'image/svg+xml'},
        responseType: 'text'
      })
      .pipe(
        map(data => this.sanitizer.bypassSecurityTrustHtml(data)),
      );
  }
}
