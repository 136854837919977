<div
  class="category"
  *ngFor="let category of categories"
  (click)="selectCategory(category); $event.stopPropagation()"
>
  <app-category-icon
    class="white-fill white-stroke {{ getColor(category) }}-bg"
  ></app-category-icon>
  <span>{{ category.name }}</span>
  <input type="checkbox" name="category" [checked]="isSelected(category)" />
</div>
