import { createAction, props } from '@ngrx/store';
import { Upload } from '@app/upload/models/upload.model';

export const uploadFileProgress = createAction(
  '[Uploads] Upload file progress',
  props<{ upload: Upload }>()
);

export const uploadFileSuccess = createAction(
  '[Uploads] Upload file success',
  props<{ upload: Upload }>()
);

export const uploadFileFailure = createAction(
  '[Uploads] Upload file failure',
  props<{ upload: Upload }>()
);
