import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Media } from '@app/media/models';
import { ModalComponent } from '@app/shared/modal/modal/modal.component';
import { UploadDetailComponent } from '@app/media/components/upload-detail/upload-detail.component';
import { ErrorService } from '@app/core/services/error.service';
import { MediaService } from '@app/media/services';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-media-remove',
  templateUrl: './media-remove.component.html',
  styleUrls: ['./media-remove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaRemoveComponent {
  @Output()
  mediaRemoved = new EventEmitter<Media>();

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @ViewChild(UploadDetailComponent)
  uploadDetailComponent: UploadDetailComponent;

  @Input()
  media: Media;

  constructor(
    private mediaService: MediaService,
    private errorService: ErrorService
  ) {}

  open() {
    if (this.modal && !this.modal.opened) {
      this.modal.open();
    }
  }

  close() {
    if (this.modal && this.modal.opened) {
      this.modal.close();
    }
  }

  remove() {
    this.mediaService.removeMedia(this.media.id).subscribe(
      () => {
        this.mediaRemoved.emit(this.media);
        this.close();
      },
      error => {
        this.errorService.pop(error);
      }
    );
  }
}
