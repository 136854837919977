import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { ProfileActions } from '@app/user/state/actions';
import * as fromProfile from './reducers';

@Injectable()
export class ProfileFacade {
  constructor(private store: Store<any>) {}

  sendProfileSuccess$ = this.store.pipe(
    select(fromProfile.getSendProfileSuccess)
  );
  sendProfileError$ = this.store.pipe(select(fromProfile.getSendProfileError));
  sendProfilePending$ = this.store.pipe(
    select(fromProfile.getSendProfilePending)
  );

  uploadRequest(file: File) {
    this.store.dispatch(ProfileActions.uploadRequest({ file }));
  }
}
