import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromChannel from './channel.reducer';

export interface State extends fromRoot.State {
  channel: fromChannel.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    channel: fromChannel.reducer
  })(state, action);
}

export const selectChannelState = createFeatureSelector<State>('channel');

export const getChannelState = createSelector(
  selectChannelState,
  state => state.channel
);

export const getFollowedUser = createSelector(
  getChannelState,
  fromChannel.getFollowedUser
);

export const getUnfollowedUser = createSelector(
  getChannelState,
  fromChannel.getUnfollowedUser
);

export const getFollowedUsers = createSelector(
  getChannelState,
  fromChannel.getFollowedUsers
);

export const getLoaded = createSelector(getChannelState, fromChannel.getLoaded);

export const getLoading = createSelector(
  getChannelState,
  fromChannel.getLoading
);
