import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '@app/page/models/page';
import { AuthFacade } from '@app/authentication/state/auth.facade';
import { PageFacade } from '@app/page/state/page.facade';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  page$: Observable<Page | null>;

  constructor(
    private authFacade: AuthFacade,
    private pageFacade: PageFacade,
    private config: NgSelectConfig
  ) {
    this.config.loadingText = 'Chargement...';
  }

  ngOnInit() {
    this.pageFacade.loadPage('login');
    this.page$ = this.pageFacade.page$;
  }
}
