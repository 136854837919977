<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="10.55"
  height="9.797"
  viewBox="0 0 10.55 9.797"
>
  <defs>
    <clipPath id="category-icon-clip-path">
      <path
        id="Tracé_146"
        data-name="Tracé 146"
        d="M5.507-17h7.536a1.507,1.507,0,0,1,1.507,1.507v4.522a1.507,1.507,0,0,1-1.507,1.507H5.507A1.507,1.507,0,0,1,4-10.971v-4.522A1.507,1.507,0,0,1,5.507-17Z"
        transform="translate(-4 17)"
        fill="none"
        clip-rule="evenodd"
      />
    </clipPath>
    <clipPath id="category-icon-clip-path-2">
      <path
        id="Tracé_148"
        data-name="Tracé 148"
        d="M4.754-6H13.8a.754.754,0,0,1,.754.754.754.754,0,0,1-.754.754H4.754A.754.754,0,0,1,4-5.246.754.754,0,0,1,4.754-6Z"
        transform="translate(-4 6)"
      />
    </clipPath>
  </defs>
  <g
    id="category-icon-Groupe_185"
    data-name="Groupe 185"
    transform="translate(-23 -166.203)"
  >
    <g
      id="category-icon-Groupe_161"
      data-name="Groupe 161"
      transform="translate(23 166.203)"
      clip-path="url(#category-icon-clip-path)"
    >
      <g
        id="category-icon-Groupe_160"
        data-name="Groupe 160"
        transform="translate(0 0)"
      >
        <path
          id="category-icon-Tracé_145"
          data-name="Tracé 145"
          d="M1.507,0H9.043A1.507,1.507,0,0,1,10.55,1.507V6.029A1.507,1.507,0,0,1,9.043,7.536H1.507A1.507,1.507,0,0,1,0,6.029V1.507A1.507,1.507,0,0,1,1.507,0Z"
          fill="none"
          stroke-width="3"
        />
      </g>
    </g>
    <g
      id="category-icon-Groupe_162"
      data-name="Groupe 162"
      transform="translate(23 174.493)"
      clip-path="url(#category-icon-clip-path-2)"
    >
      <path
        id="category-icon-Tracé_147"
        data-name="Tracé 147"
        d="M-1-1.957H17.087V-11H-1Z"
        transform="translate(-2.768 7.232)"
      />
    </g>
  </g>
</svg>
