import { MediaType } from '@app/media/models/media.model';

export enum MediaSearchOrder {
  PERTINENCE = 'pertinence',
  CREATED_AT = 'createdAt',
  TITLE = 'title'
}

export const MediaSearchOrderText: Record<MediaSearchOrder, string> = {
  [MediaSearchOrder.PERTINENCE]: 'Pertinence',
  [MediaSearchOrder.CREATED_AT]: 'Date de mise en ligne',
  [MediaSearchOrder.TITLE]: 'Ordre alphabétique'
};

export type MediaSearchOrderDir = 'desc' | 'asc';

export interface MediaSearchOrderModel {
  by: MediaSearchOrder;
  direction: MediaSearchOrderDir;
}

export interface MediaSearchModel {
  query: string;
  page: number;
  order: MediaSearchOrderModel;
  mediaTypes: MediaType[];
  hasMore: boolean;
}

export const defaultMediaSearchOrderModel: () => MediaSearchOrderModel = () => ({
  by: MediaSearchOrder.PERTINENCE,
  direction: 'desc'
});

export const defaultMediaSearchModel: () => MediaSearchModel = () => ({
  query: '',
  page: 1,
  order: defaultMediaSearchOrderModel(),
  mediaTypes: [],
  hasMore: true
});
