import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  FirstConnectionPageComponent,
  SendResetPasswordViewComponent,
  LoginViewComponent,
  ResetPasswordViewComponent
} from '@app/authentication/containers';
import { AuthGuard, PreHomeGuard } from '@app/authentication/services';
import { authConfig } from '@app/authentication/auth.config';
import { RefreshTokenComponent } from './containers/refresh-token/refresh-token.component';
import { ReloadComponent } from '@app/authentication/containers/reload/reload.component';

const AUTHENTICATION_ROUTES: Routes = [
  { path: 'reload', component: ReloadComponent },
  { path: 'login', component: LoginViewComponent },
  { path: 'resetting', component: SendResetPasswordViewComponent },
  {
    path: `${authConfig.path.passwordResetConfirm}/:token`,
    component: ResetPasswordViewComponent
  },
  {
    path: 'pre-home',
    component: FirstConnectionPageComponent,
    canActivate: [AuthGuard, PreHomeGuard]
  },
  {
    path: 'token/:refresh_token',
    component: RefreshTokenComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(AUTHENTICATION_ROUTES)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
