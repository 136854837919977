import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@app/upload/state/reducers';
import { UploadEffects } from '@app/upload/state/effects/upload-effects.service';
import { UploadFacade } from '@app/upload/state/upload-facade.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([UploadEffects]),
    StoreModule.forFeature('upload', reducers)
  ],
  providers: [UploadFacade]
})
export class UploadModule {}
