import { createAction, props } from '@ngrx/store';
import { Comment } from '@app/comment/models/comment.model';
import { Iri } from '@app/shared/models/api-entity';

const TAG = '[Comment/View]';

export const resetComments = createAction(`${TAG} Reset comments`);

export const loadComments = createAction(
  `${TAG} Load comments`,
  props<{ mediaIri: Iri }>()
);

export const addComment = createAction(
  `${TAG} Add comment`,
  props<{ comment: Comment }>()
);

export const likeComment = createAction(
  `${TAG} Like comment`,
  props<{ comment: Comment }>()
);

export const unlikeComment = createAction(
  `${TAG} Unlike comment`,
  props<{ comment: Comment }>()
);
