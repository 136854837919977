import { createReducer, on } from '@ngrx/store';
import {
  UserChannelActions,
  UserChannelApiActions
} from '@app/channel-media/state/actions';
import { UserFollow } from '@app/user/models/user-follow.model';
import { User } from '@app/user/models/user.model';
import {
  defaultMediaSearchModel,
  defaultMediaSearchOrderModel,
  Media,
  MediaSearchModel,
  MediaSearchOrder
} from '@app/media/models';
import { Collection, defaultCollection } from '@app/shared/models/collection';

export interface State {
  userChannel?: User;
  userChannelMediasSearch: MediaSearchModel;
  userChannelMedias: Collection<Media>;
  addedUserFollow?: UserFollow;
  removedUserFollow?: UserFollow;
  loading: boolean;
  loaded: boolean;
}

export const defaultChannelMediaSearchOrderModel = (): MediaSearchModel => ({
  ...defaultMediaSearchModel(),
  order: {
    ...defaultMediaSearchOrderModel(),
    by: MediaSearchOrder.CREATED_AT
  }
});

export const INITIAL_STATE: State = {
  userChannelMediasSearch: defaultChannelMediaSearchOrderModel(),
  userChannelMedias: defaultCollection<Media>(),
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(UserChannelActions.loadUserChannel, (state, { reset }) => ({
    ...state,
    userChannel: reset ? undefined : state.userChannel,
    loading: true,
    loaded: false
  })),
  on(UserChannelActions.changeUserChannelMediaSearch, (state, { search }) => ({
    ...state,
    userChannelMediasSearch: search
  })),
  on(UserChannelActions.loadUserChannelMedias, state => ({
    ...state,
    userChannelMedias:
      state.userChannelMediasSearch.page !== 1
        ? state.userChannelMedias
        : defaultCollection<Media>(),
    loading: true,
    loaded: false
  })),
  on(UserChannelApiActions.loadUserChannelSuccess, (state, { user }) => ({
    ...state,
    userChannel: user,
    loading: false,
    loaded: true
  })),
  on(
    UserChannelApiActions.loadUserChannelMediasSuccess,
    (state, { medias }) => ({
      ...state,
      userChannelMedias: {
        count: state.userChannelMedias.count + medias.count,
        items:
          state.userChannelMediasSearch &&
          state.userChannelMediasSearch.page === 1
            ? medias.items
            : [...state.userChannelMedias.items, ...medias.items]
      },
      loading: false,
      loaded: true
    })
  ),
  on(UserChannelApiActions.loadUserChannelFailure, state => ({
    ...state,
    userChannel: undefined,
    loading: false,
    loaded: false
  })),
  on(UserChannelApiActions.loadUserChannelMediasFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  }))
);

export const getUserChannel = (state: State) => state.userChannel;
export const getUserChannelMedias = (state: State) => state.userChannelMedias;
export const getUserChannelMediasSearch = (state: State) =>
  state.userChannelMediasSearch;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
