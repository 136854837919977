<a
  class="picture-wrapper {{ !hasPicture ? 'background-' + color : '' }}"
  [class.hover]="hover"
  [class.active]="active"
  [routerLink]="route ? ['/channel', user?.id] : undefined"
>
  <img
    *ngIf="hasPicture; else name"
    [src]="user?.avatarUrl"
    alt="Photo de profil"
  />

  <ng-template #name>
    <span>{{ shortName }}</span>
  </ng-template>
</a>
