<div class="channel-header {{ (color$ | async) + '-bg' }}">
  <div class="user" *ngIf="userChannel$ | async as userChannel">
    <div class="user-picture">
      <app-profile-picture [user]="userChannel"></app-profile-picture>
    </div>
    <div class="user-infos">
      <div class="user-channel">Chaîne de</div>
      <div class="user-name">{{ userChannel | user }}</div>
      <div class="user-subscriber">
        {{
          userChannel.userFollowerListCount === 0
            ? 'Aucun abonné'
            : userChannel.userFollowerListCount === 1
            ? '1 abonné'
            : userChannel.userFollowerListCount + 'abonnés'
        }}
      </div>
    </div>
  </div>
</div>

<div
  class="channel-content {{ (color$ | async) + '-text' }} {{
    (color$ | async) + '-bd'
  }}"
>
  <div class="channel-menu">
    <app-media-research-form
      [search]="searchForm$ | async"
      [config]="searchConfig$ | async"
      (searchChange)="search($event)"
    ></app-media-research-form>

    <div
      class="channel-buttons"
      *ngIf="{
        channelId: channelId$ | async,
        user: connected$ | async
      } as data"
    >
      <app-subscribe-button
        *ngIf="data.channelId && data.channelId !== data.user.id"
        [userId]="data.channelId"
      ></app-subscribe-button>
    </div>
  </div>

  <div class="channel-medias" infiniteScroll (scrolled)="loadMore()">
    <app-media-list
      *ngIf="medias$ | async as medias"
      [medias]="medias"
    ></app-media-list>
  </div>
</div>
