<app-modal>
  <app-modal-header [centered]="true">
    <app-modal-title>Ajouter un contenu au parcours</app-modal-title>
  </app-modal-header>

  <div class="flex-row align-items-center justify-content-end menu">
    <ng-container [ngTemplateOutlet]="buttons"></ng-container>
  </div>

  <app-playlist-medias-form
    *ngIf="pPlaylist !== undefined"
    [(playlist)]="pPlaylist"
  >
  </app-playlist-medias-form>

  <div class="flex-row align-items-center justify-content-end menu">
    <div class="flex-1">
      <app-button-outlined
        class="button-close"
        text="fermer"
        (click)="close()"
      ></app-button-outlined>
    </div>

    <div class="flex-row align-items-center justify-content-end">
      <ng-container [ngTemplateOutlet]="buttons"></ng-container>
    </div>
  </div>
</app-modal>

<ng-template #buttons>
  <app-button-outlined
    class="button-cancel"
    text="Annuler les modifications"
    (click)="cancel()"
  ></app-button-outlined>

  <app-button-outlined
    class="button-submit"
    text="Enregistrer"
    (click)="submit()"
  ></app-button-outlined>
</ng-template>
