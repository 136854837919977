import {Pipe, PipeTransform} from '@angular/core';
import {Media} from '../models';
import {FeaturedMedia} from '../models/highlighted-media.model';

@Pipe({
  name: 'mediaRoute'
})
export class MediaRoutePipe implements PipeTransform {
  transform(media: Media|FeaturedMedia|number): string[] {
    return ['/medias', this.getTargetMediaId((media as FeaturedMedia).media ?? media) ?? ''];
  }

  getTargetMediaId(media: Media|number): string | undefined {
    const subMedia = typeof media === 'object' ? this.getTargetMedia(media) : media;
    return typeof subMedia === 'number' ? subMedia.toString() : (subMedia as Media).id.toString();
  }

  getTargetMedia(media: Media): Media | number {
    return media.playlistItems?.length ? media.playlistItems[0].mediaItemId : media;
  }
}
