<div class="notification flex-row">
  <div
    class="avatar flex-column justify-content-center align-items-center"
    *ngIf="user !== undefined"
  >
    <app-profile-picture [user]="user"></app-profile-picture>
  </div>

  <div class="content flex-column flex-1" (click)="redirectTo(); $event.stopPropagation()">
    <div class="content-text" [innerHTML]="message"></div>
    <div class="content-date">{{notification?.createdAt | date}}</div>
  </div>

  <div class="not-read flex-column justify-content-center align-items-center" (click)="markAsRead()">
    <div class="not-read-mark" *ngIf="notification !== undefined && notification !== null && notification.viewedAt === null"></div>
  </div>
</div>
