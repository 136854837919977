import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Media } from '@app/media/models';
import { merge, Subject } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-media-share',
  templateUrl: './media-share.component.html',
  styleUrls: ['./media-share.component.scss']
})
export class MediaShareComponent {
  copied$ = new Subject<boolean>();
  copiedState$ = merge(
    this.copied$,
    this.copied$.pipe(
      debounceTime(1000),
      map(() => false)
    )
  ).pipe(shareReplay(1));

  @Input()
  media: Media;

  constructor(private router: Router) {}

  getUrl() {
    return (
      (window as any).location.origin +
      '/#' +
      this.router.serializeUrl(
        this.router.createUrlTree(['/medias', this.media.id])
      )
    );
  }

  copy(input: HTMLInputElement) {
    // Enable and select
    input.disabled = false;
    input.select();
    input.setSelectionRange(0, 99999);

    // Copy
    document.execCommand('copy');

    // Unselect and disable
    input.setSelectionRange(0, 0);
    input.blur();
    input.disabled = true;

    // Notification
    this.copied$.next(true);
  }
}
