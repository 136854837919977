import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ExternalLinkFacade } from '@app/links-bar/state/external-link.facade';
import { Observable, Subscription } from 'rxjs';
import { ExternalLink } from '@app/links-bar/models/external-link.model';
import { tap } from 'rxjs/operators';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-links-bar',
  templateUrl: './links-bar.component.html',
  styleUrls: ['./links-bar.component.scss']
})
export class LinksBarComponent implements OnInit, OnDestroy {
  externalLinks$: Observable<ExternalLink[]>;

  haveExternalLinks = false;
  havePrograms = false;
  inRadarManager = false;

  haveProgramsSubscription$: Subscription;

  constructor(
    private linksFacade: ExternalLinkFacade,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.externalLinks$ = this.linksFacade.externalLinks$.pipe(
      // Need at least one
      tap(externalLinks => (this.haveExternalLinks = externalLinks.length > 0))
    );

    this.linksFacade.loadExternalLinks();

    this.inRadarManager = this.router.isActive('/radar', false);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.inRadarManager = this.router.isActive('/radar', false);
        this.cdRef.detectChanges(); // Need to force the detection change, not sure why...
      }
    });
  }

  ngOnDestroy(): void {
    this.haveProgramsSubscription$.unsubscribe();
  }
}
