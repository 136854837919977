<div (click)="input.focus()" class="tag-area">
  <app-tag-badge
    *ngFor="let tag of tags"
    [tag]="tag"
    (remove)="remove($event)"
  ></app-tag-badge>

  <div class="tag-input" [class.focus]="focusingTagInput">
    <div class="wrapper">
      <input
        type="text"
        (keypress)="keypress($event)"
        (keyup)="search()"
        [(ngModel)]="tag.name"
        [maxlength]="maxTagLength.toString()"
        [minlength]="minTagLength.toString()"
        (focusin)="focusingTagInput = true"
        (focusout)="focusingTagInput = false"
        #input
      />

      <div class="autocomplete" *ngIf="tag.name.length >= minTagLength">
        <div
          *ngFor="let searched of tagsSearched | async"
          (click)="$event.preventDefault(); add(searched)"
          class="autocomplete-tag"
        >
          {{ searched.name }}
        </div>
      </div>
    </div>
  </div>
</div>
