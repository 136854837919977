import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Collection } from '../../../shared/models/collection';
import { Media } from '../../../media/models';
import { ActivatedRoute } from '@angular/router';
import { AuthFacade } from '../../../authentication/state/auth.facade';
import { MediaService } from '../../../media/services';
import {
  defaultMediaSearchConfig,
  MediaSearchConfig,
  MediaSearchService
} from '../../../media/services/media.search.service';
import { MediaResearchFormValue } from '../../../media/components/media-research-form/media-research-form.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { themeColors } from '../../../shared/models/colors';

@UntilDestroy()
@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss']
})
export class MediaPageComponent implements OnInit {
  config$ = new BehaviorSubject<MediaSearchConfig>(defaultMediaSearchConfig());
  searchForm$ = this.mediaSearchService.searchForm$;
  searchConfig$ = this.mediaSearchService.config$;

  @Input()
  hasNextPage = true;

  @Input()
  medias: Collection<Media> | null;

  @Input()
  loading: boolean;

  @Input()
  title = 'Title';

  @Input()
  color = themeColors[0];

  constructor(
    private route: ActivatedRoute,
    private auth: AuthFacade,
    private mediaService: MediaService,
    private mediaSearchService: MediaSearchService
  ) {}

  @Input()
  set config(config: MediaSearchConfig) {
    this.config$.next(config);
  }

  ngOnInit(): void {
    this.config$
      .pipe(untilDestroyed(this))
      .subscribe(config => this.mediaSearchService.changeConfig(config));
  }

  search(value: MediaResearchFormValue) {
    this.mediaSearchService.search(value, [], this.route);
  }

  loadMore() {
    if (this.loading || !this.hasNextPage) {
      return;
    }

    const search = this.mediaSearchService.search$.getValue();
    this.mediaSearchService.search$.next({
      ...this.mediaSearchService.search$.getValue(),
      page: search.page + 1
    });
  }
}
