import { Component, Input, OnInit } from '@angular/core';
import { Upload, UploadType } from '@app/upload/models/upload.model';

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit {
  @Input() upload: Upload;

  UploadType = UploadType;

  constructor() {}

  ngOnInit() {}
}
