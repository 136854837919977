<app-modal-wrapper [title]="'PROFIL_CONFIRMATION_TITLE' | translate">
  <section class="bg-white content-section">
    <div class="p-3">
      <div class="profile-confirm-content">
        {{ 'PROFIL_CONFIRMATION' | translate }}
      </div>
      <div class="text-center mt-md-3">
        <button
          type="button"
          class="btn btn-validate px-md-4 text-uppercase"
          (click)="continue()"
        >
          {{ 'CONTINUE' | translate }}
        </button>
      </div>
    </div>
  </section>
</app-modal-wrapper>
