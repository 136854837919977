<div class="block-picture-wrapper">
  <div class="picture-wrapper">
    <img [src]="picture" alt="picture"/>
  </div>

  <div class="button-modify-picture">
    <input
      #file
      type="file"
      (change)="onUploadFile($event)"
      accept="image/png, image/jpeg"
    />

    <button type="button">
      MODIFIER MA PHOTO DE PROFIL
    </button>
  </div>
</div>
