import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {Media, MediaType} from '@app/media/models';
import {UploadFacade} from '@app/upload/state/upload-facade.service';
import {Upload, UploadType} from '@app/upload/models/upload.model';
import {MediaImage} from '@app/upload/models/media-image.model';
import {ModalComponent} from '@app/shared/modal/modal/modal.component';
import {UploadDetailComponent} from '@app/media/components/upload-detail/upload-detail.component';
import {filter, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ErrorService} from '@app/core/services/error.service';
import {MediaService} from '@app/media/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AuthFacade} from '../../../authentication/state/auth.facade';
import {User} from '../../../user/models/user.model';

@UntilDestroy()
@Component({
  selector: 'app-media-update',
  templateUrl: './media-update.component.html',
  styleUrls: ['./media-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaUpdateComponent {
  MediaType = MediaType;
  pMedia?: Media | Partial<Media>;
  thumbnail?: File;
  uploadThumbnail?: Upload;

  @Output()
  mediaChange = new EventEmitter<Media>();

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @ViewChild(UploadDetailComponent)
  uploadDetailComponent: UploadDetailComponent;

  constructor(
    private auth: AuthFacade,
    private uploadFacade: UploadFacade,
    private mediaService: MediaService,
    private errorService: ErrorService,
    private changes: ChangeDetectorRef
  ) {
  }

  @Input()
  set media(media: Media | Partial<Media> | undefined) {
    this.pMedia = media
      ? {
        ...media
      }
      : undefined;

    delete this.thumbnail;
    delete this.uploadThumbnail;
  }

  get uploading() {
    return !!this.uploadThumbnail && !this.uploadThumbnail.response;
  }

  get canSubmit() {
    return (
      this.uploadDetailComponent &&
      this.uploadDetailComponent.form.valid &&
      !this.uploading
    );
  }

  open() {
    if (this.modal && !this.modal.opened) {
      this.modal.open();
    }
  }

  close() {
    if (this.modal && this.modal.opened) {
      this.modal.close();
    }
  }

  submit() {
    if (!this.canSubmit) {
      this.uploadDetailComponent.form.markAllAsTouched();
      this.errorService.pop('Veuillez suivre les indications du formulaire');
      this.changes.detectChanges();
      return;
    }

    const media = this.pMedia as Media;
    let upload$: Observable<Media> = this.upload().pipe(
      switchMap(() => this.mediaService.uploadMediaTags(this.pMedia as Media)),
    );

    if (!media.id) {
      upload$ = upload$.pipe(
        withLatestFrom(this.auth.loggedUser$ as Observable<User>),
        switchMap(([updatedMedia, user]) => this.mediaService.createMedia({
          ...updatedMedia,
          creator: user
        }))
      );
    } else {
      upload$ = upload$.pipe(
        switchMap(updatedMedia => this.mediaService.updateMedia(updatedMedia))
      );
    }

    upload$
      .pipe(untilDestroyed(this))
      .subscribe(
        updatedMedia => {
          this.mediaChange.emit(updatedMedia);
          this.close();
        },
        error => {
          this.errorService.pop(error);
        }
      );
  }

  upload(): Observable<Upload | undefined> {
    if (!this.thumbnail || !this.pMedia) {
      return of(undefined);
    }

    return this.uploadFacade
      .uploadFile(this.thumbnail, UploadType.MEDIA_IMAGE)
      .pipe(
        tap(upload => (this.uploadThumbnail = upload ?? undefined)),
        filter((upload): upload is Upload => !!upload?.response),
        tap(upload => {
          const mediaImage = upload.response as MediaImage;

          if (mediaImage['@id'] === undefined || !this.pMedia) {
            return;
          }

          this.pMedia = {
            ...this.pMedia,
            imageFile: mediaImage['@id']
          };

          this.uploadThumbnail = undefined;
        })
      );
  }
}
