import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { defaultTag, Tag } from '@app/tag/models/tag.model';
import { TagFacade } from '@app/tag/state/tag-facade.service';
import { debounceTime, map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss']
})
export class TagsInputComponent implements OnInit {
  focusingTagInput = false;
  tag: Tag = defaultTag();
  search$ = new Subject<string>();
  minTagLength = 3;
  maxTagLength = 200;
  validCharacters = new RegExp(/^[0-9a-zA-ZÀ-ÿ\-# ]*$/);
  submitCharacters = new RegExp(/^(,|Enter)*$/);

  @Input()
  tags: Tag[] = [];

  @Output()
  tagsChange = new EventEmitter<Tag[]>();

  private pTagsSearched$ = this.tagFacade.tags$;

  constructor(private toaster: ToasterService, private tagFacade: TagFacade) {}

  get tagsSearched() {
    return this.pTagsSearched$.pipe(
      map(tags =>
        tags.items
          .filter(tag => this.tags.findIndex(tg => tag.name === tg.name) === -1)
          .slice(0, 6)
      )
    );
  }

  ngOnInit(): void {
    this.search$
      .pipe(debounceTime(500))
      .subscribe(search => this.tagFacade.loadTags(search));
  }

  search() {
    const name = this.tag.name.trim();

    if (name.length >= this.minTagLength && name.length <= this.maxTagLength) {
      this.search$.next(name);
    }
  }

  keypress(event: KeyboardEvent) {
    if (this.submitCharacters.test(event.key)) {
      event.preventDefault();
      this.submit();
    }

    if (!this.validCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

  submit(displayError = true) {
    const name = this.tag.name.trim();

    if (name.length < this.minTagLength) {
      if (displayError) {
        this.toaster.pop(
          'error',
          `Les Tags doivent faire au moins ${this.minTagLength} caractères`
        );
      }
      return;
    }

    if (name.length > this.maxTagLength) {
      if (displayError) {
        this.toaster.pop(
          'error',
          `Les Tags doivent faire au maximum ${this.maxTagLength} caractères`
        );
      }
      return;
    }

    this.add({
      name
    });
  }

  add(tag: Tag) {
    if (this.tags.findIndex(tg => tg.name === tag.name) !== -1) {
      this.toaster.pop('error', `Le Tag ${tag.name} a déjà été ajouté`);
      return;
    }

    this.tagsChange.emit([...this.tags, tag]);

    this.tag = defaultTag();
  }

  remove(tag: Tag) {
    const index = this.tags.indexOf(tag);

    if (index !== undefined) {
      this.tagsChange.emit([
        ...this.tags.slice(0, index),
        ...this.tags.slice(index + 1, this.tags.length)
      ]);
    }
  }
}
