import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { ChannelFacade } from '../../state/channel-facade.service';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-subscribe-button',
  templateUrl: './subscribe-button.component.html',
  styleUrls: ['./subscribe-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscribeButtonComponent {
  userId$ = new BehaviorSubject<number | undefined>(undefined);
  userFollow$ = this.userId$.pipe(
    switchMap(userId =>
      userId ? this.channelFacade.selectFollowedUser(userId) : EMPTY
    )
  );

  constructor(
    private channelFacade: ChannelFacade,
    private change: ChangeDetectorRef
  ) {}

  @Input()
  set userId(userId: number) {
    this.userId$.next(userId);
  }

  subscribe() {
    const userId = this.userId$.getValue();
    if (!userId) {
      return;
    }

    this.channelFacade.follow(userId);
  }

  unsubscribe() {
    this.userFollow$.pipe(first()).subscribe(follow => {
      if (follow) {
        this.channelFacade.unfollow(follow);
      }
    });
  }
}
