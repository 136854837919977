import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {reducers} from './state/reducers';
import {NotificationEffects} from './state/effects';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationsWidgetComponent } from './containers/notifications-widget/notifications-widget.component';
import {SharedModule} from '../shared';
import {NotificationFacade} from './state/notification-facade.service';

@NgModule({
  declarations: [NotificationItemComponent, NotificationListComponent, NotificationsWidgetComponent],
  exports: [
    NotificationsWidgetComponent,
  ],
  imports: [
    EffectsModule.forFeature([NotificationEffects]),
    StoreModule.forFeature('notification', reducers),
    CommonModule,
    SharedModule
  ],
  providers: [NotificationFacade]
})
export class NotificationModule {
}
