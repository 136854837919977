import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MediasComponent} from './containers/medias/medias.component';
import {MediaRoutingModule} from '@app/media/media-routing.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {MediaEffects} from '@app/media/state/effects/media.effects';
import {reducers} from '@app/media/state/reducers';
import {MediaFacade} from '@app/media/state/media.facade';
import {MediaListComponent} from './components/media-list/media-list.component';
import {FeaturedMediaCardComponent} from './components/featured-media-card/featured-media-card.component';
import {MediaTileComponent} from './components/media-tile/media-tile.component';
import {MediaBadgeComponent} from './components/featured-media-badge/media-badge.component';
import {MediaCreatorComponent} from './components/media-creator/media-creator.component';
import {MediaViewComponent} from './components/media-view/media-view.component';
import {MediaComponent} from './containers/media/media.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserModule} from '@app/user/user.module';
import {SharedModule} from '@app/shared';
import {MediaActionComponent} from './components/media-action/media-action.component';
import {CommentModule} from '@app/comment/comment.module';
import {MediaPdfViewerComponent} from './components/media-pdf-viewer/media-pdf-viewer.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {MediaSliderComponent} from './components/media-slider/media-slider.component';
import {NestedEllipsisModule} from 'ngx-nested-ellipsis';
import {MediaVerticalListComponent} from './components/media-vertical-list/media-vertical-list.component';
import {MediasSearchComponent} from './containers/medias-search/medias-search.component';
import {MediaResearchInputComponent} from '@app/media/components/media-research-input/media-research-input.component';
import {MediasSearchInputComponent} from './containers/medias-search-input/medias-search-input.component';
import {MediaResearchFormComponent} from './components/media-research-form/media-research-form.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {UploadComponent} from './containers/upload/upload.component';
import {UploadWidgetComponent} from './components/upload-widget/upload-widget.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UploadFileInputFormComponent} from './components/upload-file-input/upload-file-input-form.component';
import {UploadDetailComponent} from './components/upload-detail/upload-detail.component';
import {TagModule} from '@app/tag/tag.module';
import {UploadProgressComponent} from './components/upload-progress/upload-progress.component';
import {UploadProgressPipe} from './pipes/upload-progress.pipe';
import {FileSizePipe} from '@app/shared/pipes/file-size.pipe';
import {DurationPipe} from '@app/shared/pipes/duration.pipe';
import {UploadModule} from '@app/upload/upload.module';
import {MediaThumbnailInputComponent} from './components/media-thumbnail-input/media-thumbnail-input.component';
import {LastMediaCardComponent} from '@app/media/components/last-media-card/last-media-card.component';
import {CategoryModule} from '@app/category/category.module';
import {MediaShareComponent} from './components/media-share/media-share.component';
import {MediaShareButtonComponent} from './components/media-share-button/media-share-button.component';
import {MediaTopTileComponent} from './components/media-top-tile/media-top-tile.component';
import {MediaUpdateComponent} from './components/media-update/media-update.component';
import {MediaRemoveComponent} from '@app/media/components/media-remove/media-remove.component';
import {VisibilityPipe} from './pipes/visibility.pipe';
import {ReportModule} from '@app/report/report.module';
import {ChannelModule} from '../channel/channel.module';
import {PlaylistMediasFormComponent} from './components/playlist-medias-form/playlist-medias-form.component';
import {PlaylistMediaFormComponent} from './components/playlist-media-form/playlist-media-form.component';
import {PlaylistItemsComponent} from './components/playlist-items/playlist-items.component';
import {PlaylistMediasFormListComponent} from './components/playlist-medias-form-list/playlist-medias-form-list.component';
import {PlaylistMediaRemoveComponent} from './components/playlist-media-remove/playlist-media-remove.component';
import {MediaRoutePipe} from './pipes/media-route.pipe';
import {PlaylistMediaComponent} from './components/playlist-media-vertical-list/playlist-media.component';
import {MediaRouteParamsPipe} from './pipes/media-route-params.pipe';
import { NextMediaTimerComponent } from './components/next-media-timer/next-media-timer.component';

@NgModule({
  declarations: [
    MediasComponent,
    MediaListComponent,
    FeaturedMediaCardComponent,
    MediaTileComponent,
    MediaBadgeComponent,
    MediaCreatorComponent,
    MediaViewComponent,
    MediaComponent,
    MediaActionComponent,
    MediaPdfViewerComponent,
    MediaSliderComponent,
    MediaVerticalListComponent,
    MediasSearchComponent,
    MediaResearchInputComponent,
    MediasSearchInputComponent,
    MediaResearchFormComponent,
    UploadComponent,
    UploadWidgetComponent,
    UploadFileInputFormComponent,
    UploadDetailComponent,
    UploadProgressComponent,
    UploadProgressPipe,
    MediaThumbnailInputComponent,
    LastMediaCardComponent,
    MediaShareComponent,
    MediaShareButtonComponent,
    MediaTopTileComponent,
    MediaUpdateComponent,
    MediaRemoveComponent,
    VisibilityPipe,
    PlaylistMediasFormComponent,
    PlaylistMediaFormComponent,
    PlaylistItemsComponent,
    PlaylistMediasFormListComponent,
    PlaylistMediaRemoveComponent,
    MediaRoutePipe,
    MediaRouteParamsPipe,
    PlaylistMediaComponent,
    NextMediaTimerComponent
  ],
  imports: [
    CommonModule,
    MediaRoutingModule,
    EffectsModule.forFeature([MediaEffects]),
    StoreModule.forFeature('media', reducers),
    ReactiveFormsModule,
    UserModule,
    SharedModule,
    UploadModule,
    CommentModule,
    CategoryModule,
    NgxExtendedPdfViewerModule,
    NestedEllipsisModule,
    NgSelectModule,
    InfiniteScrollModule,
    BrowserAnimationsModule,
    FormsModule,
    TagModule,
    ReportModule,
    ChannelModule
  ],
  exports: [
    MediaResearchInputComponent,
    MediasSearchInputComponent,
    FeaturedMediaCardComponent,
    LastMediaCardComponent,
    MediaListComponent,
    MediaViewComponent,
    MediaUpdateComponent,
    MediaRemoveComponent,
    VisibilityPipe,
    MediaResearchFormComponent,
    PlaylistItemsComponent,
    PlaylistMediaRemoveComponent,
    MediaRoutePipe,
    MediaRouteParamsPipe
  ],
  providers: [MediaFacade, FileSizePipe, DurationPipe, MediaRoutePipe, MediaRouteParamsPipe]
})
export class MediaModule {
}
