<div class="instruction">
  Prochaine vidéo
</div>
<div class="timer">
  <div class="progress" [style.width.%]="progress"></div>
</div>
<div class="buttons">
  <app-button-outlined class="next" (click)="next()">Voir la suite</app-button-outlined>
  <app-button-outlined class="cancel" (click)="cancel()">Annuler</app-button-outlined>
</div>
