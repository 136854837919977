<div class="media-view">
  <div class="wrapper">
    <app-media-view
      [media]="media"
      [extraData]="mediaExtraData"
      (needExtraData)="needExtraData.emit()"
      [autoplay]="true"
    ></app-media-view>
  </div>
</div>
<div class="media-info">
  <div class="wrapper">
    <app-media-badge>
      {{ media.type === MediaType.PDF ? 'Dernier PDF' : 'Dernière vidéo' }}
    </app-media-badge>

    <div
      class="media-title router-link"
      [routerLink]="media | mediaRoute"
      [title]="media.title"
    >
      {{ media.title }}
    </div>

    <div class="media-description">
      <div class="media-description-text">
        <div
          class="media-description-ellipsis"
          *nestedEllipsis="true"
          [innerHTML]="media.description"
        ></div>
      </div>
      <div
        class="media-description-sizer"
        [innerHTML]="media.description"
      ></div>
    </div>

    <div class="media-footer">
      <div class="media-author">
        <app-media-creator [media]="media"></app-media-creator>
      </div>

      <div class="media-controls">
        <button
          class="play router-link media-control"
          [routerLink]="media | mediaRoute"
        >
          Lecture
        </button>

        <app-media-share-button
          class="media-control"
          [media]="media"
        ></app-media-share-button>

        <app-media-action
          class="media-control"
          *ngIf="mediaExtraData"
          [media]="media"
          (likeMedia)="likeMedia.emit($event)"
          [extraData]="mediaExtraData"
          [user]="user"
        ></app-media-action>
      </div>
    </div>
  </div>
</div>
