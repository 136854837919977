import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@app/channel-media/state/reducers';
import { UserChannelEffects } from '@app/channel-media/state/effects/user-channel-effects.service';
import { UserChannelComponent } from './containers/user-channel/user-channel.component';
import { UserChannelMediaRoutingModule } from '@app/channel-media/user-channel-media-routing.module';
import { MediaModule } from '@app/media/media.module';
import { ChannelModule } from '../channel/channel.module';
import { UserChannelFacade } from '@app/channel-media/state/user-channel-facade.service';
import { SharedModule } from '@app/shared';
import { UserModule } from '@app/user/user.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [UserChannelComponent],
  imports: [
    CommonModule,
    ChannelModule,
    MediaModule,
    EffectsModule.forFeature([UserChannelEffects]),
    StoreModule.forFeature('userChannel', reducers),
    UserChannelMediaRoutingModule,
    SharedModule,
    UserModule,
    InfiniteScrollModule
  ],
  providers: [UserChannelFacade]
})
export class UserChannelMediaModule {}
