import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {ActionsItemsModule} from '@app/shared/actions-item/actions-item.module';
import {IconsModule} from '@app/shared/icons/icons.module';
import {DropdownNavbarComponent, MenuComponent, NavbarComponent} from '@app/shell/components';
import {FooterComponent} from './components/footer/footer.component';
import {LinksBarModule} from '@app/links-bar/links-bar.module';
import {UnderlinerComponent} from './components/underliner/underliner.component';
import {SideBarComponent} from './components/side-bar/side-bar.component';
import {SharedModule} from '@app/shared';
import {NavbarDirective} from './components/navbar/navbar.directive';
import {MediaModule} from '@app/media/media.module';
import {ButtonModule} from '@app/shared/button';
import {CategoryModule} from '@app/category/category.module';
import {ChannelModule} from '../channel/channel.module';
import {NotificationModule} from '../notification/notification.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ActionsItemsModule,
    IconsModule,
    LinksBarModule,
    SharedModule,
    MediaModule,
    ButtonModule,
    CategoryModule,
    ChannelModule,
    NotificationModule
  ],
  exports: [NavbarComponent, MenuComponent, FooterComponent, SideBarComponent],
  declarations: [
    NavbarComponent,
    DropdownNavbarComponent,
    MenuComponent,
    FooterComponent,
    UnderlinerComponent,
    SideBarComponent,
    UnderlinerComponent,
    NavbarDirective
  ]
})
export class ShellModule {
}
