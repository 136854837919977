import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/authentication/services';
import { UserChannelComponent } from '@app/channel-media/containers/user-channel/user-channel.component';

const CHANNEL_ROUTES: Routes = [
  {
    path: 'channel/:id',
    component: UserChannelComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(CHANNEL_ROUTES)],
  exports: [RouterModule]
})
export class UserChannelMediaRoutingModule {}
