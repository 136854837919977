<app-comment
  *ngFor="let comment of comments; trackBy: trackById"
  [@highlight]="(scroll$ | async) === comment.id"
  [comment]="comment"
  [media]="media"
  [user]="user"
  [scrollTo]="scrollTo$ | async"
  (addComment)="addComment.emit($event)"
  (likeComment)="like.emit($event)"
  [canAnswer]="canAnswer"
></app-comment>
