import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '@app/shared/shared.module';
import { AuthModule } from '@app/authentication/auth.module';

import {
  ImageProfileComponent,
  ProfileCardComponent
} from '@app/user/components';
import { UserRoutingModule } from '@app/user/user-routing.module';
import { ProfileFacade } from '@app/user/state/profile.facade';
import { FileUploadEffects } from '@app/user/state/effects';
import { reducers } from '@app/user/state/reducers';
import { StoreModule } from '@ngrx/store';
import { ProfileComponent } from '@app/user/containers';
import { UserPipe } from './pipes/user.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    UserRoutingModule,
    RouterModule,
    SharedModule,
    AuthModule,
    FontAwesomeModule,
    EffectsModule.forFeature([FileUploadEffects]),
    StoreModule.forFeature('profile', reducers)
  ],
  declarations: [
    ProfileComponent,
    ProfileCardComponent,
    ImageProfileComponent,
    UserPipe
  ],
  exports: [UserPipe],
  providers: [ProfileFacade, UserPipe]
})
export class UserModule {}
