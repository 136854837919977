import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from '@app/page/containers';
import { AuthGuard, PreHomeDataGuard } from '@app/authentication/services';

const PAGE_ROUTES: Routes = [
  {
    path: 'page/:page',
    component: PageComponent,
    canActivate: [AuthGuard, PreHomeDataGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(PAGE_ROUTES)],
  exports: [RouterModule]
})
export class PageRoutingModule {}
