<div class="menu-content underline-menu text-uppercase">
  <a (click)="view = 'my-content'" [class.active]="view === 'my-content'">Liste de mes contenus</a>
  <a (click)="view = 'other-content'" [class.active]="view === 'other-content'">Contenus d'autres utilisateurs</a>
</div>

<div *ngIf="view === 'my-content'">
  <div class="media-types">
    <div class="underline-menu">
      <a (click)="mediaType = MediaType.VIDEO" [class.active]="mediaType === MediaType.VIDEO">Vidéos</a>
      <a (click)="mediaType = MediaType.PDF" [class.active]="mediaType === MediaType.PDF">Pdf</a>
    </div>
    <app-button-outlined
      icon="assets/images/add.svg"
      text="Importer un contenu"
      [routerLink]="['/medias/upload']"
    ></app-button-outlined>
  </div>

  <app-playlist-medias-form-list
    [playlist]="playlist"
    (playlistChange)="playlistChange.emit($event)"
    [medias]="myMedias.items"
  ></app-playlist-medias-form-list>
</div>

<div *ngIf="view === 'other-content'">


  <div class="other-media-add flex-column">
    <div class="other-media-title">Ajouter un contenu de la plateforme</div>
    <div class="other-media-description">Copier, ci-dessous, le lien de la vidéo ou du pdf de la plateforme que vous
      souhaitez ajouter au parcours.
    </div>
    <div class="other-media-input flex-row">
      <input class="flex-1" [(ngModel)]="searchMedia"/>
      <app-button-outlined
        text="Rechercher"
        (click)="addOtherMedia()"
        [disabled]="!canAddOtherMedia()"
      ></app-button-outlined>
    </div>
  </div>

  <app-playlist-medias-form-list
    [playlist]="playlist"
    (playlistChange)="playlistChange.emit($event)"
    [medias]="allOtherMedias"
    [searching]="searching"
  ></app-playlist-medias-form-list>
</div>
