import { props, createAction } from '@ngrx/store';

import { Affiliations, Professions } from '@app/authentication/models/auth';
import { User } from '../../../user/models/user.model';

export const loadProfessions = createAction('[Auth] Load professions');
export const loadProfessionsSuccess = createAction(
  '[Auth/API] Load professions success',
  props<{ professions: Professions }>()
);
export const loadProfessionsFailure = createAction(
  '[Auth/API] Load professions failure',
  props<{ error: any }>()
);

export const loadAffiliations = createAction('[Auth] Load affiliations');
export const loadAffiliationsSuccess = createAction(
  '[Auth/API] Load affiliations success',
  props<{ affiliations: Affiliations }>()
);
export const loadAffiliationsFailure = createAction(
  '[Auth/API] Load affiliations failure',
  props<{ error: any }>()
);

export const firstUpdateUserInfos = createAction(
  '[Auth] First update user infos',
  props<{ user: Partial<User> }>()
);
export const firstUpdateUserInfosSuccess = createAction(
  '[Auth] First update user infos success'
);
export const firstUpdateUserInfosFailure = createAction(
  '[Auth] First update user infos failure',
  props<{ error: any }>()
);

export const formProfileUpdateUserInfos = createAction(
  '[Auth] From profile update user infos',
  props<{ user: Partial<User> }>()
);
export const fromProfileUpdateUserInfosSuccess = createAction(
  '[Auth] From profile update user infos success'
);
export const fromProfileUpdateUserInfosFailure = createAction(
  '[Auth] From profile update user infos failure',
  props<{ error: any }>()
);

export const login = createAction('[Auth] Login', props<{ value: any }>());
export const loginSuccess = createAction('[Auth] Login succes');
export const loginFailure = createAction(
  '[Auth] Login error',
  props<{ error: any }>()
);
