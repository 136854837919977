import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, EMPTY} from 'rxjs';
import {MediaService} from '../../services';
import {switchMap} from 'rxjs/operators';
import {Media, MediaType} from '../../models';
import {ModalComponent} from '../../../shared/modal/modal/modal.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ToasterService} from 'angular2-toaster';

@UntilDestroy()
@Component({
  selector: 'app-playlist-items',
  templateUrl: './playlist-items.component.html',
  styleUrls: ['./playlist-items.component.scss']
})
export class PlaylistItemsComponent implements OnInit {
  playlistId$ = new BehaviorSubject<number | undefined>(undefined);
  playlist$ = this.playlistId$.pipe(
    switchMap(id => (id ? this.media.getMedia(id) : EMPTY))
  );

  originalPlaylist?: Media;
  pPlaylist?: Media;

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @Output()
  playlistChanged = new EventEmitter<Media>();

  constructor(
    private media: MediaService,
    private change: ChangeDetectorRef,
    private toaster: ToasterService
  ) {
  }

  @Input()
  set playlist(playlist: Media | null) {
    if (!playlist || playlist.type !== MediaType.PLAYLIST) {
      return;
    }

    this.playlistId$.next(playlist.id);
  }

  ngOnInit(): void {
    this.playlist$.pipe(untilDestroyed(this)).subscribe(
      playlist => {
        this.originalPlaylist = playlist;
        this.pPlaylist = JSON.parse(JSON.stringify(this.originalPlaylist)) as Media;
        this.change.markForCheck();
      }
    );
  }

  open() {
    if (this.modal && !this.modal.opened) {
      this.modal.open();
    }
  }

  close() {
    if (this.modal && this.modal.opened) {
      this.modal.close();
    }
  }

  cancel() {
    this.pPlaylist = JSON.parse(JSON.stringify(this.originalPlaylist)) as Media;
    this.change.markForCheck();
  }

  submit() {
    if (!this.pPlaylist) {
      return;
    }

    this.media.updateMedia(this.pPlaylist).subscribe(
      updated => {
        this.playlistChanged.emit(updated);
        this.toaster.pop('success', 'Votre parcours est enregistrée');
      },
      () => {
        this.toaster.pop('error', 'Une erreur est survenue lors de l\'enregistrement');
      }
    );
  }
}
