import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { Page } from '@app/page/models/page';
import { PageFacade } from '@app/page/state/page.facade';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { PageActions } from '@app/page/state/actions';
import { Store } from '@ngrx/store';
import * as fromPage from '@app/page/state/reducers';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageComponent implements OnInit, OnDestroy {
  processing$: Observable<boolean>;
  error$: Observable<boolean>;
  subscription: Subscription;

  constructor(
    private store: Store<fromPage.State>,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscription = this.route.params
      .pipe(map(param => PageActions.loadPage({ page: param.page })))
      .subscribe(action => this.store.dispatch(action));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
