import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaType } from '@app/media/models';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-upload-file-input-form',
  templateUrl: './upload-file-input-form.component.html',
  styleUrls: ['./upload-file-input-form.component.scss']
})
export class UploadFileInputFormComponent {
  maxFileSize = {
    [MediaType.VIDEO]: 1000000000, // 1Go
    [MediaType.PDF]: 10000000, // 10Mo
    [MediaType.PLAYLIST]: 0
  };

  MediaType = MediaType;
  supportedFormats = {
    [MediaType.VIDEO]: {
      // '.mkv': 'video/x-matroska',
      '.mp4': 'video/mp4',
      '.mov': 'video/quicktime'
    },
    [MediaType.PDF]: {
      '.pdf': 'application/pdf'
    },
    [MediaType.PLAYLIST]: {}
  };

  @Input()
  mediaType: MediaType;

  @Output()
  fileChange = new EventEmitter<File>();

  private pFile?: File;

  constructor(public toaster: ToasterService) {}

  get file() {
    return this.pFile;
  }

  set file(file: File | undefined) {
    this.pFile = file;

    if (file !== undefined) {
      if (!this.isSupportedFormat) {
        this.toaster.pop('error', 'Format de fichier non supporté');
        delete this.pFile;
        return;
      }

      if (file.size > this.maxFileSize[this.mediaType]) {
        this.toaster.pop(
          'error',
          `Le fichier est trop volumineux et ne doit pas dépasser les ${this
            .maxFileSize[this.mediaType] / 1000000} Mo`
        );
        delete this.pFile;
        return;
      }
    }

    this.fileChange.emit(file);
  }

  get isSupportedFormat() {
    return (
      this.pFile !== undefined &&
      Object.values(this.supportedFormats[this.mediaType]).findIndex(
        format => (this.pFile as File).type === format
      ) !== -1
    );
  }

  get formats() {
    return Object.keys(this.supportedFormats[this.mediaType]).join(', ');
  }

  get mimeFormats() {
    return Object.values(this.supportedFormats[this.mediaType]).join(', ');
  }

  inputChange(event: Event) {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      this.file = event.target.files[0];
    }
  }

  dropFile(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.file = event.dataTransfer.files[0];
    }
  }

  drag(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
