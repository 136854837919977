import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FallbackRoutingModule } from './fallback-routing.module';
import { NotFoundComponent } from './containers/not-found/not-found.component';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, FallbackRoutingModule]
})
export class FallbackModule {}
