import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, mergeMap, take, tap} from 'rxjs/operators';

import {AuthActions, AuthApiActions, LoginViewActions, RegisterViewActions} from '@app/authentication/state/actions';

import {AuthService} from '@app/authentication/services/auth.service';
import {AuthToken, Credentials} from '@app/authentication/models/auth';
import {User} from '../../../user/models/user.model';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginViewActions.login),
      map(action => action.credentials),
      exhaustMap((credentials: Credentials) =>
        this.service.login(credentials).pipe(
          map(authToken => AuthApiActions.loginSuccess({authToken})),
          catchError(() => of(AuthApiActions.loginFailure({error: true})))
        )
      )
    )
  );
  loadToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadToken),
      exhaustMap(() => {
        return this.service.getToken().pipe(
          map((authToken: AuthToken) =>
            AuthApiActions.loadTokenSuccess({authToken})
          ),
          catchError(error => {
            return of(
              AuthApiActions.loadTokenFailure({error: error.message})
            );
          })
        );
      })
    )
  );
  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.loginSuccess),
        tap(() => {
          if (!this.service.applyRedirect()) {
            this.router.navigate(['/']);
          }
        })
      ),
    {dispatch: false}
  );
  logUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.loadTokenSuccess, AuthApiActions.loginSuccess),
      map(action => action.authToken),
      exhaustMap((authToken: AuthToken) => {
        return this.service
          .setToken(authToken)
          .pipe(map(() => AuthActions.loadLoggedUser()));
      })
    )
  );
  consent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.consent),
      exhaustMap(({user}) => {
        return this.service.consent(user).pipe(
          map(updatedUser => AuthApiActions.consentSuccess({user: updatedUser})),
          catchError(error => {
            return of(
              AuthApiActions.consentFailure({error: error.message})
            );
          })
        );
      })
    )
  );
  loadLoggedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadLoggedUser),
      exhaustMap(() => {
        return this.service.getLoggedUser().pipe(
          map((user: User) => AuthApiActions.loadLoggedUserSuccess({user})),
          catchError(error => {
            return of(
              AuthApiActions.loadLoggedUserFailure({error: error.message})
            );
          })
        );
      })
    )
  );
  loadTokenFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.loadTokenFailure),
      exhaustMap(() => of(AuthActions.logout()))
    )
  );
  loadTokenRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadRefreshToken),
      map(action => action.refreshToken),
      exhaustMap(refreshToken =>
        this.service.refreshTokenWithParam(refreshToken).pipe(
          map(authToken => AuthApiActions.loginSuccess({authToken})),
          catchError(() => of(AuthApiActions.loginFailure({error: true})))
        )
      )
    )
  );
  loadTokenRefreshSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.loadTokenRefreshSuccess),
        take(1),
        tap(() => this.router.navigate(['/']))
      ),
    {dispatch: false}
  );
  loadTokenRefreshFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.loadLoggedUserFailure),
        tap(() => {
          console.log('tokenFail');
          this.service.toSamlLogin();
        })
      ),
    {dispatch: false}
  );
  reloadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.reloadUser),
      exhaustMap(() =>
        this.service.getLoggedUser(true).pipe(
          map((user: User) => AuthApiActions.loadLoggedUserSuccess({user})),
          catchError(error => {
            return of(
              AuthApiActions.loadLoggedUserFailure({error: error.message})
            );
          })
        )
      )
    )
  );
  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterViewActions.register),
      map(action => action.register),
      exhaustMap(register =>
        this.service.register(register).pipe(
          map((userRegistered: User) =>
            AuthApiActions.registerSuccess({userRegistered})
          ),
          catchError(error =>
            of(AuthApiActions.registerFailure({error: error.message}))
          )
        )
      )
    )
  );
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout, AuthApiActions.loadLoggedUserFailure),
      mergeMap(() => {
        // console.log('logout');
        this.service.toSamlLogin();
        return this.service
          .clearToken()
          .pipe(map(() => AuthApiActions.logoutSuccess()));
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private service: AuthService,
    private router: Router
  ) {
  }
}
