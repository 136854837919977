<div
  class="links-bar"
  [class.visible]="(haveExternalLinks || havePrograms) && !inRadarManager"
>
  <!-- List of external links -->
  <ul class="nav-bar" *ngIf="externalLinks$ | async as externalLinks">
    <li *ngFor="let link of externalLinks">
      <a [href]="link.link" target="_blank" rel="noopener">
        <span class="link-image">
          <img [src]="link.iconUrl" [alt]="link.label + ' image'" />
        </span>
        <span class="link-title">{{ link.label }}</span>
      </a>
    </li>
  </ul>
</div>
