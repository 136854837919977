import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeButtonComponent } from './components/subscribe-button/subscribe-button.component';
import { SharedModule } from '@app/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './state/reducers';
import { ChannelEffects } from './state/effects';
import { ChannelFacade } from './state/channel-facade.service';
import { ChannelListComponent } from './components/channel-list/channel-list.component';
import { RouterModule } from '@angular/router';
import { UserModule } from '../user/user.module';

@NgModule({
  declarations: [SubscribeButtonComponent, ChannelListComponent],
  exports: [SubscribeButtonComponent, ChannelListComponent],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([ChannelEffects]),
    StoreModule.forFeature('channel', reducers),
    RouterModule,
    UserModule
  ],
  providers: [ChannelFacade]
})
export class ChannelModule {}
