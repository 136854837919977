import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, take, switchMap } from 'rxjs/operators';

import { User } from '../../user/models/user.model';
import * as fromAuth from '@app/authentication/state/reducers';

@Injectable({
  providedIn: 'root'
})
export class PreHomeDataGuard implements CanActivate {
  public constructor(
    private store: Store<fromAuth.State>,
    private router: Router
  ) {}

  public isUserConnected(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getIsLogged),
      filter((loaded: boolean) => loaded),
      take(1)
    );
  }

  public canActivate(): Observable<boolean> {
    return this.isUserConnected().pipe(
      switchMap(() => this.getLoggeddUser()),
      switchMap((user: User) => {
        if (!user.preHomeData) {
          this.router.navigate(['/pre-home']);
          return of(false);
        }
        return of(true);
      })
    );
  }

  public getLoggeddUser(): Observable<any> {
    return this.store.pipe(select(fromAuth.getLoggedUser), take(1));
  }
}
