<app-icon
  class="upload-image"
  [src]="
    'assets/images/filetype-' +
    (upload.type === UploadType.API_VIDEO ? 'video' : 'pdf') +
    '-alt.svg'
  "
></app-icon>
<div class="progress-container">
  <div class="progress-filename">{{ upload.file.name }}</div>
  <div class="progress-info">
    {{ upload.progress?.startedAt | date: "d MMMM y 'à' H'h'ss" }} ●
    {{
      upload.progress?.progress < 100
        ? (upload | uploadProgress)
        : (upload.file.size | fileSize)
    }}
  </div>
  <div
    class="progress-bar {{ upload.type }}"
    *ngIf="upload.progress?.progress < 100"
  >
    <span [style.width.%]="upload.progress?.progress"></span>
  </div>
</div>
