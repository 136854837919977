<div class="profile-widget">
  <div class="profile-widget-picture-wrapper">
    <app-profile-picture [user]="user"></app-profile-picture>

    <div class="profile-widget-infos">
      <a class="profile-widget-name" [routerLink]="['/channel', user?.id]">
        {{ user.firstname }} {{ user.lastname }}
      </a>

      <a class="profile-widget-signout" (click)="logout.emit()">
        <svg
          id="_11_Star"
          data-name="11 Star"
          xmlns="http://www.w3.org/2000/svg"
          width="9.563"
          height="9.563"
          viewBox="0 0 9.563 9.563"
          fill="#989898"
        >
          <path
            id="Tracé_252"
            data-name="Tracé 252"
            d="M5.155,9.563H.467A.467.467,0,0,1,0,9.1V.467A.467.467,0,0,1,.467,0H5.155a.467.467,0,1,1,0,.934H.934v7.7H5.155a.467.467,0,1,1,0,.934Z"
            transform="translate(0 0)"
          />
          <path
            id="Tracé_253"
            data-name="Tracé 253"
            d="M167.949,231.934h-5.482a.467.467,0,1,1,0-.934h5.482a.467.467,0,1,1,0,.934Z"
            transform="translate(-158.974 -226.685)"
          />
          <path
            id="Tracé_254"
            data-name="Tracé 254"
            d="M335.541,110.145a.467.467,0,0,1-.33-.8l2.039-2.032-2.039-2.032a.467.467,0,1,1,.659-.661l2.371,2.363a.467.467,0,0,1,0,.662l-2.371,2.363A.465.465,0,0,1,335.541,110.145Z"
            transform="translate(-328.815 -102.533)"
          />
        </svg>
        Se déconnecter
      </a>
    </div>
  </div>
  <div class="profile-widget-body">
    <a
      class="profile-widget-body-row"
      routerLink="/profile"
      (click)="routing.emit()"
    >
      <div class="profile-widget-body-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.894"
          height="16.894"
          viewBox="0 0 16.894 16.894"
        >
          <g id="Layer_14" data-name="Layer 14" transform="translate(-2 -2)">
            <circle
              id="Ellipse_36"
              data-name="Ellipse 36"
              cx="4.022"
              cy="4.022"
              r="4.022"
              transform="translate(6.425 2)"
              fill="#989898"
            />
            <path
              id="Tracé_258"
              data-name="Tracé 258"
              d="M11.3,13H9.594A7.618,7.618,0,0,0,2,20.642a.4.4,0,0,0,.4.4H18.491a.442.442,0,0,0,.4-.45A7.61,7.61,0,0,0,11.3,13Z"
              transform="translate(0 -2.151)"
              fill="#989898"
            />
          </g>
        </svg>
      </div>
      <div class="profile-widget-body-text">Mon profil</div>
    </a>
    <a
      class="profile-widget-body-row"
      routerLink="/page/faq"
      (click)="routing.emit()"
    >
      <div class="profile-widget-body-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.16"
          height="17.16"
          viewBox="0 0 17.16 17.16"
        >
          <path
            id="question-4"
            d="M8.58,0a8.58,8.58,0,1,0,8.58,8.58A8.59,8.59,0,0,0,8.58,0Zm0,13.585A.715.715,0,1,1,9.3,12.87.715.715,0,0,1,8.58,13.585ZM9.712,9.039a.718.718,0,0,0-.417.65v.321a.715.715,0,1,1-1.43,0V9.689A2.154,2.154,0,0,1,9.113,7.74a2.337,2.337,0,0,0,1.255-1.662,1.787,1.787,0,1,0-3.575,0,.715.715,0,1,1-1.43,0,3.218,3.218,0,0,1,6.435,0A3.726,3.726,0,0,1,9.712,9.039Zm0,0"
            fill="#989898"
          />
        </svg>
      </div>
      <div class="profile-widget-body-text">FAQ</div>
    </a>
  </div>
</div>
