<div class="title" [class.video]="mediaType === MediaType.VIDEO">
  <app-icon
    [src]="
      mediaType === MediaType.VIDEO
        ? 'assets/images/filetype-video.svg'
        : 'assets/images/filetype-pdf.svg'
    "
  ></app-icon>
  <span>
    {{
      mediaType === MediaType.VIDEO ? 'Partager une vidéo' : 'Partage un pdf'
    }}
  </span>
</div>
<div class="drop-zone" (drop)="dropFile($event)" (dragover)="drag($event)">
  <!--  Hidden -->
  <input
    type="file"
    class="hide"
    (change)="inputChange($event)"
    [accept]="mimeFormats"
    #input
  />

  <span class="drop-text">Glissez votre fichier ici</span>

  <app-icon
    class="upload-image"
    (click)="input.click()"
    [src]="
      file && isSupportedFormat
        ? 'assets/images/upload-file-' +
          (mediaType === MediaType.VIDEO ? 'video' : 'pdf') +
          '.svg'
        : 'assets/images/upload-drop-zone.svg'
    "
  ></app-icon>

  <span class="file-name">{{ file !== undefined ? file.name : '' }}</span>
  <span class="formats">Formats acceptés : {{ formats }}</span>

  <app-button-outlined
    *ngIf="file === undefined"
    (click)="input.click()"
    text="Ou importer un fichier"
  ></app-button-outlined>
  <app-button-outlined
    *ngIf="file !== undefined"
    (click)="file = undefined"
    text="Supprimer le fichier"
  ></app-button-outlined>
</div>
