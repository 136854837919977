<div infiniteScroll (scrolled)="loadMore()">
  <h1 class="header {{ color }}-bg">
    <app-icon src="assets/images/library.svg" class="white-fill"></app-icon>
    {{ title }}
  </h1>
  <app-media-research-form
    [search]="searchForm$ | async"
    [config]="searchConfig$ | async"
    (searchChange)="search($event)"
  ></app-media-research-form>

  <div class="media-list">
    <app-media-list
      *ngIf="medias && !!medias.items?.length; else noMedias"
      [medias]="medias.items"
    ></app-media-list>
  </div>
</div>

<ng-template #noMedias>
  <div class="no-media">
    <span>Aucun média de présent dans cette rubrique.</span>
  </div>
</ng-template>
