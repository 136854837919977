import { createAction, props } from '@ngrx/store';
import { Collection } from '@app/shared/models/collection';
import { Comment } from '@app/comment/models/comment.model';

const TAG = '[Comment/Api]';

export const loadCommentsSuccess = createAction(
  `${TAG} Load comments SUCCESS`,
  props<{ comments: Collection<Comment> }>()
);

export const loadCommentsFailure = createAction(
  `${TAG} Load comments FAILURE`,
  props<{ error: any }>()
);

export const addCommentSuccess = createAction(
  `${TAG} Add comment SUCCESS`,
  props<{ comment: Comment }>()
);

export const addCommentFailure = createAction(
  `${TAG} Add comment FAILURE`,
  props<{ error: any }>()
);

export const likeCommentSuccess = createAction(
  `${TAG} Like comment SUCCESS`,
  props<{ id: number; likeCount: number }>()
);

export const likeCommentFailure = createAction(
  `${TAG} Like comment FAILURE`,
  props<{ error: any }>()
);

export const unlikeCommentSuccess = createAction(
  `${TAG} Unlike comment SUCCESS`,
  props<{ id: number; likeCount: number }>()
);

export const unlikeCommentFailure = createAction(
  `${TAG} Unlike comment FAILURE`,
  props<{ error: any }>()
);
