import { createReducer, on } from '@ngrx/store';
import {
  Collection,
  defaultCollection
} from '../../../shared/models/collection';
import { UserFollow } from '../../../user/models/user-follow.model';
import { ChannelActions, ChannelApiActions } from '../actions';

export interface State {
  followedUsers: Collection<UserFollow>;
  followedUser?: UserFollow;
  unfollowedUser?: UserFollow;

  loading: boolean;
  loaded: boolean;
}

export const INITIAL_STATE: State = {
  followedUsers: defaultCollection<UserFollow>(),
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(
    ChannelActions.loadFollowedUser,
    ChannelActions.follow,
    ChannelActions.unfollow,
    state => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),
  on(ChannelApiActions.loadFollowedUserSuccess, (state, { followedUsers }) => ({
    ...state,
    followedUsers,
    loading: false,
    loaded: true
  })),
  on(ChannelApiActions.loadFollowedUserFailure, state => ({
    ...state,
    followedUsers: defaultCollection<UserFollow>(),
    loading: false,
    loaded: false
  })),
  on(ChannelApiActions.followSuccess, (state, { follow }) => ({
    ...state,
    followedUser: follow,
    loading: false,
    loaded: true
  })),
  on(ChannelApiActions.followFailure, state => ({
    ...state,
    followedUser: undefined,
    loading: false,
    loaded: false
  })),
  on(ChannelApiActions.unfollowSuccess, (state, { unfollow }) => ({
    ...state,
    unfollowedUser: unfollow,
    loading: false,
    loaded: true
  })),
  on(ChannelApiActions.unfollowFailure, state => ({
    ...state,
    unfollowedUser: undefined,
    loading: false,
    loaded: false
  }))
);

export const getFollowedUsers = (state: State) => state.followedUsers;
export const getFollowedUser = (state: State) => state.followedUser;
export const getUnfollowedUser = (state: State) => state.unfollowedUser;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
