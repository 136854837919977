import { Media } from '@app/media/models';
import { ApiEntity } from '@app/shared/models/api-entity';
import {getColorForNumber, themeColors} from '../../shared/models/colors';

export interface Category extends ApiEntity {
  id: number;
  name: string;
  medias?: Media[];
}

export const categorySpecificColorForIds: {[key: number]: string} = {
  19: 'lime'
};

export function getColorForCategory(id: number) {
  return categorySpecificColorForIds[id] ?? getColorForNumber(id);
}
