import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromUpload from '@app/upload/state/reducers';
import { UploadActions } from '@app/upload/state/actions';
import { uploadFactory, UploadType } from '@app/upload/models/upload.model';
import { map } from 'rxjs/operators';
import { Params } from '@angular/router';

@Injectable()
export class UploadFacade {
  uploads$ = this.store.pipe(select(fromUpload.getUploads));

  constructor(private store: Store<fromUpload.State>) {}

  uploadFile(file: File, type: UploadType, params?: Params) {
    const upload = uploadFactory(file, type, params);
    this.store.dispatch(UploadActions.uploadFile({ upload }));

    return this.uploads$.pipe(
      map(uploads => uploads.find(up => up.id === upload.id)),
      map(upl => (upl === undefined ? null : upl))
    );
  }
}
