<app-toaster></app-toaster>
<app-http-progress-bar></app-http-progress-bar>
<app-consent-modal *ngIf="isNotConsentedUserAndLogged$ | async"></app-consent-modal>

<div
  class="app-backdrop"
  *ngIf="opened$ | async"
  (click)="onToggleMenu()"
></div>

<main
  class="app-main"
  [class.navbar-padding]="isConsentedUser$ | async"
  [class.forceSidebarHidden]="(displaySidebar$ | async) === false"
>
  <ng-container *ngIf="isConsentedUserOrOffline$ | async; else splash">
    <router-outlet #outlet="outlet"></router-outlet>
    <ng-container *ngIf="!outlet.isActivated" [ngTemplateOutlet]="splash"></ng-container>
  </ng-container>

  <ng-template #splash>
    <div class="index-template">
      <div class="index-template-wrapper">
        <img src="assets/images/logo.png?270224" alt="logo image">
      </div>
    </div>
  </ng-template>
</main>

<app-navbar
  *ngIf="isConsentedUser$ | async"
  [pageTitle]="pageTitle$ | async"
  [opened]="opened$ | async"
  [loggedUser]="loggedUser$ | async"
  (logout)="displayLogout()"
  (toggleMenu)="onToggleMenu()"
  [previousLink]="previousLink$ | async"
  [menu]="userMenu$ | async"
></app-navbar>

<app-side-bar
  *ngIf="isConsentedUser$ | async"
  [visible]="opened$ | async"
  [categories]="categories$ | async"
  [followedUsers]="followedUsers$ | async"
  [class.forceHidden]="(displaySidebar$ | async) === false"
></app-side-bar>

<app-footer
  *ngIf="isConsentedUserOrOffline$ | async"
  [class.navbar-padding]="loggedUser$ | async"
  [class.forceSidebarHidden]="(displaySidebar$ | async) === false"
></app-footer>

