<table *ngIf="connected$ | async as user">
  <thead>
    <tr>
      <th class="thumbnail">Miniature</th>
      <th class="description">Titre et description</th>
      <th class="position">Affiché en</th>
      <th class="action"></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngIf="searching">
      <td colspan="4">
        <div class="other-media-search flex-row align-items-center justify-content-center">
          <span>Recherche de la vidéo en cours...</span>
        </div>
      </td>
    </tr>
    <tr *ngFor="let media of medias; trackBy: trackByMediaId">
      <ng-container *ngIf="{item: getItemForMedia(media)} as data">
        <!-- Thumbnail -->
        <td class="thumbnail">
          <a class="wrapper" [routerLink]="media | mediaRoute">
            <div class="media-view">
              <app-media-view [media]="media"></app-media-view>
            </div>
          </a>
        </td>

        <!-- Title/Desc -->
        <td class="description">
          <div class="media-info">
            <a
              class="media-title"
              [routerLink]="media | mediaRoute"
              [title]="media.title"
            >
              {{ media.title }}
            </a>

            <div class="media-description" *ngIf="media.creator.id === user.id">
              <div *nestedEllipsis="true" [innerHTML]="media.description"></div>
            </div>

            <div class="media-description" *ngIf="media.creator.id !== user.id">
              <app-media-creator [media]="media" theme="light"></app-media-creator>
            </div>
          </div>
        </td>

        <!-- Position -->
        <td class="position">
          <app-select-position
            *ngIf="data.item"
            [count]="data.item.position"
            (countChange)="changeItemPosition(data.item, $event)"
          ></app-select-position>
        </td>

        <!-- Actions -->
        <td class="action">
          <app-button-outlined
            *ngIf="!data.item"
            icon="assets/images/add.svg"
            text="Ajouter"
            (click)="addMedia(media)"
          ></app-button-outlined>

          <div *ngIf="data.item" class="playlist-item-action">
            <div class="already">
              Déjà présent
              <app-icon src="assets/images/check-small.svg" class="green-fill"></app-icon>
            </div>

            <a class="remove" (click)="removePlaylistItem(data.item)">
              Supprimer du parcours
              <app-icon src="assets/images/remove.svg"></app-icon>
            </a>
          </div>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
