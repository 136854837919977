import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromRoot from '@app/core/state/reducers';
import * as fromTag from '@app/tag/state/reducers/tag.reducer';

export interface State extends fromRoot.State {
  tag: fromTag.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    tag: fromTag.reducer
  })(state, action);
}

export const selectTagState = createFeatureSelector<State>('tag');

export const getTagState = createSelector(
  selectTagState,
  customTag => customTag.tag
);

export const getTags = createSelector(getTagState, fromTag.getTags);

export const getLoading = createSelector(getTagState, fromTag.getLoading);

export const getLoaded = createSelector(getTagState, fromTag.getLoaded);
