<div
  class="media"
  *ngIf="{
    media: media$ | async,
    extraData: mediaExtraData$ | async,
    user: user$ | async
  } as data"
>
  <app-media-view
    [media]="data.media"
    [extraData]="data.extraData"
    [autoplay]="true"
    (videoEvent)="videoEvent($event)"
  >
    <app-next-media-timer
      *ngIf="autoNext"
      (timerCanceled)="closeAutoNext()"
      (timerNext)="nextVideoOnPlaylist()"
      (timerEnded)="nextVideoOnPlaylist()"
    ></app-next-media-timer>
  </app-media-view>

  <div class="media-detail">
    <h1>{{ data.media?.title }}</h1>

    <div class="media-actions">
      <app-media-action
        *ngIf="data.user && data.media && data.extraData"
        [media]="data.media"
        [extraData]="data.extraData"
        [user]="data.user"
        (likeMedia)="likeMedia(data.media.id, $event)"
      ></app-media-action>

      <app-report-button
        *ngIf="data.media"
        [media]="data.media"
      ></app-report-button>

      <app-subscribe-button
        *ngIf="data.media && data.user.id !== data.media.creator.id"
        [userId]="data.media.creator.id"
      ></app-subscribe-button>

      <app-media-share-button
        *ngIf="data.media"
        [media]="data.media"
      ></app-media-share-button>
    </div>

    <div class="media-info">
      <div
        *ngIf="data.media"
        class="media-description"
        [innerHTML]="data.media.description"
      ></div>
      <app-media-creator
        *ngIf="data.media"
        [media]="data.media"
        theme="dark"
      ></app-media-creator>
    </div>

    <app-comments></app-comments>
  </div>
</div>

<div class="right-bar" *ngIf="media$ | async as media">
  <div class="wrapper">
    <ng-container *ngIf="associatedPlaylists$ | async as playlists">
      <app-playlist-media-list
        class="medias-playlists medias-panel"
        *ngIf="playlists.length > 0"
        [playlists]="playlists"
        [media]="media"
      ></app-playlist-media-list>
    </ng-container>

    <div class="media-upload-button">
      <app-upload-widget
        [routerLink]="['/medias/upload']"
        alternate="true"
      ></app-upload-widget>
    </div>

    <div
      class="medias-suggested medias-panel"
      *ngIf="suggestedMedias$ | async as medias"
    >
      <span>Suggestion de médias</span>

      <app-media-vertical-list
        class="media-vertical-list"
        [medias]="medias"
        size="small"
        [description]="false"
      ></app-media-vertical-list>

      <app-media-slider class="media-list" [medias]="medias"></app-media-slider>
    </div>
  </div>
</div>
