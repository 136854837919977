import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';

import {
  Affiliation,
  Affiliations,
  BusinessUnit,
  Jobs,
  Professions,
  TeamAgencies
} from '@app/authentication/models/auth';
import { User } from '../../../user/models/user.model';
import { FirstConnectionService } from '@app/authentication/services/first-connection.service';

@Component({
  selector: 'app-first-connection-form',
  templateUrl: './first-connection-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstConnectionFormComponent implements OnInit {
  @Input() professions: Professions;
  @Input() user: User;
  @Input() fromProfile: boolean;
  @Output() save = new EventEmitter<any>();
  @Output() edit = new EventEmitter<void>();
  businessUnits: BusinessUnit[] = [];
  teamAgencies: TeamAgencies;
  jobs: Jobs;
  subordinationNames = [
    { id: 'collaborator', name: 'Collaborateur' },
    { id: 'manager', name: 'Manager' }
  ];
  editing = false;
  submitted = false;
  firstConnectionForm: FormGroup;
  private allAffiliations: Affiliations;

  constructor(
    private formBuilder: FormBuilder,
    private config: NgSelectConfig,
    private service: FirstConnectionService,
    private change: ChangeDetectorRef
  ) {
    this.config.loadingText = 'Chargement...';
    this.config.notFoundText = 'Aucun élément correspondant';
  }

  @Input()
  set affiliations(affiliations: Affiliations) {
    this.allAffiliations = affiliations;

    if (!!this.allAffiliations) {
      this.enableAffiliationForm();
    }
  }

  get affiliation(): FormControl {
    return this.firstConnectionForm.get('affiliation') as FormControl;
  }

  get businessUnit(): FormControl {
    return this.firstConnectionForm.get('businessUnit') as FormControl;
  }

  get teamAgency(): FormControl {
    return this.firstConnectionForm.get('teamAgency') as FormControl;
  }

  get job(): FormControl {
    return this.firstConnectionForm.get('job') as FormControl;
  }

  get subordinationName(): FormControl {
    return this.firstConnectionForm.get('subordinationName') as FormControl;
  }

  ngOnInit() {
    this.service.saveModification$.subscribe(saved => {
      if (saved) {
        this.onSubmit();
      } else {
        this.initForm();
        this.change.detectChanges();
      }
    });

    this.initForm();
  }

  initForm() {
    const professionId = this.user.profession ? this.user.profession.id : null;
    const affiliationId = this.user.affiliation
      ? this.user.affiliation.id
      : null;
    const businessUnitId = this.user.businessUnit
      ? this.user.businessUnit.id
      : null;
    const teamAgencyId = this.user.teamAgency ? this.user.teamAgency.id : null;
    const jobId = this.user.job ? this.user.job.id : null;
    const subordinationNameValue = this.user.subordinationName
      ? this.user.subordinationName
      : null;

    if (this.user.preHomeData) {
      const config: any = {
        profession: [professionId, Validators.required],
        affiliation: [
          { value: affiliationId, disabled: true },
          Validators.required
        ],
        businessUnit: [
          { value: businessUnitId, disabled: true },
          Validators.required
        ],
        teamAgency: [
          { value: teamAgencyId, disabled: true },
          Validators.required
        ],
        job: [{ value: jobId, disabled: true }, Validators.required],
        subordinationName: [subordinationNameValue, Validators.required]
      };

      if (!this.fromProfile) {
        config.checkValidData = [false, Validators.required];
      }

      this.firstConnectionForm = this.formBuilder.group(config);

      this.affiliation.valueChanges.subscribe(() =>
        this.businessUnit.setValue(null)
      );
      this.businessUnit.valueChanges.subscribe(() => {
        this.teamAgency.setValue(null);
        this.job.setValue(null);
      });

      this.businessUnits = this.user.businessUnit
        ? [this.user.businessUnit]
        : [];
      this.teamAgencies = this.user.teamAgency ? [this.user.teamAgency] : [];
      this.jobs = this.user.job ? [this.user.job] : [];
    } else {
      this.firstConnectionForm = this.formBuilder.group({
        profession: [professionId, Validators.required],
        subordinationName: [subordinationNameValue, Validators.required],
        checkValidData: [false, Validators.required]
      });
    }
  }

  enableAffiliationForm() {
    const affiliationId = this.user.affiliation
      ? this.user.affiliation.id
      : null;
    const businessUnitId = this.user.businessUnit
      ? this.user.businessUnit.id
      : null;

    this.onAffiliationChange(affiliationId);
    this.onBusinessUnitChange(businessUnitId);
    this.formSetDisabled('affiliation', false);
    this.formSetDisabled('businessUnit', false);
    this.formSetDisabled('teamAgency', false);
    this.formSetDisabled('job', false);
  }

  formSetDisabled(controlName: string, disabled: boolean) {
    const control = this.firstConnectionForm.get(controlName);

    if (control) {
      if (disabled) {
        control.disable();
      } else {
        control.enable({ onlySelf: true, emitEvent: false });
      }
    }
  }

  getAffiliations(): Affiliations {
    return !!this.allAffiliations
      ? this.allAffiliations
      : this.user.affiliation
      ? [this.user.affiliation]
      : [];
  }

  getBuisnessUnit(): BusinessUnit[] {
    return !!this.businessUnits ? this.businessUnits : [];
  }

  getTeamAgencies(): TeamAgencies {
    return !!this.teamAgencies ? this.teamAgencies : [];
  }

  getJobs(): Jobs {
    return !!this.jobs ? this.jobs : [];
  }

  isLoading() {
    return this.editing && !this.allAffiliations;
  }

  isEditing() {
    return this.editing && !!this.allAffiliations;
  }

  startEditing() {
    if (this.editing) {
      return;
    }

    this.editing = true;
    this.edit.emit();
  }

  getIsFieldValueNotNull(nameField: string): boolean {
    const field = this.firstConnectionForm.get(nameField) as FormControl;
    if (field && field.errors) {
      return field.errors.required && field.touched;
    }
    return false;
  }

  onSubmit() {
    this.submitted = true;

    if (this.firstConnectionForm.valid && this.user) {
      this.save.emit({ ...this.firstConnectionForm.value, id: this.user.id });
    }
  }

  onAffiliationChange(value: number) {
    if (value && this.getAffiliations()) {
      this.businessUnits = (this.getAffiliations().find(
        affiliation => affiliation.id === value
      ) as Affiliation).businessUnits;
      this.teamAgencies = null;
      this.jobs = null;
    }
  }

  onBusinessUnitChange(value: number) {
    if (value) {
      const buisnessUnit = this.businessUnits.find(
        bu => bu.id === value
      ) as BusinessUnit;

      if (!buisnessUnit) {
        return;
      }

      this.teamAgencies = buisnessUnit.teamAgencies;
      this.jobs = buisnessUnit.jobs;
    }
  }
}
