import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PlaylistItem} from '@app/media/models';
import {ModalComponent} from '@app/shared/modal/modal/modal.component';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-playlist-media-remove',
  templateUrl: './playlist-media-remove.component.html',
  styleUrls: ['./playlist-media-remove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaylistMediaRemoveComponent {
  @Output()
  mediaRemove = new EventEmitter<void>();

  @ViewChild(ModalComponent)
  modal: ModalComponent;

  @Input()
  item: PlaylistItem;

  get media() {
    return this.item?.mediaItem;
  }

  open() {
    if (this.modal && !this.modal.opened) {
      this.modal.open();
    }
  }

  close() {
    if (this.modal && this.modal.opened) {
      this.modal.close();
    }
  }

  remove() {
    this.mediaRemove.emit();
  }
}
