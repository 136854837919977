import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../../user/models/user.model';
import { getColorForNumber, themeColors } from '@app/shared/models/colors';

@Component({
  selector: 'app-profile-picture',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent {
  @Input() user: User;
  @Input() hover = false;
  @Input() active = false;
  @Input() route = true;

  colors = themeColors;

  get color() {
    return this.user ? getColorForNumber(this.user.id) : 'green';
  }

  get hasPicture() {
    return (
      this.user &&
      this.user.avatarUrl &&
      this.user.avatarUrl.indexOf('placeholder_profile') === -1
    );
  }

  get firstname() {
    return this.user?.firstname ? this.user.firstname?.substr(0, 1) : '';
  }

  get lastname() {
    return this.user?.lastname ? this.user.lastname?.substr(0, 1) : '';
  }

  get shortName() {
    return this.firstname + this.lastname;
  }
}
