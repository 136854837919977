<div class="widget-container" [class.alternate]="alternate">
  <div class="text-container">
    <span class="text-pre1">Et pourquoi pas</span>
    <span class="text-pre2">Vous ?</span>
    <span class="text-main">
      Vous souhaitez partager vos connaissances en vidéo ?
    </span>
  </div>
  <div class="circle">
    <app-icon src="/assets/images/add.svg"></app-icon>
  </div>
</div>
