import {Injectable} from '@angular/core';
import {HttpService} from '@app/core/services';
import {Observable, of} from 'rxjs';
import {Media, MediaExtraData, MediaSearchModel, MediaSearchOrder, MediaType} from '@app/media/models';
import {FeaturedMedia} from '../models/highlighted-media.model';
import {ApiCollection} from '@app/shared/models/api-collection';
import {Collection, collectionFromApi} from '@app/shared/models/collection';
import {map, switchMap} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {UploadFacade} from '@app/upload/state/upload-facade.service';
import {UploadType} from '@app/upload/models/upload.model';
import {UserService} from '@app/user/services/user.service';
import {TagService} from '@app/tag/services';
import {Tag} from '@app/tag/models/tag.model';
import {User} from '../../user/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  public constructor(
    private httpService: HttpService,
    private uploadFacade: UploadFacade,
    private userService: UserService,
    private tagService: TagService
  ) {
  }

  static normalize(media: Partial<Media>): any {
    return {
      ...media,
      tags: media.tags ? media.tags.map(tag => tag['@id']) : [],
      categories: media.categories
        ? media.categories.map(category => category['@id'])
        : [],
      creator: (media.creator as User)['@id'],
      playlistItems: media.playlistItems ? media.playlistItems.map(item => ({
        ...item,
        mediaItem: item.mediaItem['@id']
      })) : []
    };
  }

  public getMedias(
    params: { [param: string]: string } = {},
    search?: MediaSearchModel
  ): Observable<Collection<Media>> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(
      param => (httpParams = httpParams.set(param, params[param]))
    );

    return this.httpService
      .get<ApiCollection<Media>>(
        '/media',
        search ? this.prepareSearch(search, httpParams) : httpParams
      )
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public getMediasByHistory(
    search: MediaSearchModel,
    params = new HttpParams()
  ): Observable<Collection<Media>> {
    params = this.prepareSearch(search, params);
    return this.httpService
      .get<ApiCollection<Media>>('/media/user_history', params)
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public getMyMedias(
    search: MediaSearchModel,
    params = new HttpParams()
  ): Observable<Collection<Media>> {
    params = this.prepareSearch(search, params);
    return this.httpService
      .get<ApiCollection<Media>>('/media/user_medias', params)
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public getLikedMedias(
    search: MediaSearchModel,
    params = new HttpParams()
  ): Observable<Collection<Media>> {
    params = this.prepareSearch(search, params);
    return this.httpService
      .get<ApiCollection<Media>>('/media/user_likes', params)
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public getResumeMedias(
    search: MediaSearchModel,
    params = new HttpParams()
  ): Observable<Collection<Media>> {
    params = this.prepareSearch(search, params);
    return this.httpService
      .get<ApiCollection<Media>>('/media/user_resume', params)
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public loadRandomMedias(): Observable<Collection<Media>> {
    return this.httpService
      .get<ApiCollection<Media>>('/media/random')
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public loadSuggestedMedias(): Observable<Collection<Media>> {
    return this.httpService
      .get<ApiCollection<Media>>('/media/user_suggested')
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public loadRecommendedMedias(): Observable<Collection<FeaturedMedia>> {
    return this.httpService
      .get<ApiCollection<FeaturedMedia>>('/recommended_media/me')
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public loadTopMedias(): Observable<Collection<Media>> {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    let params = new HttpParams();
    params = params.append('afterViewedAt', date.toISOString());

    return this.httpService
      .get<ApiCollection<Media>>('/media/top_list', params)
      .pipe(map(apiCollection => collectionFromApi(apiCollection)))
      .pipe(
        map(collection => ({
          count: collection.count > 10 ? 10 : collection.count,
          items: collection.items.slice(0, 10)
        }))
      );
  }

  public getMedia(id: number): Observable<Media> {
    return this.httpService.get<Media>(`/media/${id}`);
  }

  public removeMedia(id: number): Observable<Media> {
    return this.httpService.delete(`/media/${id}`);
  }

  public createMedia(media: Partial<Media>): Observable<Media> {
    return this.httpService.post<Media>(
      `/media`,
      MediaService.normalize(media)
    );
  }

  public updateMedia(media: Media): Observable<Media> {
    return this.httpService.put<Media>(
      `/media/${media.id}`,
      MediaService.normalize(media)
    );
  }

  public likeMedia(id: number): Observable<{ likeCount: number }> {
    return this.httpService.put<{ likeCount: number }>(
      `/media/${id}/user_liked`,
      {}
    );
  }

  public unlikeMedia(id: number): Observable<{ likeCount: number }> {
    return this.httpService.put<{ likeCount: number }>(
      `/media/${id}/user_unliked`,
      {}
    );
  }

  public saveMediaPosition(id: number, videoPosition: number): Observable<{ currentUserLastVideoPosition: number }> {
    return this.httpService.put<{ currentUserLastVideoPosition: number }>(
      `/media/${id}/user_video_position`,
      {videoPosition}
    );
  }

  public prepareSearch(
    search: MediaSearchModel,
    params = new HttpParams()
  ): HttpParams {
    if (search.query) {
      params = params.append('query', search.query);
    }

    if (search.page) {
      params = params.append('page', search.page.toString());
    }

    if (search.order.by !== MediaSearchOrder.PERTINENCE) {
      params = params.append(
        `order[${search.order.by}]`,
        search.order.direction
      );
    }

    search.mediaTypes.forEach(type => {
      params = params.append('type[]', type);
    });

    return params;
  }

  public searchMedias(
    search: MediaSearchModel,
    params = new HttpParams()
  ): Observable<Collection<Media>> {
    params = this.prepareSearch(search, params);

    return this.httpService
      .get<ApiCollection<Media>>('/media/search', params)
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public loadMediaExtraData(id: number, highlightId?: number): Observable<MediaExtraData> {
    return this.httpService.get<MediaExtraData>(`/media/${id}/user_extra_data`, {
      ...(highlightId !== undefined ? {recommendedMediaId: highlightId.toString()} : {})
    });
  }

  public loadFeaturedMedias(): Observable<Collection<FeaturedMedia>> {
    return this.httpService
      .get<ApiCollection<FeaturedMedia>>(`/featured_media/me`)
      .pipe(map(apiCollection => collectionFromApi(apiCollection)));
  }

  public replace(media: Partial<Media>): Observable<Media> {
    return this.httpService.post<Media>(`/media`, media);
  }

  public uploadMediaTags<T extends Media = Media>(media: T): Observable<T> {
    return media.tags === undefined
      ? of(media)
      : of(media.tags).pipe(
        map(tags => tags.filter(tag => tag['@id'] === undefined)),
        switchMap(toAddTags =>
          toAddTags.length ? this.tagService.createTags(toAddTags) : of([])
        ),
        map(tagsAdded => [
          ...(media.tags as Tag[]).filter(tag => tag['@id'] !== undefined),
          ...tagsAdded
        ]),
        map(tags => ({
          ...media,
          tags
        }))
      );
  }

  public uploadMedia(file: File, mediaType: MediaType) {
    const uploadType =
      mediaType === MediaType.VIDEO
        ? UploadType.API_VIDEO
        : UploadType.MEDIA_PDF;
    let params$ = of({});

    if (uploadType === UploadType.API_VIDEO) {
      params$ = this.userService
        .generateApiVideoUploadTokenForConnected()
        .pipe(map(data => ({token: data.lastApiVideoUploadToken})));
    }

    return params$.pipe(
      switchMap(params =>
        this.uploadFacade.uploadFile(file, uploadType, params)
      )
    );
  }
}
