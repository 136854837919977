import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-medias-search-input',
  templateUrl: './medias-search-input.component.html',
  styleUrls: ['./medias-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediasSearchInputComponent implements OnInit {
  query = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private change: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(map(params => (params.query ? params.query : '')))
      .subscribe(query => {
        this.query = query;
        this.change.detectChanges();
      });
  }

  search(query: string) {
    this.router.navigate(['medias/search'], {
      queryParams: { query },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
