import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Media} from '@app/media/models';
import {MediaTileComponent} from '@app/media/components/media-tile/media-tile.component';
import {BehaviorSubject} from 'rxjs';
import {pairwise} from 'rxjs/operators';
import {MediaTopTileComponent} from '@app/media/components/media-top-tile/media-top-tile.component';
import {Slider} from '@app/media/models/slider';
import {HighlightedMedia} from '../../models/highlighted-media.model';

@Component({
  selector: 'app-media-slider',
  templateUrl: './media-slider.component.html',
  styleUrls: ['./media-slider.component.scss']
})
export class MediaSliderComponent implements OnInit, AfterViewInit {
  canScrollLeft$ = new BehaviorSubject<boolean>(false);
  canScrollRight$ = new BehaviorSubject<boolean>(false);
  viewInit = false;

  @ViewChild('wrapper', {read: ElementRef})
  wrapper: ElementRef;

  @Input()
  highlight?: Media;

  @Input()
  playlist?: Media;

  @Input()
  cardLink = false;

  @Input()
  displayType: 'top' | 'classic' = 'classic';

  @ViewChildren(MediaTileComponent)
  classicItems: QueryList<MediaTileComponent>;

  @ViewChildren(MediaTopTileComponent)
  topItems: QueryList<MediaTopTileComponent>;

  @Input()
  margin = 0;

  @Input()
  itemsMargin = 7;

  @Input()
  buttonMargin = -20;

  buttonWidth = 40;

  private pMedias: (Media | HighlightedMedia)[] = [];

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  get medias() {
    return this.pMedias;
  }

  @Input()
  set medias(medias: (Media | HighlightedMedia)[]) {
    this.pMedias = medias;
    this.onResize();
  }

  get containerWidth(): number {
    return (document.querySelector('.medias') as Element).clientWidth;
  }

  get items(): QueryList<Slider> {
    switch (this.displayType) {
      case 'classic':
      default:
        return this.classicItems;
      case 'top':
        return this.topItems;
    }
  }

  ngOnInit() {
    this.canScrollLeft$.pipe(pairwise()).subscribe(([previous, current]) => {
      if (previous !== current) {
        this.wrapper.nativeElement.style.scrollBehavior = 'auto';
        // this.wrapper.nativeElement.scrollLeft +=
        //   (current ? 1 : -1) * (this.buttonWidth + this.buttonMargin);
      }
    });
  }

  ngAfterViewInit(): void {
    this.viewInit = true;
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    if (!this.viewInit) {
      return;
    }

    setTimeout(() => {
      this.canScrollRight$.next(!!this.getLastVisibleRight());
      this.canScrollLeft$.next(!!this.getLastVisibleLeft());
      this.changeDetector.detectChanges();
    });
  }

  itemScrollRight(item: Slider) {
    return (
      item.positionRight - this.containerWidth + this.itemsMargin + this.margin
    );
  }

  itemScrollLeft(item: Slider) {
    return item.positionLeft - this.itemsMargin - this.margin;
  }

  getLastVisibleRight() {
    const elem = this.getVisibleRight();

    if (!elem) {
      return null;
    }

    return elem[0];
  }

  getVisibleRight() {
    return this.items.filter(value => {
      return (
        value.positionRight -
        this.wrapper.nativeElement.scrollLeft -
        this.itemsMargin -
        this.margin >
        this.containerWidth
      );
    });
  }

  getLastVisibleLeft() {
    const elem = this.getVisibleLeft();

    if (!elem) {
      return null;
    }
    return elem[elem.length - 1];
  }

  getVisibleLeft() {
    return this.items.filter(value => {
      return (
        value.positionLeft -
        this.wrapper.nativeElement.scrollLeft -
        this.itemsMargin +
        this.margin <
        0
      );
    });
  }

  scrollRight() {
    const items = this.getVisibleRight();
    if (items.length) {
      this.canScrollLeft$.next(true);
      this.canScrollRight$.next(items.length > 1);

      setTimeout(() => {
        this.wrapper.nativeElement.style.scrollBehavior = 'smooth';
        this.wrapper.nativeElement.scrollLeft = this.itemScrollRight(items[0]);
      });
    }
  }

  scrollLeft() {
    const items = this.getVisibleLeft();
    if (items.length) {
      this.canScrollRight$.next(true);
      this.canScrollLeft$.next(items.length > 1);

      setTimeout(() => {
        this.wrapper.nativeElement.style.scrollBehavior = 'smooth';
        this.wrapper.nativeElement.scrollLeft = this.itemScrollLeft(
          items[items.length - 1]
        );
      });
    }
  }
}
