import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Media, MediaType, MediaVisibility } from '@app/media/models';
import { Upload } from '@app/upload/models/upload.model';
import { FormBuilder, Validators } from '@angular/forms';
import { Tag } from '@app/tag/models/tag.model';
import { CategoryFacade } from '@app/category/state/category.facade';
import { Category } from '@app/category/models';

@Component({
  selector: 'app-upload-detail',
  templateUrl: './upload-detail.component.html',
  styleUrls: ['./upload-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDetailComponent implements OnInit {
  MediaType = MediaType;
  MediaVisibility = MediaVisibility;

  form = this.builder.group({
    title: [
      '',
      [Validators.maxLength(200), Validators.minLength(5), Validators.required]
    ],
    description: [
      '',
      [Validators.maxLength(1000), Validators.minLength(5), Validators.required]
    ],
    visibility: [MediaVisibility.PUBLIC],
    tagsCount: [
      0,
      [Validators.min(1), Validators.max(20), Validators.required]
    ],
    categoriesCount: [0]
  });

  categories$ = this.categoryFacade.categories$;

  @Input()
  upload?: Upload;

  @Input()
  thumbnail?: string;

  @Input()
  canPublish = false;

  @Input()
  videoDuration?: number;

  @Output()
  thumbnailChange = new EventEmitter<File>();

  @Output()
  save = new EventEmitter<void>();

  @Output()
  mediaChange = new EventEmitter<Partial<Media>>();

  @Output()
  cancel = new EventEmitter<void>();

  private pMedia: Partial<Media> = {
    title: '',
    description: '',
    type: MediaType.VIDEO,
    visibility: MediaVisibility.PUBLIC,
    tags: []
  };

  constructor(
    private builder: FormBuilder,
    private categoryFacade: CategoryFacade
  ) {}

  get tags() {
    return this.pMedia.tags as Tag[];
  }

  set tags(tags: Tag[]) {
    this.pMedia = {
      ...this.pMedia,
      tags
    };

    this.form.patchValue({ tagsCount: tags.length });
    this.form.controls.tagsCount.markAsTouched();
  }

  get isEditing() {
    return this.pMedia.id !== undefined;
  }

  get categories() {
    return this.pMedia.categories as Category[];
  }

  set categories(categories: Category[]) {
    this.pMedia = {
      ...this.pMedia,
      categories
    };

    this.form.patchValue({ categoriesCount: categories.length });
    this.form.controls.categoriesCount.markAsTouched();
  }

  get media() {
    return this.pMedia;
  }

  @Input()
  set media(media: Partial<Media>) {
    this.pMedia =
      media !== undefined
        ? media
        : {
            title: '',
            description: '',
            type: MediaType.VIDEO,
            tags: []
          };

    this.form.setValue(
      {
        title: this.pMedia.title,
        description: this.pMedia.description,
        tagsCount: this.pMedia.tags ? this.pMedia.tags.length : 0,
        visibility: this.pMedia.visibility,
        categoriesCount: this.pMedia.categories
          ? this.pMedia.categories.length
          : 0
      },
      { emitEvent: false }
    );
  }

  get controls() {
    return this.form.controls;
  }

  get isVideo() {
    return this.media.type === MediaType.VIDEO;
  }

  get validThumbnail() {
    return !!this.thumbnail && this.thumbnail.split('default_playlist_thumbnail').length <= 1;
  }

  get isPdf() {
    return this.media.type === MediaType.PDF;
  }

  get isPlaylist() {
    return this.media.type === MediaType.PLAYLIST;
  }

  hasError(field: string) {
    const errors = this.controls[field] ? this.controls[field].errors : null;
    return (
      this.controls[field] &&
      this.controls[field].touched &&
      errors !== null &&
      !!Object.keys(errors).length
    );
  }

  errors(field: string) {
    const errors = this.controls[field] ? this.controls[field].errors : null;
    return errors !== null && this.controls[field].touched ? errors : undefined;
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(values => {
      this.mediaChange.emit({
        ...this.pMedia,
        title: this.form.value.title,
        description: this.form.value.description,
        visibility: this.form.value.visibility
      });
    });

    this.categoryFacade.loadCategories();
  }
}
