import { Injectable } from '@angular/core';

import { HttpService } from '@app/core/services/http.service';
import { Observable } from 'rxjs';
import { ExternalLink } from '@app/links-bar/models/external-link.model';
import { ApiCollection } from '@app/shared/models/api-collection';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExternalLinkService {
  public constructor(private httpService: HttpService) {}

  public loadExternalLinks(): Observable<ExternalLink[]> {
    return this.httpService
      .get<ApiCollection<ExternalLink>>('/external_links')
      .pipe(map(response => response['hydra:member']));
  }
}
