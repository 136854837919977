import { Iri } from '@app/shared/models/api-entity';
import { environment } from '@env/environment';

export function IriToUrl(iri: Iri) {
  return iri.replace(environment.apiBasePath, '');
}

export function IriToId(iri: Iri) {
  return iri.split('/').pop();
}
